import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';

@Component({
	templateUrl: './answer-post.component.html',
	styleUrls: [ './answer-post.component.scss' ]
})
export class AnswerPostComponent {

	answerText = new FormControl('', [Validators.required]);
  showResult = false;


	constructor(public dialogRef: MatDialogRef<AnswerPostComponent>,
				@Inject(MAT_DIALOG_DATA) public data) {
	}

  toggleShowResult(event) {
	  this.showResult = event.checked;
  }

	answer() {
		this.dialogRef.close({postId: this.data.post.id, answer: this.answerText.value, inactive: false});
	}

	seen() {
		this.dialogRef.close({postId: this.data.post.id, answer: null, inactive: false});
	}

	remove() {
		this.dialogRef.close({postId: this.data.post.id, answer: null, inactive: true})
	}

  openDocumentation() {
	  window.open('https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet');
  }
}
