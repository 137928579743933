import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AnswerPostComponent } from '../answer-post/answer-post.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
	templateUrl: './edit-event-type.component.html',
	styleUrls: ['./edit-event-type.component.scss']
})
export class EditEventTypeComponent {
	name = new FormControl('', [Validators.required]);

	constructor(public dialogRef: MatDialogRef<EditEventTypeComponent>,
				@Inject(MAT_DIALOG_DATA) public data) {
		this.name.setValue(data.name || '')
	}

	save() {
		this.dialogRef.close({id: this.data.id, name: this.name.value});
	}
}