<mat-sidenav-container>
    <mat-sidenav></mat-sidenav>
    <mat-sidenav-content>

        <lomas-public-toolbar></lomas-public-toolbar>

        <section class="preview">
            <mat-card *ngFor="let course of course$ | async" class="preview-card" >
                <mat-card-title >{{course.name}}</mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <a (click)="startCourse(course)">
                        <img [src]="course.thumbnail | cloudinaryWidth:300" width="300" height="424" [alt]="course.name"/>
                        <button mat-raised-button color="primary">Start proefles</button>
                    </a>
                </mat-card-content>
            </mat-card>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>


