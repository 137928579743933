<mat-sidenav-content class="page">
    <lomas-admin-toolbar title="Gebeurtenissen"></lomas-admin-toolbar>

    <div class="cards">
        <mat-card>
            <mat-card-title class="search-bar">
                <span class="search-bar__title">Gebeurtenis types</span>
                <mat-form-field class="search-bar__input">
                    <input matInput [formControl]="searchText" placeholder="Zoeken"/>
                </mat-form-field>
            </mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content *ngIf="eventTypes$ | async as eventTypes">
                <mat-table [dataSource]="eventTypes" class="mat-elevation-z8" *ngIf="eventTypes.length > 0">

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Naam </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.name }} </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <p></p>
                <mat-paginator *ngIf="eventTypes.length > 0"
                               [length]="total"
                               [pageSize]="size"
                               [pageSizeOptions]="[5, 10, 25, 100]"
                               (page)="pageChange($event)">
                </mat-paginator>
            </mat-card-content>
        </mat-card>
    </div>
</mat-sidenav-content>
<a mat-fab color="accent" class="mat-fab-bottom-right fixed" (click)="add()">
    <mat-icon>add</mat-icon>
</a>