<h2 mat-dialog-title>Bevestigen versturen nieuwsbrief</h2>
<mat-dialog-content>
    <p>
        Je staat op het punt om deze nieuwsbrief te versturen naar alle ontvangers van deze nieuwsbrief.
        Weet je dit zeker?
    </p>

</mat-dialog-content>
<mat-dialog-actions >
    <button mat-button mat-dialog-close>Annuleren</button>
    <button mat-raised-button (click)="ok()" color="primary" >Versturen</button>
</mat-dialog-actions>