import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NewsletterWebService = /** @class */ (function () {
    function NewsletterWebService(http) {
        this.http = http;
    }
    NewsletterWebService.prototype.get = function (id, accountId) {
        return this.http
            .get("/api/newsletter-web/" + id + "?accountId=" + accountId)
            .pipe(map(function (result) { return result['value']; }));
    };
    NewsletterWebService.ngInjectableDef = i0.defineInjectable({ factory: function NewsletterWebService_Factory() { return new NewsletterWebService(i0.inject(i1.HttpClient)); }, token: NewsletterWebService, providedIn: "root" });
    return NewsletterWebService;
}());
export { NewsletterWebService };
