import { ID } from '@datorama/akita';

export type Course = {
	id: ID;
	moduleId: string;
	chapters: any;
	name: string;
	active: boolean;
	progress: Progress[];
	publicAccessible: boolean;
	thumbnail: string;
	ltiLaunch: any,
}

export function createCourse({
								 id = null,
								 moduleId = '',
								 chapters = [],
								 name = '',
								 active = false,
								 progress = null,
								 publicAccessible = false,
								 thumbnail = '',
                ltiLaunch = null
							 }: Partial<Course>) {
	return {
		id,
		moduleId,
		chapters,
		name,
		active,
		progress,
		publicAccessible,
		thumbnail,
    ltiLaunch,
	}
}

export class Progress {
	p: string;
	i: string;
	a: string;
	v: string;
	t: number;

}
