import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { LicenseCode } from '../model/license-code.model';



export interface LicenseCodeState extends EntityState<LicenseCode> {
  count: number;
}

@StoreConfig({name: 'license-code'})
export class LicenseCodeStore extends EntityStore<LicenseCodeState, LicenseCode> {
	constructor() {
		super({count: 7});
	}

	updateCount(count) {
	  console.log(count)
	  this.update(state => ({...state, count}));
  }
}

@Injectable({
	providedIn: 'root'
})
export class LicenseCodeQuery extends QueryEntity<LicenseCodeState, LicenseCode> {
	constructor(protected store: LicenseCodeStore) {
		super(store);
	}
}
