import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
	templateUrl: './add-subscriber.component.html',
	styleUrls: ['./add-subscriber.component.scss']
})
export class AddSubscriberComponent {
	form = new FormGroup({
		firstName: new FormControl('', [Validators.required]),
		preposition: new FormControl(''),
		surName: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required, Validators.email]),
	});


	constructor(public dialogRef: MatDialogRef<AddSubscriberComponent>,
				@Inject(MAT_DIALOG_DATA) public data) {
	}

	save() {
		this.dialogRef.close(this.form.value);
	}
}