import { ID } from '@datorama/akita';

export type UserAccount = {
	id: ID;
	firstName: string;
	preposition: string;
	surName: string;
	email: string;
	password: string;
}

export function createAccount({
	  id = null,
	  firstName = '',
	  preposition = '',
	  surName = '',
	  email = '',
	  password = ''}: Partial<UserAccount>){
	return {
		id,
		firstName,
		preposition,
		surName,
		email,
		password
	}
}

