<mat-sidenav-content class="page">
    <lomas-admin-toolbar title="Inschrijvingen proeflessen"></lomas-admin-toolbar>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Inschrijvingen proeflessen</mat-card-title>
            <span class="flex-title"></span>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content *ngIf="enrollmentOverview$ | async as enrollmentOverview">
            <mat-table [dataSource]="enrollmentOverview" class="mat-elevation-z8" *ngIf="enrollmentOverview.length > 0">

                <ng-container matColumnDef="createDateTime">
                    <mat-header-cell *matHeaderCellDef> Titel </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.createDate | date: 'yyyy-MM-dd' }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="course">
                    <mat-header-cell *matHeaderCellDef> Cursus </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{getCourseName(element.courseId) }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="account">
                    <mat-header-cell *matHeaderCellDef> Deelnemer </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{getAccountName(element.accountId) }} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <p></p>
            <mat-paginator *ngIf="enrollmentOverview.length > 0"
                           [length]="total$ | async"
                           [pageIndex]="pageIndex$ | async"
                           [pageSize]="size$ | async"
                           [pageSizeOptions]="[5, 10, 25, 100]"
                           (page)="pageChange($event)">
            </mat-paginator>
            <div class="no-results" *ngIf="enrollmentOverview.length === 0">
                Er zijn geen inschrijvingen gevonden
            </div>
        </mat-card-content>

    </mat-card>

</mat-sidenav-content>
