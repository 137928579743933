import {Component, OnDestroy, OnInit} from '@angular/core';
import { AccountQuery, AccountStore } from '../../../store/account.store';
import { MatDialog, MatSnackBar, MatSnackBarConfig, PageEvent } from '@angular/material';
import { FormControl } from '@angular/forms';
import { debounce } from 'rxjs/internal/operators';
import { timer } from 'rxjs/index';
import { AccountService } from '../../../services/account/account.service';
import { AddSubscriberComponent } from '../dialogs/add-subscriber/add-subscriber.component';
import { Account } from '../../../model/account.model';
import { ID } from '@datorama/akita';
import { Router } from '@angular/router';

@Component({
	templateUrl: './accounts.component.html',
	styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, OnDestroy {
	displayedColumns: string[] = ['firstName', 'surName', 'email', 'enrolled', 'actions'];
	size = 25;
	pageIndex = 0;
	total: number;
	subscribers$ = this.accountQuery.selectAll();
	searchText = new FormControl('');

	constructor(private accountQuery: AccountQuery,
				private accountStore: AccountStore,
				private accountService: AccountService,
				private dialog: MatDialog,
				private router: Router,
				private snackBar: MatSnackBar)
	{}

	ngOnInit() {
	  // this should ideally be done via the accountQuery. But works for now
    this.size = this.accountService.pageSize;
    this.pageIndex = this.accountService.pageIndex;
    this.searchText.setValue(this.accountService.searchText);

		this.loadData();

		this.searchText.valueChanges
			.pipe(debounce(() => timer(300)))
			.subscribe(() => this.loadData())

	}

	ngOnDestroy() {
	  this.accountService.pageSize = this.size;
	  this.accountService.pageIndex = this.pageIndex;
	  this.accountService.searchText = this.searchText.value;
  }

	loadData() {
		this.accountService.page( this.size, this.pageIndex, this.searchText.value)
			.subscribe(result => this.total = result);
	}

	pageChange(event: PageEvent) {
		this.size = event.pageSize;
		this.pageIndex = event.pageIndex;
		this.loadData();
	}

	add() {
		this.dialog.open(AddSubscriberComponent, {
			width: '500px',
			height: '500px'
		}).afterClosed().subscribe(result => {
			if (result) {
				result.id = 'new';
				result.note = '';
				this.accountService.save(<Account> result).subscribe(() => {
					this.snackBar.open('Abonnee is opgeslagen', 'ok', {duration: 4000})
					this.loadData();
				},() => {
					this.snackBar.open('Abonnee bestaat al', 'ok', {duration: 4000})
				});
			}
		})
	}

	showAccount(id: ID) {
		this.router.navigate([`/admin/accounts/${id}`])
	}
}
