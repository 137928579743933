import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Newsletter } from '../../model/newsletter.model';
import { map, tap } from 'rxjs/internal/operators';
import { NewsletterStore } from '../../store/newsletter.store';
import { Observable } from 'rxjs/Rx';
import { ID } from '@datorama/akita';

@Injectable({
	providedIn: 'root'
})
export class NewsletterService {
	constructor(private http: HttpClient,
				private newsletterStore: NewsletterStore) {}

	list(top: number, skip: number) {
		return this.http.get(`/api/newsletter/?&$top=${top}&$skip=${skip}`)
			.pipe(
				tap(results => this.newsletterStore.set(results['value'])),
				map(results => results['count']));
	}

	get(id: ID) {
		return this.http.get(`/api/newsletter/${id}`)
			.pipe(map(result => result['value']));
	}

	getBatch(id: ID, batchIndex: number) {
		return this.http.get(`/api/newsletter/${id}/${batchIndex}`)
			.pipe(map(result => result['value']));
	}

	resend(id: ID, batchIndex: number) {
		return this.http.put(`/api/newsletter/resend/${id}`, {batchIndex: batchIndex})
	}

	save(newsletter: Newsletter): Observable<string | ID> {
		return newsletter.id === 'new' ?
			this.http.post('/api/newsletter', newsletter,  {observe: 'response'})
				.pipe(map(resp => {
					const location = resp.headers.get('location');
					return location.substr(location.lastIndexOf('/') + 1);
				})):
			this.http.put(`/api/newsletter/${newsletter.id}`, newsletter)
				.pipe(map(() => newsletter.id));
	}

	preview(id: ID, emails: string[]) {
		return this.http.put(`/api/newsletter/preview/${id}`, {emails});
	}

	send(id: ID){
		return this.http.put(`/api/newsletter/send/${id}`, {});
	}
}