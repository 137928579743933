var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from "@datorama/akita";
import { CourseStore } from "./course.store";
import * as i0 from "@angular/core";
import * as i1 from "./course.store";
var CourseQuery = /** @class */ (function (_super) {
    __extends(CourseQuery, _super);
    function CourseQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        return _this;
    }
    CourseQuery.ngInjectableDef = i0.defineInjectable({ factory: function CourseQuery_Factory() { return new CourseQuery(i0.inject(i1.CourseStore)); }, token: CourseQuery, providedIn: "root" });
    return CourseQuery;
}(QueryEntity));
export { CourseQuery };
