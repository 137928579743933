import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class UnsubscribeService {
	constructor(private http: HttpClient){}

	unsubscribe = (id: string) => {
		return this.http.put(`/api/unsubscribe/${id}`, {})
	}
}