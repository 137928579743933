<mat-sidenav-content class="page">
    <lomas-admin-toolbar title="Licentiecodes"></lomas-admin-toolbar>
    <mat-card>
        <mat-card-title>Codes</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content *ngIf="licenseCode$ | async as licenseCodes">
            <mat-table [dataSource]="licenseCodes" class="mat-elevation-z8">

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Cursusnaam</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{courses[element.courseId]}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="fullName">
                    <mat-header-cell *matHeaderCellDef> Naam/referentie</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.fullName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reseller">
                    <mat-header-cell *matHeaderCellDef> Reseller</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{getResellerName(element.resellerId)}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef> Datum </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.createDate | date: 'yyyy-MM-dd: HH:mm'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="code">
                    <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.code}} {{element.licenseId ? '(*)' : ''}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="duration">
                    <mat-header-cell *matHeaderCellDef> Duur (dagen) </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.duration}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          <mat-paginator *ngIf="licenseCodes.length > 0"
                         [length]="total"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="[5, 10, 25, 100]"
                         (page)="pageChange($event)">
          </mat-paginator>
        </mat-card-content>
    </mat-card>

</mat-sidenav-content>
<a mat-fab color="accent" class="mat-fab-bottom-right fixed" (click)="addLicenseCode()">
    <mat-icon>add</mat-icon>
</a>
