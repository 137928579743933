import { Component, OnInit } from '@angular/core';
import { PublicCourseService } from '../../../services/public-course/public-course.service';
import { CourseEnrollmentService } from '../../../services/course-enrollment/course-enrollment.service';
import { PublicCourseQuery } from '../../../store/pubic-course.store';
import { CourseEnrollmentQuery } from '../../../store/course-enrollment.store';
import { combineLatest } from 'rxjs/index';
import { map } from 'rxjs/internal/operators';
import { Course } from '../../../model/course.model';
import { Router } from '@angular/router';

@Component({
	templateUrl: './catalog.component.html',
	styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {

	courseEnrollment$ = this.courseEnrollmentQuery.selectAll();
	course$ = this.publicCourseQuery.selectAll();

	enrollableCourses$ = combineLatest(this.courseEnrollment$, this.course$)
		.pipe(map(result => {
			const courseEnrollmentIds = result[0].map(enrollment => enrollment.courseId);
			const courses = result [1];
			return courses.filter(course => !courseEnrollmentIds.includes(String(course.id)))
		}));

	constructor(
		private publicCourseService: PublicCourseService,
		private courseEnrollmentService: CourseEnrollmentService,
		private publicCourseQuery: PublicCourseQuery,
		private courseEnrollmentQuery: CourseEnrollmentQuery,
		private router: Router,
	) {}

	ngOnInit() {
		this.publicCourseService.list().subscribe();
		this.courseEnrollmentService.list().subscribe();
	}

	startCourse(course: Course) {
		this.courseEnrollmentService.addCourse(String(course.id)).subscribe();
		this.router.navigate(['/learning']);
	}
}
