var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityState, EntityStore, ID, QueryEntity, StoreConfig } from '@datorama/akita';
import * as i0 from "@angular/core";
var EventStore = /** @class */ (function (_super) {
    __extends(EventStore, _super);
    function EventStore() {
        return _super.call(this, { size: 25, pageIndex: 0, total: 0, pageIds: [] }) || this;
    }
    EventStore.prototype.upsertPage = function (events, size, pageIndex, total) {
        var _this = this;
        var eventIds = events
            .map(function (event) {
            _this.upsert(event.id, event);
            return event.id;
        });
        this.updateRoot({
            size: size,
            pageIndex: pageIndex,
            total: total,
            pageIds: eventIds
        });
    };
    EventStore = __decorate([
        StoreConfig({ name: 'event' }),
        __metadata("design:paramtypes", [])
    ], EventStore);
    return EventStore;
}(EntityStore));
export { EventStore };
var EventQuery = /** @class */ (function (_super) {
    __extends(EventQuery, _super);
    function EventQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        return _this;
    }
    EventQuery.prototype.getPageIndex = function () {
        return this.getSnapshot().pageIndex;
    };
    EventQuery.prototype.getSize = function () {
        return this.getSnapshot().size;
    };
    EventQuery.prototype.getTotal = function () {
        return this.getSnapshot().total;
    };
    EventQuery.prototype.selectPageIndex = function () {
        return this.select(function (state) { return state.pageIndex; });
    };
    EventQuery.prototype.selectSize = function () {
        return this.select(function (state) { return state.size; });
    };
    EventQuery.prototype.selectTotal = function () {
        return this.select(function (state) { return state.total; });
    };
    EventQuery.prototype.selectPage = function () {
        var _this = this;
        return this.select(function (state) { return state.pageIds.map(function (id) { return _this.getEntity(id); }); });
    };
    EventQuery.ngInjectableDef = i0.defineInjectable({ factory: function EventQuery_Factory() { return new EventQuery(i0.inject(EventStore)); }, token: EventQuery, providedIn: "root" });
    return EventQuery;
}(QueryEntity));
export { EventQuery };
