import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/internal/operators';
import { BlogStore } from '../../store/blog.store';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../store/blog.store";
var BlogAdminService = /** @class */ (function () {
    function BlogAdminService(http, blogStore) {
        this.http = http;
        this.blogStore = blogStore;
    }
    BlogAdminService.prototype.countUnseen = function () {
        return this.http.get('/api/blog-admin/count-unseen');
    };
    BlogAdminService.prototype.list = function (top, skip, unseenOnly) {
        var _this = this;
        if (unseenOnly === void 0) { unseenOnly = true; }
        return this.http.get("/api/blog-admin/?unseen=" + unseenOnly + "&$top=" + top + "&$skip=" + skip)
            .pipe(tap(function (results) { return _this.blogStore.set(results['value']); }), map(function (results) { return results['count']; }));
    };
    BlogAdminService.prototype.thread = function (id) {
        var _this = this;
        return this.http.get("/api/blog-admin/thread/" + id)
            .pipe(tap(function (results) { return _this.blogStore.set(results['value']); }));
    };
    BlogAdminService.prototype.answer = function (payload) {
        return this.http.post("/api/blog-admin", payload);
    };
    BlogAdminService.prototype.editAnswer = function (id, answer) {
        return this.http.put("/api/blog-admin/" + id, { answer: answer });
    };
    BlogAdminService.ngInjectableDef = i0.defineInjectable({ factory: function BlogAdminService_Factory() { return new BlogAdminService(i0.inject(i1.HttpClient), i0.inject(i2.BlogStore)); }, token: BlogAdminService, providedIn: "root" });
    return BlogAdminService;
}());
export { BlogAdminService };
