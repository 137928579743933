import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
	templateUrl: 'forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	email = new FormControl();
	message = '';

	constructor(private route: ActivatedRoute,
				private authService: AuthService) {}

	ngOnInit() {
		this.email = new FormControl(this.route.snapshot.queryParamMap.get('email') || '', [Validators.required]);
	}

	submit() {
		this.authService.forgotPassword(this.email.value).subscribe(() => {
			this.message = 'Een link is verzonden.'
		}, () => {
			this.message = 'Het e-mailadres is niet gevonden'
		});
	}

	resetMessage() {
		this.message = '';
	}
}