import {Component, OnDestroy, OnInit} from "@angular/core";
import {LicenseService} from "../../../services/license/license.service";
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatSnackBar} from "@angular/material";

@Component({
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss']
})
export class LicensesComponent implements OnInit, OnDestroy {
  month = new Date().getMonth();
  year = new Date().getFullYear();
  licenses = null;
  formGroup = new FormGroup({
    month: new FormControl(new Date().getMonth()),
    year: new FormControl(new Date().getFullYear())
  });

  months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
  years = [];
  startYear = 2018;
  subscriptions = new Subscription();

  constructor(
    private licenseService: LicenseService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    const currentYear = new Date().getFullYear();
    this.years = new Array(currentYear - this.startYear + 1)
      .fill('')
      .map((value, index) => this.startYear + index);

    this.licenseService.list(this.month, this.year)
      .subscribe((data: any[]) => {
          this.licenses = data;
        },
        err => {
          this.snackBar.open('Er is iets misgegaan met het ophalen van de gegevens', 'ok', {duration: 4000});
        });

    this.subscriptions.add(this.formGroup.valueChanges.subscribe(() => {
      this.licenses = null;
      this.licenseService.list(this.formGroup.get('month').value, this.formGroup.get('year').value)
      .subscribe((data: any[]) => {
          this.licenses = data;
        },
        err => {
          this.snackBar.open('Er is iets misgegaan met het ophalen van de gegevens', 'ok', {duration: 4000});
        });
    }))
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getAccount(account) {
    return account ? `${account.firstName} ${account.preposition || ''} ${account.surName}` : 'onbekend'
  }

  getReseller(reseller) {
    return reseller || 'onbekend'
  }
}
