<div class="wrapper">
  <div class="header" *ngIf="!externalView">
    <lomas-student-toolbar [title]="course.name"></lomas-student-toolbar>
  </div>

  <ng-container *ngIf="course.ltiLaunch">
    <iframe [src]="ltiLaunchUrl" style="width: 100%; height: 100%; border: 0"></iframe>
  </ng-container>
  <ng-container *ngIf="!course.ltiLaunch">
  <div class="content" *ngIf="notAccessible">Deze cursus is niet toegankelijk!</div>
  <div class="content" *ngIf="!notAccessible">

      <aside [ngClass]="{'toc-open': tocOpen}">
        <a (click)="showSplash()">
          <mat-toolbar class="toc-header" [ngClass]="{'splash-open': splashOpen}">
            <mat-icon>home</mat-icon> Overzicht
          </mat-toolbar>
        </a>
        <mat-accordion style="margin: 20px">

          <mat-expansion-panel *ngFor="let chapter of course.chapters" [expanded]="chapter === selectedChapter" (opened)="openedPanel(chapter)">
            <mat-expansion-panel-header>
              <mat-panel-title>{{chapter.name}}</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-list-item
                *ngFor="let part of chapter.parts"
                class="part"
                [ngClass]="{'selected-part': part === selectedPart, 'splash-open': splashOpen}"
                (click)="selectPart(part)">
                <mat-icon *ngIf="part.type === 'video'">ondemand_video</mat-icon>
                <mat-icon *ngIf="part.type === 'content'">list_alt</mat-icon>
                <mat-icon *ngIf="part.type === 'items'">assessment</mat-icon>
                {{part.name}}
              </mat-list-item>
            </mat-list>
            <div>

            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </aside>

    <section [ngClass]="{'toc-open': tocOpen}" *ngIf="course" id="content">
      <mat-card *ngIf="splashOpen">
        <mat-card-title>Overzicht {{course.name}}</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div *ngIf="splashOpen">
            <div *ngIf="course.progress === null" class="not-started">
              <h4>Je bent nog niet begonnen aan de cursus.</h4>
              <button mat-raised-button (click)="next()" color="primary">Start cursus</button>
            </div>
            <div *ngIf="course.progress !== null">
              <p>
                Je bent gebleven bij:
                <strong>
                  {{selectedChapter?.name}} -
                  {{selectedPart?.name}}
                </strong>
              </p>
              <div>
                <button (click)="continue()" mat-raised-button color="primary">Verder gaan</button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card *ngIf="splashOpen && blogTemp.length > 0 && !externalView">
        <mat-card-title>Forum berichten</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content *ngIf="getBlogSlice() as blogs">
          <mat-table [dataSource]="blogs" class="mat-elevation-z8">

            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef> Datum </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{element.date  }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="fullName">
              <mat-header-cell *matHeaderCellDef> Naam </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.fullName }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="partName">
              <mat-header-cell *matHeaderCellDef> Onderdeel </mat-header-cell>
              <mat-cell *matCellDef="let element"> <a (click)="viewPartBlog(element.partId)">{{element.partName }} </a></mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <mat-paginator *ngIf="getBlogs().length > 0"
                         [length]="getBlogs().length"
                         [pageSize]="size"
                         [pageSizeOptions]="[5, 10, 25, 100]"
                         (page)="pageChange($event)">
          </mat-paginator>
        </mat-card-content>
      </mat-card>

      <mat-card *ngIf="splashOpen && !course.publicAccessible && !externalView">
        <mat-card-title>Scholingsbijeenkomsten</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <p>Schrijf je in voor de scholingsbijeenkomsten via onderstaande knop:</p>
          <button mat-raised-button (click)="enrollMeeting()" color="primary">Inschrijven scholingsbijeenkomsten</button>
        </mat-card-content>
      </mat-card>

      <mat-card *ngIf="!splashOpen">
        <mat-card-title>{{getTitle()}}</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content *ngIf="!blogOpen">


          <div *ngIf="selectedPart !== null">
            <lomas-view-content [id]="selectedPart.id" [courseId]="course.id"  *ngIf="selectedPart?.type === 'items' && selectedPart.id"></lomas-view-content>

            <lomas-view-video [id]="selectedPart.id" *ngIf="selectedPart?.type === 'video'"></lomas-view-video>
            <lomas-view-content [id]="selectedPart.id" [courseId]="course.id" *ngIf="selectedPart?.type === 'content'"></lomas-view-content>
            <lomas-view-items *ngIf="selectedPart?.type === 'items'" [ids]="selectedPart.children" [selectedIndex]="selectedItemIndex" (selected)="selectItem($event)"></lomas-view-items>
          </div>
        </mat-card-content>
        <mat-card-content *ngIf="blogOpen">
          <lomas-view-blog [courseId]="course.id" [partId]="selectedPart.id" (close)="toggleBlog()"></lomas-view-blog>
        </mat-card-content>
      </mat-card>
    </section>
  </div>

  <div class="footer">
    <mat-toolbar>
      <div  class="page-footer__buttons">
        <button mat-button class="tc-grey-600 toc-button" (click)="toggleToc()" [ngClass]="{'footer__buttons--selected': tocOpen}">
          <mat-icon class="pull-bottom">toc</mat-icon>
          <div class="mat-caption">Inhoud</div>
        </button>
        <button mat-button class="tc-grey-600" (click)="previous()">
          <mat-icon class="pull-bottom">navigate_before</mat-icon>
          <div class="mat-caption">Vorige</div>
        </button>
        <button mat-button class="tc-grey-600" (click)="next()" >
          <mat-icon class="pull-bottom">navigate_next</mat-icon>
          <div class="mat-caption">Volgende</div>
        </button>
        <button mat-button class="tc-grey-600"
                *ngIf="!externalView"
                (click)="toggleBlog()"
                [ngClass]="{'footer__buttons--selected': blogOpen && !tocOpen}"
                [disabled]="selectedPart === null">

          <mat-icon class="pull-bottom"  [matBadge]="blogCount" [matBadgeHidden]="blogCount === 0">question_answer</mat-icon>
          <div class="mat-caption">Forum </div>
        </button>
      </div>
    </mat-toolbar>
  </div>
  </ng-container>
</div>
