import {Component, OnInit} from '@angular/core';
import {StateQuery} from '../../../store/state.store';
import {LicenseCodeService} from '../../../services/license-code/license-code.service';
import {CourseService} from '../../../services/course/course.service';
import {LicenseCodeQuery} from '../../../store/license-code.store';
import {CourseQuery} from '../../../store/course.store';
import {BlogAdminService} from '../../../services/blog-admin/blog-admin.service';
import {NewsletterService} from '../../../services/newsletter/newsletter.service';
import {AccountService} from '../../../services/account/account.service';
import {AccountQuery} from '../../../store/account.store';
import {EnrollmentOverviewService} from '../../../services/enrollment-overview/enrollment-overview.service';
import {ActionService} from "../../../services/action/action.service";

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  licenseCode$;
  subscribers$;
  enrollmentOverview$;
  courses = {};
  unseenForumCount = '';
  openActionCount = '';

  constructor(private stateQuery: StateQuery,
              private licenseCodeQuery: LicenseCodeQuery,
              private licenseCodeService: LicenseCodeService,
              private courseQuery: CourseQuery,
              private blogAdminService: BlogAdminService,
              private courseService: CourseService,
              private newsletterService: NewsletterService,
              //private enrollmentOverviewService: EnrollmentOverviewService,
              //private enrollmentOverviewQuery, EnrollmentOverviewQuery,
              private subscriberService: AccountService,
              private subscriberQuery: AccountQuery,
              private actionService: ActionService) {
  }


  ngOnInit() {
    this.licenseCodeService.list(0, 3).subscribe();
    this.subscriberService.page(3, 0).subscribe();
    //this.enrollmentOverviewService.list(3, 0).subscribe();
    this.courseService.list().subscribe();

    this.licenseCode$ = this.licenseCodeQuery.selectAll();
    this.courseQuery.selectAll().subscribe(courses => {
      this.courses = courses.reduce((result, course) => {
        result[course.id] = course.name;
        return result;
      }, {});

    });
    this.subscribers$ = this.subscriberQuery.selectAll();

    //this.enrollmentOverview$ = this.enrollmentOverviewQuery.selectPage();

    this.blogAdminService.countUnseen().subscribe(result => this.unseenForumCount = result['value'].count);

    this.actionService.open().subscribe(result => this.openActionCount = result);
  }

}
