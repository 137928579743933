import { ID } from '@datorama/akita';

export type Blog = {
	id: ID;
	courseId: string;
	accountId: string;
	partId: string;
	createDateTime: number;
	message: string;
	replyId: string;
	fullName: string;
	seenDateTime: number,
	seenById: string,
	inactive: boolean,
	adminAnswer: boolean

}

export function createBlog({
							   id = null,
							   courseId = '',
							   accountId = '',
							   partId = '',
							   createDateTime = 0,
							   message = '',
							   replyId = null,
							   fullName = '',
							   seenDateTime = 0,
							   seenById = null,
							   inactive = false,
							   adminAnswer = false,
						   }: Partial<Blog>) {
	return {
		id,
		courseId,
		accountId,
		partId,
		createDateTime,
		message,
		replyId,
		fullName,
		seenDateTime,
		seenById,
		inactive,
		adminAnswer,
	}
}
