import {Component, Inject} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {EventTypeQuery} from "../../../../store/event-type.store";
import {EventTypeService} from "../../../../services/event-type/event-type.service";
import {Action} from "../../../../model/action.model";
import { Event } from'../../../../model/event.model';

@Component({
  templateUrl: './complete-action.component.html',
  styleUrls: ['./complete-action.component.scss']
})
export class CompleteActionComponent {
  eventTypes$ = this.eventTypeQuery.selectAll();
  id = 'new';

  action: Action;

  eventTypeId = new FormControl('');
  note = new FormControl('');

  constructor(
    private dialogRef: MatDialogRef<CompleteActionComponent>,
    private eventTypeQuery: EventTypeQuery,
    private eventTypeService: EventTypeService,
    @Inject(MAT_DIALOG_DATA) private data){

    this.action = data.action;
    this.eventTypeId.setValue(data.action.eventTypeId);
    this.eventTypeService.list(0, 1000).subscribe();
  }

  save() {
    const note = this.note.value !== '' ? this.note.value : this.action.note;
    this.dialogRef.close(<Event> {
      id: this.id,
      note: note,
      eventTypeId: this.eventTypeId.value,
      actionId: this.action.id
    });
  }
}
