import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { createNewsletter, Newsletter } from '../model/newsletter.model';

const initialState: Newsletter = createNewsletter({});

export interface NewsletterState extends EntityState<Newsletter> {}

@StoreConfig({name: 'store'})
export class NewsletterStore extends EntityStore<NewsletterState, Newsletter> {
	constructor() {
		super(initialState);
	}
}

@Injectable({providedIn: 'root'})
export class NewsletterQuery extends QueryEntity<NewsletterState, Newsletter> {
	constructor(protected store: NewsletterStore) {
		super(store);
	}
}