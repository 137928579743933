import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccountService } from '../../../services/useraccount/useraccount.service';

@Component({
	templateUrl: './confirm-newsletter.component.html',
	styleUrls: ['./confirm-newsletter.component.scss']
})
export class ConfirmNewsletterComponent implements OnInit {
	status: 'new' | 'fail' | 'success'  = 'new';

	constructor(private authService: AuthService,
				private accountService: UserAccountService,
				private route: ActivatedRoute,) {}

	ngOnInit() {
		const code = this.route.snapshot.queryParamMap.get('code');
		this.accountService.confirmAccount(code).subscribe(() => {
			this.status = 'success';
		}, () => {
			this.status = 'fail';
		});
	}


}