import { Component, HostBinding, Inject } from '@angular/core';
import { UserAccountQuery} from './store/user-account.store';
import { UserAccountService } from './services/useraccount/useraccount.service';
import { Router } from '@angular/router';
import { WINDOW } from './window.provider';

@Component({
  selector: 'lomas-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @HostBinding('class') componentCssClass;

  constructor(private accountService: UserAccountService,
              private accountQuery: UserAccountQuery,
			  private router: Router,
			  @Inject(WINDOW) private window: Window) {

	  this.window.location.hostname.indexOf('acc.') > -1 ?
	    this.componentCssClass = 'light-theme' :
	    this.componentCssClass = 'default-theme';
  }
}
