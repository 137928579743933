import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/internal/operators';
import { BlogStore } from '../../store/blog.store';
import { of } from 'rxjs/index';
import { ID } from '@datorama/akita';

@Injectable({
	providedIn: 'root'
})
export class BlogAdminService {
	constructor(private http: HttpClient,
				private blogStore: BlogStore,) {}

	countUnseen() {
		return this.http.get('/api/blog-admin/count-unseen');
	}

	list(top: number, skip: number, unseenOnly = true) {
		return this.http.get(`/api/blog-admin/?unseen=${unseenOnly}&$top=${top}&$skip=${skip}`)
			.pipe(
				tap(results => this.blogStore.set(results['value'])),
				map(results => results['count']));
	}

	thread(id) {
		return this.http.get(`/api/blog-admin/thread/${id}`)
			.pipe(tap(results => this.blogStore.set(results['value'])))
	}

	answer(payload) {
		return this.http.post(`/api/blog-admin`, payload);
	}

	editAnswer(id: ID, answer: string) {
		return this.http.put(`/api/blog-admin/${id}`, {answer});
	}
}