import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PublicCourseService } from '../../../services/public-course/public-course.service';
import { PublicCourseQuery } from '../../../store/pubic-course.store';
import { ActivatedRoute } from '@angular/router';
import { SubscribeService } from '../../../services/subscribe/subscribe.service';
var SubscribeComponent = /** @class */ (function () {
    function SubscribeComponent(subscribeService, publicCourseService, publicCourseQuery, route) {
        this.subscribeService = subscribeService;
        this.publicCourseService = publicCourseService;
        this.publicCourseQuery = publicCourseQuery;
        this.route = route;
        this.createAccountForm = new FormGroup({
            firstName: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email]),
        });
        this.status = 'new';
    }
    Object.defineProperty(SubscribeComponent.prototype, "firstName", {
        get: function () { return this.createAccountForm.get('firstName'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubscribeComponent.prototype, "email", {
        get: function () { return this.createAccountForm.get('email'); },
        enumerable: true,
        configurable: true
    });
    SubscribeComponent.prototype.submit = function () {
        var _this = this;
        var account = this.createAccountForm.value;
        this.subscribeService.post(account).subscribe(function (res) {
            _this.status = 'success';
        }, function (err) {
            if (err.error.code === 'ALREADY_EXISTS') {
                _this.status = 'exists';
            }
            else {
                _this.status = 'fail';
            }
        });
    };
    return SubscribeComponent;
}());
export { SubscribeComponent };
