import { Injectable } from '@angular/core';
import { EntityState, EntityStore, Query, QueryEntity, Store, StoreConfig } from '@datorama/akita';
import { createEventType, EventType } from '../model/event-type.model';
import { Account } from '../model/account.model';

const initialState: EventType = createEventType({});

export interface EventTypeState extends EntityState<EventType> {}

@StoreConfig({ name: 'eventType' })
export class EventTypeStore extends EntityStore<EventTypeState, EventType> {
	constructor() {
		super(initialState);
	}
}

@Injectable({providedIn: 'root'})
export class EventTypeQuery extends QueryEntity<EventTypeState, EventType> {
	constructor(protected store: EventTypeStore) {
		super(store);
	}
}