<mat-sidenav-content class="page" *ngIf="account">
    <lomas-admin-toolbar title="Nieuwsbrief"></lomas-admin-toolbar>
    <mat-toolbar>
        <button mat-icon-button [routerLink]="['/admin/accounts']">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
        Persoon
    </mat-toolbar>
    <div class="cards">
        <mat-card class="">
            <mat-card-title>
                Gegevens
            </mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content class="account-details">
                <form [formGroup]="accountDetailsForm" class="account-details__form">
                    <mat-form-field>
                        <input matInput formControlName="firstName" placeholder="Voornaam" />
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput formControlName="preposition" placeholder="Tussenvoegsel" />
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput formControlName="surName" placeholder="Achternaam" />
                    </mat-form-field>
                    <mat-form-field>
                        <textarea matInput placeholder="Notities" formControlName="note"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput formControlName="email" placeholder="E-mail" />
                    </mat-form-field>
                    <button mat-raised-button color="primary" (click)="save()">Opslaan</button>
                </form>

            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-title>Nieuwsbrief</mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div *ngIf="!account.confirmedDate">Emailadres is nog niet bevestigd</div>
                <div *ngIf="account.confirmedDate && !account.unsubscribeDate">
                    Ingeschreven voor nieuwsbrief sinds: {{account.confirmedDate | date:'dd-MM-yyyy'}}
                    <button mat-raised-button color="primary" (click)="unsubscribeAccount()">Uitschrijven</button>
                </div>
                <div *ngIf="account.unsubscribeDate">Uitgeschreven voor nieuwsbrief sinds: {{account.unsubscribeDate | date: 'dd-MM-yyyy'}}</div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-title class="search-bar">
                <span class="search-bar__title">Openstaande acties</span>

            </mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content *ngIf="actions$ | async as actions">
                <mat-table [dataSource]="actions" class="mat-elevation-z8" *ngIf="actions.length > 0">

                    <ng-container matColumnDef="dueDate">
                        <mat-header-cell *matHeaderCellDef> Einddatum </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                          {{element.dueDate | date: 'yyyy-MM-dd'}}
                          <mat-icon *ngIf="isDue(element.dueDate )">alarm</mat-icon>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="eventType">
                        <mat-header-cell *matHeaderCellDef> Gebeurtenis </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{eventTypes[element.eventTypeId]?.name}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="adminName">
                        <mat-header-cell *matHeaderCellDef> Aangemaakt door </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{getAccountName(element.createAccountId)}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="note">
                        <mat-header-cell *matHeaderCellDef> Notitie </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.note}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef> Acties </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                          <button mat-button (click)="editAction(element)">Wijzigen</button>
                          <button mat-button (click)="completeAction(element)">Afronden</button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="actionDisplayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: actionDisplayedColumns;"></mat-row>
                </mat-table>
                <p></p>
                <mat-paginator *ngIf="actions.length > 0"
                               [length]="actionTotal"
                               [pageSize]="actionPageSize"
                               [pageSizeOptions]="[5, 10, 25, 100]"
                               (page)="actionPageChange($event)">
                </mat-paginator>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-title class="search-bar">
                <span class="search-bar__title">Gebeurtenissen</span>

            </mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content *ngIf="events$ | async as events">
                <mat-table [dataSource]="events" class="mat-elevation-z8" *ngIf="events.length > 0">

                    <ng-container matColumnDef="dateTime">
                        <mat-header-cell *matHeaderCellDef> Datum/tijd </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                          {{element.createDateTime | date: 'yyyy-MM-dd (hh:mm)'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="eventType">
                        <mat-header-cell *matHeaderCellDef> Gebeurtenis </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{eventTypes[element.eventTypeId]?.name}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="adminName">
                        <mat-header-cell *matHeaderCellDef> Door </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{getAccountName(element.adminAccountId)}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="note">
                        <mat-header-cell *matHeaderCellDef> Notitie </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.note}} </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="eventDisplayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: eventDisplayedColumns;"></mat-row>
                </mat-table>
                <p></p>
                <mat-paginator *ngIf="events.length > 0"
                               [length]="eventTotal"
                               [pageSize]="eventPageSize"
                               [pageSizeOptions]="[5, 10, 25, 100]"
                               (page)="eventPageChange($event)">
                </mat-paginator>
            </mat-card-content>
        </mat-card>
      <mat-card>
        <mat-card-title class="search-bar">
          <span class="search-bar__title">Voortgang</span>
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-expansion-panel *ngFor="let courseEnrollment of courseEnrollments">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{courseEnrollment.name}}
            </mat-panel-title>
            <mat-panel-description>
              <span *ngIf="!courseEnrollment.progress">-- Niet gestart --</span>
              <span *ngIf="courseEnrollment.progress">{{courseEnrollment.progress[courseEnrollment.progress.length - 1].date}}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>

            <ul *ngIf="courseEnrollment.progress">

              <li *ngFor="let entry of courseEnrollment.progress">{{entry.date}}: {{entry.partName}}</li>

            </ul>

        </mat-expansion-panel>
      </mat-card>
    </div>
</mat-sidenav-content>
<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="addAction()"> Actie </button>
  <button mat-menu-item (click)="addEvent()"> Gebeurtenis </button>
</mat-menu>
<a mat-fab color="accent" class="mat-fab-bottom-right fixed" [matMenuTriggerFor]="appMenu" >
    <mat-icon>add</mat-icon>
</a>
