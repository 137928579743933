<mat-sidenav-content class="page" *ngIf="newsletter">
    <lomas-admin-toolbar title="Nieuwsbrief"></lomas-admin-toolbar>
    <mat-toolbar>
        <button mat-icon-button [routerLink]="['/admin/newsletters']">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
        Nieuwsbrief
    </mat-toolbar>
    <div class="cards">
        <mat-card class="newsletter compose-section">
            <mat-card-title>

                Nieuwsbrief
            </mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content *ngIf="newsletter">
                <mat-form-field class="full-width">
                    <input matInput [formControl]="subject" placeholder="Onderwerp"/>
                </mat-form-field>

                &nbsp;<button mat-raised-button (click)="save()" *ngIf="newsletter.sendDateTime === null">Opslaan</button>
                <editor [init]="options" [formControl]="content" *ngIf="newsletter.sendDateTime === null"></editor>
                <div [innerHTML]="content.value | safeHtml"></div>
            </mat-card-content>
        </mat-card>
        <div class="management-section">
            <mat-card >
                <mat-card-title>Web versie</mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <a [href]="getWebLink()" target="_blank">{{getWebLink()}}</a>
                </mat-card-content>
            </mat-card>
            <mat-card *ngIf="newsletter.sendDateTime === null">
                <mat-card-title>Test</mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <mat-form-field class="full-width">
                        <textarea class="test-mail-addresses" matInput [formControl]="previewEmail" placeholder="E-mail adressen (voor test)"></textarea>
                    </mat-form-field>
                    <div>
                        <button mat-raised-button color="primary" (click)="sendPreview()"
                                [disabled]="isInvalid() || previewEmail.invalid">Verstuur test email</button>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-title>Gegevens</mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <h2>Samenvatting</h2>
                    <table class="summary">
                        <thead>
                            <tr><th>Gegeven</th><th>Aantal</th></tr>
                        </thead>
                        <tbody>
                            <tr><td>Datum verstuurd</td><td>{{newsletter.sendDateTime | date: 'yyyy-MM-dd' }}</td></tr>
                            <tr><td>Aantal ontvangers van deze nieuwsbrief: </td><td>{{newsletter.recipientCount || 'n.n.b.'}}</td></tr>
                            <tr><td>Aantal geopend: </td><td>{{newsletter.trackerCount}}</td></tr>
                            <tr><td>Aantal uniek geopend: </td><td>{{newsletter.uniqueTrackerCount}}</td></tr>
                            <tr *ngIf="newsletter.uniqueTrackerCount > 0">
                                <td>Gemiddeld geopend: </td>
                                <td>{{(newsletter.trackerCount/newsletter.uniqueTrackerCount).toFixed(2)}}</td>
                            </tr>

                            <tr><td>Aantal huidige ontvangers: </td><td>{{newsletter.currentRecipientCount}}</td></tr>
                        </tbody>
                    </table>
                    <table class="summary">
                        <thead>
                            <tr><th>Url</th><th>Aantal clicks</th></tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let link of newsletter.summary">
                                <td>{{link.url}}</td>
                                <td>{{link.count}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="newsletter.sendDateTime === null">
                        <button mat-raised-button color="primary" (click)="send()" [disabled]="isInvalid()">Verstuur email</button>
                    </div>

<!--                    <div>
                        <h3>Batches</h3>
                        <input type="text" [(ngModel)]="batchIndex" name="batchIndex" /><button (click)="getBatch()">Get</button>
                        <div *ngIf="batch">
                            <h4>status</h4>
                            <textarea>{{batch.status | json}}</textarea>

                            <h4>batch</h4>
                            <textarea>{{batch.batch | json}}</textarea>

                            <h4>errors</h4>
                            <textarea>{{batch.errors | json}}</textarea>

                            <h4>correct</h4>
                            <textarea>{{batch.corrects | json}}</textarea>
                        </div>
                    </div>-->
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</mat-sidenav-content>