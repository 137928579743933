import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {fromEvent, Subscription} from "rxjs";

@Component({
	selector: 'lomas-audio-player',
	templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnChanges, OnDestroy {
	@Input() source: string;
	@Input() label: string;
	@Input() disabled = false;
	subscriptions = new Subscription();

	audio;
  shortFragment = true;

  duration: string = null;
  current: string = null;

	ngOnChanges() {
		this.audio = new Audio(this.source);

		this.subscriptions.add(fromEvent(this.audio, 'canplay')
      .subscribe(() => {
        this.shortFragment = this.audio.duration < 5;
        this.duration = stringifyTime(this.audio.duration);
        this.current = stringifyTime(this.audio.currentTime);
      }));

    this.subscriptions.add(fromEvent(this.audio, 'ended')
      .subscribe(() => {
        this.stop();
      }));

    this.subscriptions.add(fromEvent(this.audio, 'timeupdate')
      .subscribe(() => {
        this.current = stringifyTime(this.audio.currentTime);
      }));
	}

	ngOnDestroy() {
	  this.stop();
    this.subscriptions.unsubscribe();
  }

	stop() {
	  if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
  }

	toggleSound() {
	  if (this.audio.paused) {
	    this.audio.play();
    } else {
	    this.audio.pause();
    }
	}
}
function stringifyTime(time: number) {
  const seconds = Math.round(time);
  const minutes = Math.floor(seconds / 60) || 0;
  const secondsLeft = `00${String(seconds % 60)}`;
  return `${minutes}:${secondsLeft.substr(secondsLeft.length -2)}`
}
