import { Component, OnInit } from '@angular/core';
import { StateQuery } from '../../../store/state.store';
import { FormControl } from '@angular/forms';
import { LicenseService } from '../../../services/license/license.service';
import { CourseEnrollmentService } from '../../../services/course-enrollment/course-enrollment.service';
import { CourseEnrollmentQuery } from '../../../store/course-enrollment.store';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '../../../services/blog/blog.service';
import { PublicCourseService } from '../../../services/public-course/public-course.service';
import { PublicCourseQuery } from '../../../store/pubic-course.store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import {CourseEnrollment} from "../../../model/course-enrollment.model";

@Component({
  selector: 'lomas-learning-dashboard-page',
  templateUrl: './learning-dashboard.component.html',
	styleUrls: ['./learning-dashboard.component.scss']
})
export class LearningDashboardComponent implements OnInit {
    constructor(private stateQuery: StateQuery,
				private licenseService: LicenseService,
				private blogService: BlogService,
				private courseEnrollmentQuery:CourseEnrollmentQuery,
				private courseEnrollmentService: CourseEnrollmentService,
				private publicCourseService: PublicCourseService,
				private publicCourseQuery: PublicCourseQuery,
				private route: ActivatedRoute) {}

    licenseCode = new FormControl('');
	codeLoading = false;
	courseEnrollment$ = this.courseEnrollmentQuery.selectAll();
	course$ = this.publicCourseQuery.selectAll();
	enrollableCourses$ = combineLatest(this.courseEnrollment$, this.course$)
		.pipe(map(result => {
			const enrolledCourseIds = result[0].map(course => course.id);
			return result[1].filter(course => !enrolledCourseIds.includes(course.id));
		}));

	ngOnInit() {
		this.publicCourseService.list().subscribe();
		this.courseEnrollmentService.list().subscribe();  //TODO: this call causes a backend call to null ???
		this.blogService.list().subscribe();
		const courseId = this.route.snapshot.queryParamMap.get('courseId');
		const curriculumId = this.route.snapshot.queryParamMap.get('curriculumId');

		if (courseId) {
			this.courseEnrollmentService.addCourse(courseId).subscribe();
		} else if(curriculumId) {
			this.courseEnrollmentService.addCurriculum(curriculumId).subscribe();
		}
	}

	toggleSideNav = () => this.stateQuery.openSideNav();

	activate() {
		this.codeLoading = true;
		this.licenseService.activate(this.licenseCode.value).subscribe(() => {
			this.licenseCode.setValue('');
			this.courseEnrollmentService.list().subscribe(() => {
				this.codeLoading = false;
			}, () => {
				this.codeLoading = false;
			});
		}, () => {
			this.codeLoading = false;
		});
	}

	openCourse() {

	}

	isAccessible(enrollment: CourseEnrollment) {
	  return enrollment.publicAccessible || enrollment.endDate >= new Date();
  }
}
