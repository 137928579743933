import { ID } from '@datorama/akita';

export type LicenseCode = {
	id: ID;
	licenseId: string;
	courseId: string;
	createDate: Date;
	code: string;
	duration: number;
	fullName: string,
  resellerId: string
}

export function createLicenseCode({
								  id = null,
								  licenseId = '',
								  courseId = '',
								  createDate = null,
								  code = '',
								  duration = 0,
								  fullName = '',
                  resellerId = null}: Partial<LicenseCode>){
	return {
		id,
		licenseId,
		courseId,
		createDate,
		code,
		duration,
		fullName,
    resellerId
	}
}
