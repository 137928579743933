/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-video.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./view-video.component";
import * as i3 from "@angular/platform-browser";
var styles_ViewVideoComponent = [i0.styles];
var RenderType_ViewVideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewVideoComponent, data: {} });
export { RenderType_ViewVideoComponent as RenderType_ViewVideoComponent };
export function View_ViewVideoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "videoWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["allowfullscreen", ""], ["frameborder", "0"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }); }
export function View_ViewVideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lomas-view-video", [], null, null, null, View_ViewVideoComponent_0, RenderType_ViewVideoComponent)), i1.ɵdid(1, 573440, null, 0, i2.ViewVideoComponent, [i3.DomSanitizer], null, null)], null, null); }
var ViewVideoComponentNgFactory = i1.ɵccf("lomas-view-video", i2.ViewVideoComponent, View_ViewVideoComponent_Host_0, { id: "id" }, {}, []);
export { ViewVideoComponentNgFactory as ViewVideoComponentNgFactory };
