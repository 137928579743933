import { HttpClient } from "@angular/common/http";
import { CourseStore } from "./course.store";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./course.store";
var CourseService = /** @class */ (function () {
    function CourseService(http, courseStore) {
        this.http = http;
        this.courseStore = courseStore;
    }
    CourseService.prototype.get = function (id) {
        var _this = this;
        if (this.courseStore.entities[id]) {
            this.courseStore.setActive(id);
        }
        else {
            this.courseStore.setLoading(true);
            this.http.get('/api/course')
                .subscribe(function (response) {
                _this.courseStore.setLoading(false);
                _this.courseStore.set(response['value']);
                _this.courseStore.setActive(id);
            }, function (err) {
                _this.courseStore.setLoading(false);
                _this.courseStore.setError(err);
            });
        }
    };
    CourseService.prototype.list = function () {
        var _this = this;
        this.courseStore.setLoading(true);
        this.http.get('/api/course')
            .subscribe(function (response) {
            _this.courseStore.setLoading(false);
            _this.courseStore.set(response['value']);
        }, function (err) {
            _this.courseStore.setLoading(false);
            _this.courseStore.setError(err);
        });
    };
    CourseService.prototype.save = function (data) {
        if (!data.id) {
            return this.http.post("/api/course", data, { observe: 'response' })
                .pipe(map(function (result) {
                var keys = result.headers.keys();
                if (keys.indexOf('location') > -1) {
                    var location_1 = result.headers.get('location').split('/');
                    return location_1[location_1.length - 1];
                }
                else {
                    return '';
                }
            }));
        }
        else {
            return this.http.put("/api/course/" + data.id, data);
        }
    };
    CourseService.ngInjectableDef = i0.defineInjectable({ factory: function CourseService_Factory() { return new CourseService(i0.inject(i1.HttpClient), i0.inject(i2.CourseStore)); }, token: CourseService, providedIn: "root" });
    return CourseService;
}());
export { CourseService };
