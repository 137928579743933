<h1 mat-dialog-title>Een blogpost toevoegen</h1>
<div mat-dialog-content>
    <form >
        <p>
            Via het blog kunt u vragen of opmerkingen plaatsen over het deel van de cursus waar u op dat moment bent.
        </p>

        <mat-form-field>
            <textarea matInput placeholder="Uw vraag of opmerking" [formControl]="message"></textarea>
            <mat-hint align="end">{{message.value.length}} / 1000</mat-hint>
            <mat-error *ngIf="message.invalid && message.errors['maxlength']">Het bericht mag maximaal 1000 tekens bevatten</mat-error>
        </mat-form-field>
        <p></p>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" cdkFocusInitial >Annuleren</button>
    <button mat-raised-button color="primary" [disabled]="message.invalid" (click)="submit()">Versturen</button>
</div>