<mat-sidenav-container>
    <mat-sidenav></mat-sidenav>
    <mat-sidenav-content>

        <lomas-public-toolbar></lomas-public-toolbar>

        <section>
            <mat-card>
                <mat-card-title>Password herstellen</mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <form (submit)="submit()" *ngIf="status === 'new'">
                        <mat-form-field class="full-width">
                            <input matInput type="password" [formControl]="newPassword" placeholder="Je nieuwe wachtwoord"/>
                            <mat-error *ngIf="newPassword.invalid && newPassword.errors.required">Dit veld is verplicht</mat-error>
                            <mat-error *ngIf="newPassword.invalid && newPassword.errors.minlength">Het wachtwoord moet minimaal 6 karakters lang zijn</mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <input matInput type="password" [formControl]="newPassword2" placeholder="Nogmaals je nieuwe wachtwoord"/>
                            <mat-error *ngIf="newPassword2.invalid && newPassword2.errors.notEqual">De wachtwoorden zijn niet gelijk aan elkaar</mat-error>
                        </mat-form-field>
                        <p>
                            <button type="submit" [disabled]="newPassword.errors || newPassword2.errors" mat-raised-button color="primary">Versturen</button>
                        </p>
                    </form>
                    <div *ngIf="status !== 'new'" class="result">
                        <p *ngIf="status === 'fail'">
                            Er is iets misgegaan met het herstellen van het wachtwoord. De link is maximaal 24 uur geldig.
                            <br />
                            <a [routerLink]="['/login/forgot']">Wachtwoord vergeten</a>
                        </p>
                        <p *ngIf="status === 'success'">
                            Het wachtwoord is gewijzigd.
                            <br />
                            <a [routerLink]="['/login']">Naar loginpagina</a>
                        </p>
                    </div>
                </mat-card-content>
            </mat-card>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>


