
<h2 mat-dialog-title>Gebeurtenis toevoegen</h2>
<mat-dialog-content *ngIf="eventTypes$ | async as eventTypes">

    <mat-form-field class="full-width">
        <mat-select placeholder="Type gebeurtenis" [formControl]="eventTypeId">
            <mat-option *ngFor="let eventType of eventTypes" [value]="eventType.id">
                {{eventType.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width">
        <textarea matInput placeholder="Notitie" [formControl]="note" class="note"></textarea>
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions >
    <button mat-button mat-dialog-close>Annuleren</button>
    <button mat-raised-button (click)="save()" color="primary" [disabled]="eventTypeId.invalid || note.invalid">Beantwoorden</button>
</mat-dialog-actions>