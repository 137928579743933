export function createNewsletter(_a) {
    var _b = _a.id, id = _b === void 0 ? null : _b, _c = _a.title, title = _c === void 0 ? '' : _c, _d = _a.content, content = _d === void 0 ? '' : _d, _e = _a.createDate, createDate = _e === void 0 ? null : _e, _f = _a.sendDateTime, sendDateTime = _f === void 0 ? null : _f, _g = _a.summary, summary = _g === void 0 ? [] : _g, _h = _a.recipientCount, recipientCount = _h === void 0 ? 0 : _h, _j = _a.currentRecipientCount, currentRecipientCount = _j === void 0 ? 0 : _j, _k = _a.trackerCount, trackerCount = _k === void 0 ? 0 : _k, _l = _a.uniqueTrackerCount, uniqueTrackerCount = _l === void 0 ? 0 : _l;
    return {
        id: id,
        title: title,
        content: content,
        createDate: createDate,
        sendDateTime: sendDateTime,
        summary: summary,
        recipientCount: recipientCount,
        currentRecipientCount: currentRecipientCount,
        trackerCount: trackerCount,
        uniqueTrackerCount: uniqueTrackerCount,
    };
}
