import { Component } from '@angular/core';
import { CourseQuery } from '../../../../store/course.store';
import { FormControl, Validators } from '@angular/forms';
import { LicenseCodeService } from '../../../../services/license-code/license-code.service';
import { createLicenseCode, LicenseCode } from '../../../../model/license-code.model';
import { map } from 'rxjs/internal/operators';
import {ResellerQuery} from "../../../../store/reseller.store";

@Component({
	templateUrl: './license-code.component.html',
	styleUrls: ['./license-code.component.scss']
})
export class LicenseCodeDialogComponent {
	course = new FormControl('', [Validators.required]);
	reseller = new FormControl('', [Validators.required]);
	duration = new FormControl('', [Validators.required]);
	fullName = new FormControl('', [Validators.required]);

	status: 'new' | 'loading' | 'created' | 'error' = 'new';

	constructor(private courseQuery: CourseQuery,
				private resellerQuery: ResellerQuery,
				private licenseCodeService: LicenseCodeService) {
  }

	course$ = this.courseQuery
		.selectAll()
		.pipe(map(courses => courses.filter(course => !course.publicAccessible)));

	reseller$ = this.resellerQuery.selectAll();

	licenseCode = createLicenseCode({});

	create() {
		this.status = 'loading';
		this.licenseCodeService
			.create(this.course.value.id, this.duration.value, this.fullName.value, this.reseller.value.id)
			.subscribe(res => {
				const location = res.headers.get('location');
				const id = location.substring(location.lastIndexOf('/') + 1);
				this.licenseCodeService.get(id).subscribe(newLicenseCode => {
					this.licenseCode = <LicenseCode> newLicenseCode['value'];
					this.status = 'created';
				}, () => {
					this.status = 'error';
				});
			}, () => {
				this.status = 'error';
			})
	}

	another() {
		this.status = 'new';
	}
}
