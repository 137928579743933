import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { AccountQuery, AccountStore } from '../../store/account.store';
import { Account } from '../../model/account.model';
import { of } from 'rxjs/index';
import { Observable } from 'rxjs/Rx';

@Injectable({
	providedIn: 'root'
}	)
export class AccountService {
	constructor(private http: HttpClient,
				private accountStore: AccountStore,
				private accountQuery: AccountQuery) {}

  public pageSize = 25;
	public pageIndex = 0;
	public searchText = '';

	get(id: string) {
		return this.http.get(`/api/account/${id}`)
			.pipe(tap(result => this.accountStore.upsert(id, result['value'])));
	}

	list(ids: string[]): Observable<any[]> {
		const uniqueIds = ids
			.filter((id, index, self) => self.indexOf(id) === index)
      .filter(x => x)
			.filter(id => !this.accountQuery.getEntity(id));

		if (uniqueIds.length === 0) {
			return of([]);
		}

		return this.http.get(`/api/account?ids=${uniqueIds}`)
			.pipe(map(results => {
				results['value'].forEach(account => this.accountStore.upsert(account.id, account));
				return [];
			}))
	}

	page(top: number, skip: number, search = '', ids: string[] = []) {
		const idsProp = ids.length > 0 ? `&ids=${ids.join()}` : '';
		if (ids.length > 0) {
			top = ids.length;
			skip = 0;
		}

		return this.http.get(`/api/account/?$top=${top}&$skip=${skip}&search=${search}${idsProp}`)
			.pipe(
				tap(results => {
					const data = results['value']
						.map(row => {
							row.enrolled = row.roles !== null;
							return row;
						});
					this.accountStore.set(data)
				}),
				map(results => results['count']));
	}

	save(subscriber: Account) {
		if (subscriber.id === 'new') {
			return this.http.post('/api/account', subscriber);
		} else {
			return this.http.put(`/api/account/${subscriber.id}`, subscriber);
		}

	}
}
