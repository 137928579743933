import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  templateUrl: './edit-reseller.component.html',
  styleUrls: ['./edit-reseller.component.scss']
})
export class EditResellerComponent {

  id = null;

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    active: new FormControl(),
    ltiKey: new FormControl('', [Validators.required]),
    ltiSecret: new FormControl(),
  });

  constructor(
    public dialogRef: MatDialogRef<EditResellerComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ){
    if (data.reseller) {
      this.formGroup.get('name').setValue(data.reseller.name);
      this.formGroup.get('active').setValue(data.reseller.active);
      this.formGroup.get('ltiKey').setValue(data.reseller.ltiKey);
      this.formGroup.get('ltiSecret').setValue(data.reseller.ltiSecret);
      //this.formGroup.get('ltiSecret').disable();
      this.id = data.reseller.id;
    } else {
      this.resetLtiSecret();
      this.formGroup.get('active').setValue(true);
    }
  }

  resetLtiSecret() {
    //this.formGroup.get('ltiSecret').disable();
    this.formGroup.get('ltiSecret').setValue(randomString(10));
  }

  save() {
    this.dialogRef.close({
      ...this.formGroup.value, id: this.id
    })
  }

}

function randomString(length) {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz';

  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}
