import { Component, EventEmitter, Input, OnChanges, Output,  SimpleChanges } from '@angular/core';
import { ItemQuery } from '../../../../store/item.store';
import { Item } from '../../../../model/items.model';

@Component({
	selector: 'lomas-view-items',
	templateUrl: './view-items.component.html',
	styleUrls: ['./view-items.component.scss']
})
export class ViewItemsComponent implements OnChanges {
	@Input() ids: string[];
	@Input() selectedIndex: number = 0;
	@Output() selected = new EventEmitter();
	items: Item[] = [];
	explanationShown = false;
	selectedAnswer: string = null;

	constructor(private itemQuery: ItemQuery) {}

	ngOnChanges(change: SimpleChanges) {
		if (change['ids']) {
			this.itemQuery.selectAll().subscribe((items) => {
				this.items = this.ids.map(id => items.find(item => item.id === id));
			});
		}
		this.explanationShown = false;
		this.selectedAnswer = null;

	}

	buttonClick(index) {
		this.selected.emit(index);
	}

	showExplanation() {
		this.explanationShown = true;
	}

	answerCorrect() {
		return this.items[this.selectedIndex].data.answers[this.items[this.selectedIndex].data.correct] === this.selectedAnswer;
	}
}