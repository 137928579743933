<mat-toolbar color="primary" class="page-header mat-elevation-z4">
    <button mat-icon-button (click)="toggleSideNav()">
        <mat-icon>menu</mat-icon>
    </button>
    <img src="assets/kantooropleider.png" />
    <a [routerLink]="['/learning']" class="right-links">
        <span  [ngClass]="{'large-home-link': title}">De Kantooropleider</span>
        <span *ngIf="title" class="large-home-link"> - </span>
        <span *ngIf="title" >{{title}}</span>
    </a>
    <span class="spacer"></span>

</mat-toolbar>