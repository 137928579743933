<mat-sidenav-content class="page">
  <lomas-admin-toolbar title="Licentie overzicht"></lomas-admin-toolbar>
  <mat-card>
    <mat-card-title>Licenties</mat-card-title>
    <mat-divider></mat-divider>
<mat-card-content>
  <form [formGroup]="formGroup">
    <mat-form-field >
    <mat-select placeholder="Maand" formControlName="month">
      <mat-option *ngFor="let month of months; let i = index" [value]="i">
        {{month}}
      </mat-option>
    </mat-select>
    </mat-form-field>
    <mat-form-field >
    <mat-select placeholder="Jaar" formControlName="year">
      <mat-option *ngFor="let year of years" [value]="year">
        {{year}}
      </mat-option>
    </mat-select>
    </mat-form-field>
  </form>

  <table *ngIf="licenses !== null">
    <thead>
      <tr>
        <th>Datum aangemaakt</th>
        <th>Cursist</th>
        <th>Reseller</th>
        <th>Cursus</th>
        <th>Eerdere licenties</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let license of licenses">
        <td>{{license.startDate}}</td>
        <td>{{getAccount(license.account)}}</td>
        <td>{{getReseller(license.reseller)}}</td>
        <td>{{getReseller(license.course)}}</td>
        <td>{{license.previousLicenseCount}}</td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="licenses && licenses.length === 0">Er zijn geen licenties in deze periode</div>

    </mat-card-content>
  </mat-card>

</mat-sidenav-content>

