import { EntityState, EntityStore, ID, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { createAccount, Account } from '../model/account.model';
import { Observable } from 'rxjs/Rx';
import { CourseEnrollment } from '../model/course-enrollment.model';

export interface EnrollmentOverviewState extends EntityState<CourseEnrollment> {
	size: number;
	pageIndex: number;
	total: number;
	pageIds: ID[];
}

@StoreConfig({name: 'enrollmentOverview'})
export class EnrollmentOverviewStore extends EntityStore<EnrollmentOverviewState, CourseEnrollment> {

	upsertPage(courseEnrollments:CourseEnrollment[], size: number, pageIndex: number, total: number) {
		const courseEnrollmentIds = courseEnrollments
			.map(enrollment => {
				this.upsert(enrollment.id, enrollment);
				return enrollment.id;
			});

		this.updateRoot({
			size: size,
			pageIndex: pageIndex,
			total: total,
			pageIds: courseEnrollmentIds
		})
	}

	constructor() {
		super({size: 25, pageIndex: 0, total: 0});
	}
}

@Injectable({providedIn: 'root'})
export class EnrollmentOverviewQuery extends QueryEntity<EnrollmentOverviewState, CourseEnrollment> {

	getPageIndex(): number {
		return this.getSnapshot().pageIndex;
	}

	getSize(): number {
		return this.getSnapshot().size;
	}

	getTotal(): number {
		return this.getSnapshot().total;
	}

	selectPageIndex(): Observable<number> {
		return this.select(state => state.pageIndex);
	}

	selectSize(): Observable<number> {
		return this.select(state => state.size);
	}

	selectTotal(): Observable<number> {
		return this.select(state => state.total)
	}

	selectPage(): Observable<CourseEnrollment[]>{
		return this.select(state => state.pageIds.map(id => this.getEntity(id)));
	}

	constructor(protected store: EnrollmentOverviewStore) {
		super(store);
	}
}