import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccountService } from '../../../services/useraccount/useraccount.service';

@Component({
	templateUrl: './confirm-account.component.html',
	styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit {
	status: 'new' | 'fail' | 'success' | 'loggedIn' = 'new';

	constructor(private authService: AuthService,
				private accountService: UserAccountService,
				private route: ActivatedRoute,
				private router: Router) {}

	ngOnInit() {
		const code = this.route.snapshot.queryParamMap.get('code');
		this.accountService.confirmAccount(code).subscribe(() => {

			this.authService.refreshToken().subscribe(() => {
				this.status = 'loggedIn';
			}, () => {
				this.status = 'success';
			})
		}, () => {
			this.status = 'fail';
		});
	}

	navigate(event) {
		event.preventDefault();
		const payload = this.authService.getPayload();
		if (payload.roles.indexOf('admin') > -1) {
			this.router.navigate(['admin']);
		} else {
			this.router.navigate(['learning']);
		}
	}
}