import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Blog } from '../../model/blog.model';
import { map, tap } from 'rxjs/internal/operators';
import { BlogStore } from '../../store/blog.store';

@Injectable()
export class BlogService {
	constructor(private http: HttpClient,
				private blogStore: BlogStore){}

	list() {
		return this.http
			.get('/api/blog')
			.pipe(map(results => this.blogStore.set(results['value'])));
	}

	save(blog: Blog) {
		if (blog.id === 'new') {
			return this.http.post('/api/blog', blog)
				.pipe(tap(() => this.list().subscribe()));
		} else {
			return this.http.put(`/api/blog/${blog.id}`, blog)
				.pipe(tap(() => this.list().subscribe()));
		}
	}
}