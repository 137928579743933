import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	templateUrl: './confirm-unsubscribe-account.component.html',
	styleUrls: ['./confirm-unsubscribe-account.component.scss']
})
export class ConfirmUnsubscribeAccountComponent {
	constructor(private dialogRef: MatDialogRef<ConfirmUnsubscribeAccountComponent>,
				@Inject(MAT_DIALOG_DATA) public data )
	{}

	ok() {
		this.dialogRef.close(true);
	}
}