import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAccountService } from '../../../services/useraccount/useraccount.service';
import { UserAccount } from '../../../model/user-account.model';
import { ActivatedRoute } from '@angular/router';
import { PublicCourseService } from '../../../services/public-course/public-course.service';
import { PublicCourseQuery } from '../../../store/pubic-course.store';
import { find, map } from 'rxjs/internal/operators';

@Component({
	templateUrl: './create-account.component.html',
	styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
	createAccountForm = new FormGroup({
		firstName: new FormControl('', [Validators.required]),
		preposition: new FormControl(''),
		surName: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required, Validators.email]),
		password: new FormControl('', [Validators.required, Validators.minLength(6)])
	});


	status: 'new' | 'success' | 'exists' | 'fail' = 'new';

	constructor(private accountService: UserAccountService,
				private publicCourseService: PublicCourseService,
				private publicCourseQuery: PublicCourseQuery,
				private route: ActivatedRoute) {}

	get firstName(): any { return this.createAccountForm.get('firstName'); }
	get preposition(): any { return this.createAccountForm.get('preposition'); }
	get surName(): any { return this.createAccountForm.get('surName'); }
	get email(): any { return this.createAccountForm.get('email'); }
	get password(): any { return this.createAccountForm.get('password'); }

	course$ = this.publicCourseQuery.selectAll()
		.pipe(map(courses => {return courses.find(course => course.id === this.route.snapshot.queryParamMap.get('courseId'))}));
			//.pipe(find(course => course['id'] === this.route.snapshot.queryParamMap.get('courseId')))

	ngOnInit() {
		this.publicCourseService.list().subscribe();
	}

	submit() {
		const account = <UserAccount> this.createAccountForm.value;
		this.accountService.createAccount(account, this.route.snapshot.queryParamMap.get('courseId')).subscribe((res) => {
			this.status = 'success';
		}, (err) => {
			if (err.error.code === 'ALREADY_EXISTS') {
				this.status = 'exists';
			} else {
				this.status = 'fail';
			}
		})
	}
}