import {Injectable} from "@angular/core";
import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {Course} from "./course.model";
import { PageEvent } from "@angular/material/paginator";

export interface CourseState extends EntityState<Course>, ActiveState {}


const initialState = {
  page: {
    length: 0,
    pageIndex: 0,
    pageSize: 50
  }
};

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'course-design'})
export class CourseStore extends EntityStore<CourseState, Course> {
  constructor() {
    super(initialState);
  }

}
