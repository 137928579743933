import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ResellerStore} from "../../store/reseller.store";
import {tap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class ResellerService {
  constructor(
    private http: HttpClient,
    private resellerStore: ResellerStore
  ) {}

  list() {
    this.http.get('/api/reseller')
      .pipe(tap(results => this.resellerStore.set(results['value'])))
      .subscribe();
  }

  save(data) {
    if (!data.id) {
      return this.http.post('/api/reseller', data)
        .pipe(tap(() => {this.list()}))
    } else {
      return this.http.put(`/api/reseller/${data.id}`, data)
        .pipe(tap(() => {this.list()}));
    }
  }
}
