import { Component, Input, OnChanges } from '@angular/core';
import { CourseEnrollmentService } from '../../../../services/course-enrollment/course-enrollment.service';

@Component({
	selector: 'lomas-view-content',
	templateUrl: 'view-content.component.html',
	styleUrls: ['./view-content.component.scss']
})
export class ViewContentComponent implements OnChanges {
	@Input() id: string;
	@Input() courseId: string;

	html = '';
	constructor(private courseEnrollmentService: CourseEnrollmentService) {}

	ngOnChanges() {
		this.courseEnrollmentService.content(this.courseId, this.id).subscribe(res => this.html = res['value'].html);
	}
}