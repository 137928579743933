import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsletterService } from '../../../services/newsletter/newsletter.service';
import { createNewsletter, Newsletter } from '../../../model/newsletter.model';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { ID } from '@datorama/akita';
import { ConfirmSendNewsletterComponent } from '../dialogs/confirm-send-newsletter/confirm-send-newsletter.component';
import { WINDOW } from '../../../window.provider';

const previewAddresses = [
	'nicky@lsso.nl',
	'lyn@lsso.nl',
	'ja@lsso.nl',
	'jurgen@lsso.nl',
	'ria@lsso.nl',
	'werner@lsso.nl'
];

@Component({
	templateUrl: './newsletter.component.html',
	styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
	newsletter: Newsletter;

	previewEmail = new FormControl(previewAddresses.join('\n'), [Validators.required]);
	subject = new FormControl('', [Validators.required]);
	content = new FormControl('', [Validators.required]);
	batchIndex: number;
	batch: any = null;

	constructor(private activatedRoute: ActivatedRoute,
				private newsletterService: NewsletterService,
				private snackBar: MatSnackBar,
				private router: Router,
				private dialog: MatDialog,
				@Inject(WINDOW) private window: Window)
	{}

	ngOnInit() {
		const id = this.activatedRoute.snapshot.paramMap.get('id');
		if (id === 'new') {
			this.newsletter = createNewsletter({id: id});
		} else {
			this.loadData(id)
		}
	}

	loadData(id: string| ID) {
		this.newsletterService.get(id).subscribe(newsletter => {
			this.newsletter = newsletter;
			this.subject.setValue(newsletter.title);
			this.content.setValue(newsletter.content);
			if (this.newsletter.sendDateTime) {
				this.subject.disable();
				this.content.disable();
			}
		});
	}

	getWebLink() {
		return `${this.window.location.origin}/newsletter-web/${this.newsletter.id}`;
	}

	save() {
		this.updateNewsletter();
		this.newsletterService.save(this.newsletter).subscribe(() => {
			this.router.navigate(['/admin/newsletters'])
		}, () => {
			this.snackBar.open('De mailing kon niet worden opgeslagen', 'ok', {duration: 4000})
		});
	}

	sendPreview() {
		this.updateNewsletter();
		this.newsletterService.save(this.newsletter).subscribe((id) => {
			this.newsletterService.preview(id, this.previewEmail.value.split('\n')).subscribe(() => {
				this.snackBar.open('Voorbeeld is verstuurd', 'ok', {duration: 4000});
				if (this.newsletter.id === 'new') {
					this.router.navigate([`/admin/newsletters/${id}`]);
				}
			}, () => {
				this.snackBar.open('Voorbeeld kon niet worden verstuurd!', 'ok', {duration: 4000})
			});
		});
	}

	send() {
		this.updateNewsletter();
		this.newsletterService.save(this.newsletter).subscribe((id) => {
			this.dialog.open(ConfirmSendNewsletterComponent, {width: '300px', height: '300px'})
				.afterClosed().subscribe(result => {
					if(result) {
						this.newsletterService.send(id).subscribe(() => {
							this.snackBar.open('Mailing is verstuurd', 'ok', {duration: 4000});
							if (this.newsletter.id === 'new') {
								this.router.navigate([`/admin/newsletters/${id}`]);
							} else {
								this.loadData(this.newsletter.id)
							}
						}, () => {
							this.snackBar.open('Mailing kon niet worden verstuurd!', 'ok', {duration: 4000})
						});
					}
			});
		}, () => {
			this.snackBar.open('Mailing kon niet worden verstuurd!', 'ok', {duration: 4000})
		});
	}

	isInvalid() {
		return this.content.invalid || this.subject.invalid;
	}

	private updateNewsletter () {
		this.newsletter.title = this.subject.value;
		this.newsletter.content = this.content.value;
	}

	getBatch() {
		this.batch = null;
		this.newsletterService.getBatch(this.newsletter.id, this.batchIndex)
			.subscribe(res => {
				this.batch = res;

				if (this.batch.status.response && this.batch.status.response.body && this.batch.status.response.body.errors) {
					const personIndices = this.batch.status.response.body.errors.map(error => {
						const parts = error.field.split('.');
						return parseInt(parts[1], 10);
					});
					this.batch.errors = this.batch.batch.filter((entry, index) => {return personIndices.includes(index);})
					this.batch.corrects = this.batch.batch.filter((entry, index) => {return !personIndices.includes(index);})
				}
			})
	}

	resend() {
		this.newsletterService.resend(this.newsletter.id, this.batchIndex).subscribe();
	}

	options = {
		relative_urls : false,
		remove_script_host : false,
		height: '540',
		language: 'nl',
		browser_spellcheck : true,
		toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor',
		plugins: [
			"advlist autolink lists link image charmap print preview anchor",
			"searchreplace visualblocks code fullscreen",
			"insertdatetime media table contextmenu paste imagetools wordcount",
			"textcolor"
		],
		textcolor_map: [
			"000000", "Zwart",
			"2A3140", "Donkergrijs",
			"515363", "Middengrijs",
			"E4E4E8", "Lichtgrijs",
			"FFFFFF", "Wit",
			"C42729", "Rood"
		]
	}
}