import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { Blog } from '../../../../model/blog.model';

@Component({
	templateUrl: 'add-blogpost.component.html',
	styleUrls: ['./add-blogpost.component.scss']
})
export class AddBlogpostComponent {

	blog = <Blog>{id: 'new'};
	message = new FormControl('', [Validators.required, Validators.maxLength(1000)]);

	constructor(
		public dialogRef: MatDialogRef<AddBlogpostComponent>,
		@Inject(MAT_DIALOG_DATA) public data) {}


	submit() {
		this.dialogRef.close(<Blog>{id: this.blog.id, message: this.message.value})
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
}