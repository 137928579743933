import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Course, createCourse } from '../model/course.model';

const initialState: Course = createCourse({});

export interface PublicCourseState extends EntityState<Course> {
}

@StoreConfig({name: 'course'})
export class PublicCourseStore extends EntityStore<PublicCourseState, Course> {
	constructor() {
		super(initialState);
	}
}

@Injectable({
	providedIn: 'root'
})
export class PublicCourseQuery extends QueryEntity<PublicCourseState, Course> {
	constructor(protected store: PublicCourseStore) {
		super(store);
	}
}
