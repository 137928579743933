<mat-sidenav-container>
    <mat-sidenav></mat-sidenav>
    <mat-sidenav-content>

        <lomas-public-toolbar></lomas-public-toolbar>

        <section>
            <mat-card>
                <mat-card-title>Uitgelogd</mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <div class="result">
                        Je bent uitgelogd. <a [routerLink]="['/']">Naar de homepage.</a>
                    </div>

                </mat-card-content>
            </mat-card>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>
