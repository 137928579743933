var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { createAccount } from '../model/user-account.model';
import { Query, Store, StoreConfig } from '@datorama/akita';
import * as i0 from "@angular/core";
var initialState = createAccount({});
var UserAccountStore = /** @class */ (function (_super) {
    __extends(UserAccountStore, _super);
    function UserAccountStore() {
        return _super.call(this, initialState) || this;
    }
    UserAccountStore = __decorate([
        StoreConfig({ name: 'user-account' }),
        __metadata("design:paramtypes", [])
    ], UserAccountStore);
    return UserAccountStore;
}(Store));
export { UserAccountStore };
var UserAccountQuery = /** @class */ (function (_super) {
    __extends(UserAccountQuery, _super);
    function UserAccountQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.account$ = _this.select(function (account) { return account; });
        return _this;
    }
    UserAccountQuery.ngInjectableDef = i0.defineInjectable({ factory: function UserAccountQuery_Factory() { return new UserAccountQuery(i0.inject(UserAccountStore)); }, token: UserAccountQuery, providedIn: "root" });
    return UserAccountQuery;
}(Query));
export { UserAccountQuery };
