import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {map, tap, switchMap} from 'rxjs/internal/operators';
import { LicenseCodeStore } from '../../store/license-code.store';
import {ResellerStore} from "../../store/reseller.store";

@Injectable()
export class LicenseCodeService {
	constructor(private http: HttpClient,
				private licenseCodeStore: LicenseCodeStore,
              private resellerStore: ResellerStore,) {}

	list(top: number, skip: number) {
		let params = new HttpParams();
		params = params.set('$top', String(top));
		params = params.set('$skip', String(skip));
		return this.http
			.get('/api/licensecode',{ params})
			.pipe(
			  tap(results => this.licenseCodeStore.set(results['value'])),
        //tap(results => this.licenseCodeStore.updateCount(results['count'])),
        //switchMap(() => this.http.get('/api/reseller')),
        //tap(results => this.resellerStore.set(results['value'])),
        map(results => results['count']),

      );
	}


 	get(id) {
		return this.http
			.get(`/api/licensecode/${id}`);
	}

	create(courseId, duration, fullName, resellerId) {
		return this.http
			.post('/api/licensecode', {duration, courseId, fullName, resellerId}, {observe: 'response'})
	}
}
