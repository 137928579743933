/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/icon";
import * as i5 from "./icon.component";
var styles_IconComponent = [i0.styles];
var RenderType_IconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IconComponent, data: {} });
export { RenderType_IconComponent as RenderType_IconComponent };
export function View_IconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "fa-lg mat-icon"], ["fontSet", "fa"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(2, 638976, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null]], { fontSet: [0, "fontSet"], fontIcon: [1, "fontIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fa-lg"; var currVal_2 = ("fa-" + _co.name); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = "fa"; var currVal_4 = _co.name; _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 0, 0, currVal_0); }); }
export function View_IconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lomas-icon", [], null, null, null, View_IconComponent_0, RenderType_IconComponent)), i1.ɵdid(1, 49152, null, 0, i5.IconComponent, [], null, null)], null, null); }
var IconComponentNgFactory = i1.ɵccf("lomas-icon", i5.IconComponent, View_IconComponent_Host_0, { name: "name" }, {}, []);
export { IconComponentNgFactory as IconComponentNgFactory };
