import {Component, OnInit} from "@angular/core";
import {ResellerQuery} from "../../../store/reseller.store";
import {ResellerService} from "../../../services/resellers/resellers.service";
import {MatDialog, MatSnackBar} from "@angular/material";
import {EditResellerComponent} from "../dialogs/edit-reseller/edit-reseller.component";

@Component({
  templateUrl: './resellers.component.html',
  styleUrls: ['./resellers.component.scss']
})
export class ResellersComponent implements OnInit {
  reseller$ ;
  displayedColumns = ['name', 'active', 'key', 'secret', 'edit'];
  constructor(
    private resellerQuery: ResellerQuery,
    private resellerService: ResellerService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.resellerService.list();
    this.reseller$ = this.resellerQuery.selectAll();
  }

  addReseller() {
    this.editReseller();
  }

  editReseller(reseller?) {
    this.dialog.open(EditResellerComponent, {
      width: '500px',
      height: '500px',
      data: {reseller}
    }).afterClosed().subscribe(reseller => {
      if(reseller) {
        this.resellerService.save(reseller)
          .subscribe(() =>
            this.snackBar.open('Gegevens opgeslagen','ok', {duration: 4000}),
          () =>
            this.snackBar.open('Er is iets misgegaan met het opslaan van de gegevens','ok', {duration: 4000})
          );
      }
    })

  }
}

