<mat-sidenav-content class="page">
  <lomas-admin-toolbar title="Cursussen"></lomas-admin-toolbar>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Overzicht cursussen</mat-card-title>
      <span class="flex-title"></span>
    </mat-card-header>
    <mat-divider></mat-divider>
      <mat-card-content *ngIf="courses$ | async as courses">
        <mat-table [dataSource]="courses" class="mat-elevation-z8" *ngIf="courses.length > 0">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Naam</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let element"> <button mat-raised-button (click)="editCourse(element.id)">Wijzigen</button> </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </mat-card-content>
  </mat-card>
</mat-sidenav-content>
<a mat-fab color="accent" class="mat-fab-bottom-right fixed" (click)="add()">
  <mat-icon>add</mat-icon>
</a>
