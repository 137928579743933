var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { AccountQuery } from '../../../store/account.store';
import { EventQuery } from '../../../store/event.store';
import { EventService } from '../../../services/event/event.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EditEventComponent } from '../dialogs/edit-event/edit-event.component';
import { EventTypeService } from '../../../services/event-type/event-type.service';
import { EventTypeQuery } from '../../../store/event-type.store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../../services/account/account.service';
import { ConfirmUnsubscribeAccountComponent } from '../dialogs/confirm-unsubscribe-account/confirm-unsubscribe-account.component';
import { UnsubscribeService } from '../../../services/unsubscribe/unsubscribe.service';
import { EditActionComponent } from "../dialogs/edit-action/edit-action.component";
import { ActionService } from "../../../services/action/action.service";
import { EnrollmentOverviewService } from '../../../services/enrollment-overview/enrollment-overview.service';
import { ActionQuery } from "../../../store/action.store";
import { CompleteActionComponent } from "../dialogs/complete-action/complete-action.component";
import { formatDate } from "@angular/common";
var AccountComponent = /** @class */ (function () {
    function AccountComponent(accountQuery, eventQuery, eventService, dialog, snackBar, eventTypeService, eventTypeQuery, subscriberService, unsubscribeService, accountService, actionService, actionQuery, enrollmentOverviewService) {
        this.accountQuery = accountQuery;
        this.eventQuery = eventQuery;
        this.eventService = eventService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.eventTypeService = eventTypeService;
        this.eventTypeQuery = eventTypeQuery;
        this.subscriberService = subscriberService;
        this.unsubscribeService = unsubscribeService;
        this.accountService = accountService;
        this.actionService = actionService;
        this.actionQuery = actionQuery;
        this.enrollmentOverviewService = enrollmentOverviewService;
        this.eventDisplayedColumns = ['dateTime', 'eventType', 'adminName', 'note'];
        this.eventPageSize = 25;
        this.eventPageIndex = 0;
        this.events$ = this.eventQuery.selectAll();
        this.eventTypes = {};
        this.actionDisplayedColumns = ['dueDate', 'eventType', 'adminName', 'note', 'actions'];
        this.actionPageSize = 25;
        this.actionPageIndex = 0;
        this.actions$ = this.actionQuery.selectAll();
        this.courseEnrollments = [];
        this.accountDetailsForm = new FormGroup({
            firstName: new FormControl('', [Validators.required]),
            preposition: new FormControl('', [Validators.required]),
            surName: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required]),
            note: new FormControl('')
        });
    }
    AccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.account = this.accountQuery.getActive();
        this.loadData();
        this.eventTypeService.list(0, 1000).subscribe();
        this.eventTypeQuery.selectAll().subscribe(function (eventTypes) {
            _this.eventTypes = eventTypes.reduce(function (types, eventType) {
                types[eventType.id] = eventType;
                return types;
            }, {});
        });
    };
    AccountComponent.prototype.loadData = function () {
        var _this = this;
        if (this.account) {
            this.accountDetailsForm.setValue({
                firstName: this.account.firstName,
                preposition: this.account.preposition,
                surName: this.account.surName,
                email: this.account.email,
                note: this.account.note
            });
            this.eventService.list(this.eventPageSize, this.eventPageIndex * this.eventPageSize, this.account.id)
                .subscribe(function (result) { return _this.eventTotal = result; });
            this.actionService.list(this.actionPageSize, this.actionPageIndex * this.actionPageSize, this.account.id)
                .subscribe(function (result) { return _this.actionTotal = result; });
            this.enrollmentOverviewService.accountList(this.account.id).subscribe(function (results) {
                _this.courseEnrollments = results.map(function (result) {
                    var parts = (result.chapters || []).reduce(function (total, chapter) {
                        chapter.parts.forEach(function (part) {
                            total[part.id] = chapter.name + " - " + part.name;
                        });
                        return total;
                    }, {});
                    if (result.progress) {
                        result.progress.map(function (entry) {
                            entry.date = formatDate(entry.t, 'medium', 'nl');
                            entry.partName = entry.a === 'L' ? 'LTI Launch' : (parts[entry.p] || 'Introscherm');
                            return entry;
                        });
                    }
                    return result;
                });
            });
        }
    };
    AccountComponent.prototype.actionPageChange = function (event) {
        this.actionPageSize = event.pageSize;
        this.actionPageIndex = event.pageIndex;
        this.loadData();
    };
    AccountComponent.prototype.eventPageChange = function (event) {
        this.eventPageSize = event.pageSize;
        this.eventPageIndex = event.pageIndex;
        this.loadData();
    };
    AccountComponent.prototype.save = function () {
        var _this = this;
        this.subscriberService.save(__assign({}, this.accountDetailsForm.value, { id: this.account.id })).subscribe(function () {
            _this.snackBar.open('Gegegevens zijn opgeslagen', 'ok', { duration: 4000 });
        }, function () {
            _this.snackBar.open('Er is iets misgegaan met het opslaan van de gegevens', 'ok', { duration: 4000 });
        });
    };
    AccountComponent.prototype.addEvent = function () {
        var _this = this;
        this.dialog.open(EditEventComponent, {
            width: '500px',
            height: '500px'
        }).afterClosed().subscribe(function (result) {
            if (result) {
                result.accountId = _this.account.id;
                _this.eventService.save(result).subscribe(function () {
                    _this.loadData();
                }, function () {
                    _this.snackBar.open('er is iets misgegaan met het opslaan van de data', 'ok', { duration: 4000 });
                });
            }
        });
    };
    AccountComponent.prototype.addAction = function () {
        this.editAction();
    };
    AccountComponent.prototype.unsubscribeAccount = function () {
        var _this = this;
        this.dialog.open(ConfirmUnsubscribeAccountComponent, {
            width: '500px',
            height: '500px',
            data: {
                account: this.account
            }
        }).afterClosed().subscribe(function (result) {
            if (result === true) {
                _this.unsubscribeService.unsubscribe(_this.account.id)
                    .subscribe(function () { return _this.accountService.get(_this.account.id)
                    .subscribe(function () {
                    _this.snackBar.open('Uitschrijven is gelukt', 'ok', { duration: 4000 });
                    _this.account = _this.accountQuery.getActive();
                }); }, function () {
                    _this.snackBar.open('Er is iets misgegaan met het uitschrijven', 'ok', { duration: 4000 });
                });
            }
        });
    };
    AccountComponent.prototype.getAccountName = function (id) {
        var account = this.accountQuery.getEntity(id);
        return account ? account.firstName + " " + account.preposition + " " + account.surName : '';
    };
    AccountComponent.prototype.isDue = function (date) {
        return (new Date(date) <= new Date());
    };
    AccountComponent.prototype.editAction = function (action) {
        var _this = this;
        this.dialog.open(EditActionComponent, {
            width: '500px',
            height: '500px',
            data: {
                action: action
            }
        }).afterClosed().subscribe(function (result) {
            if (result) {
                result.accountId = _this.account.id;
                _this.actionService.save(result).subscribe(function () {
                    _this.loadData();
                }, function () {
                    _this.snackBar.open('er is iets misgegaan met het opslaan van de data', 'ok', { duration: 4000 });
                });
            }
        });
    };
    AccountComponent.prototype.completeAction = function (action) {
        var _this = this;
        this.dialog.open(CompleteActionComponent, {
            width: '500px',
            height: '500px',
            data: {
                action: action
            }
        }).afterClosed().subscribe(function (result) {
            if (result) {
                result.accountId = _this.account.id;
                _this.eventService.save(result).subscribe(function () {
                    _this.actionService.complete(result.actionId).subscribe(function () {
                        console.log('done');
                        _this.loadData();
                    }, function () {
                        _this.snackBar.open('er is iets misgegaan met het opslaan van de data', 'ok', { duration: 4000 });
                    });
                }, function () {
                    _this.snackBar.open('er is iets misgegaan met het opslaan van de data', 'ok', { duration: 4000 });
                });
                console.log(result);
            }
        });
    };
    AccountComponent.prototype.getLatestProgressDate = function (progress) {
        console.log(progress);
        return progress[progress.length - 1].t;
    };
    return AccountComponent;
}());
export { AccountComponent };
