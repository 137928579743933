var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Account } from '../model/account.model';
import { Observable } from 'rxjs/Rx';
import * as i0 from "@angular/core";
var AccountStore = /** @class */ (function (_super) {
    __extends(AccountStore, _super);
    function AccountStore() {
        return _super.call(this, { search: '' }) || this;
    }
    AccountStore = __decorate([
        StoreConfig({ name: 'store' }),
        __metadata("design:paramtypes", [])
    ], AccountStore);
    return AccountStore;
}(EntityStore));
export { AccountStore };
var AccountQuery = /** @class */ (function (_super) {
    __extends(AccountQuery, _super);
    function AccountQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.search$ = Observable.of(_this.getSnapshot().search);
        return _this;
    }
    AccountQuery.prototype.selectSearch = function () {
        return this.select(function (state) { return state.search; });
    };
    AccountQuery.ngInjectableDef = i0.defineInjectable({ factory: function AccountQuery_Factory() { return new AccountQuery(i0.inject(AccountStore)); }, token: AccountQuery, providedIn: "root" });
    return AccountQuery;
}(QueryEntity));
export { AccountQuery };
