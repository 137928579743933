import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAccountService } from '../../../services/useraccount/useraccount.service';
import { ActivatedRoute } from '@angular/router';
import { PublicCourseService } from '../../../services/public-course/public-course.service';
import { PublicCourseQuery } from '../../../store/pubic-course.store';
import { map } from 'rxjs/internal/operators';
var CreateAccountComponent = /** @class */ (function () {
    function CreateAccountComponent(accountService, publicCourseService, publicCourseQuery, route) {
        var _this = this;
        this.accountService = accountService;
        this.publicCourseService = publicCourseService;
        this.publicCourseQuery = publicCourseQuery;
        this.route = route;
        this.createAccountForm = new FormGroup({
            firstName: new FormControl('', [Validators.required]),
            preposition: new FormControl(''),
            surName: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)])
        });
        this.status = 'new';
        this.course$ = this.publicCourseQuery.selectAll()
            .pipe(map(function (courses) { return courses.find(function (course) { return course.id === _this.route.snapshot.queryParamMap.get('courseId'); }); }));
    }
    Object.defineProperty(CreateAccountComponent.prototype, "firstName", {
        get: function () { return this.createAccountForm.get('firstName'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateAccountComponent.prototype, "preposition", {
        get: function () { return this.createAccountForm.get('preposition'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateAccountComponent.prototype, "surName", {
        get: function () { return this.createAccountForm.get('surName'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateAccountComponent.prototype, "email", {
        get: function () { return this.createAccountForm.get('email'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateAccountComponent.prototype, "password", {
        get: function () { return this.createAccountForm.get('password'); },
        enumerable: true,
        configurable: true
    });
    //.pipe(find(course => course['id'] === this.route.snapshot.queryParamMap.get('courseId')))
    CreateAccountComponent.prototype.ngOnInit = function () {
        this.publicCourseService.list().subscribe();
    };
    CreateAccountComponent.prototype.submit = function () {
        var _this = this;
        var account = this.createAccountForm.value;
        this.accountService.createAccount(account, this.route.snapshot.queryParamMap.get('courseId')).subscribe(function (res) {
            _this.status = 'success';
        }, function (err) {
            if (err.error.code === 'ALREADY_EXISTS') {
                _this.status = 'exists';
            }
            else {
                _this.status = 'fail';
            }
        });
    };
    return CreateAccountComponent;
}());
export { CreateAccountComponent };
