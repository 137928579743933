export function createCourse(_a) {
    var _b = _a.id, id = _b === void 0 ? null : _b, _c = _a.moduleId, moduleId = _c === void 0 ? '' : _c, _d = _a.chapters, chapters = _d === void 0 ? [] : _d, _e = _a.name, name = _e === void 0 ? '' : _e, _f = _a.active, active = _f === void 0 ? false : _f, _g = _a.progress, progress = _g === void 0 ? null : _g, _h = _a.publicAccessible, publicAccessible = _h === void 0 ? false : _h, _j = _a.thumbnail, thumbnail = _j === void 0 ? '' : _j, _k = _a.ltiLaunch, ltiLaunch = _k === void 0 ? null : _k;
    return {
        id: id,
        moduleId: moduleId,
        chapters: chapters,
        name: name,
        active: active,
        progress: progress,
        publicAccessible: publicAccessible,
        thumbnail: thumbnail,
        ltiLaunch: ltiLaunch,
    };
}
var Progress = /** @class */ (function () {
    function Progress() {
    }
    return Progress;
}());
export { Progress };
