import { EntityState, EntityStore, ID, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { CourseEnrollment, createCourseEnrollment } from '../model/course-enrollment.model';

const initialState: CourseEnrollment = createCourseEnrollment({});

export interface CourseEnrollmentState extends EntityState<CourseEnrollment> {
}

@StoreConfig({name: 'course-enrollment'})
export class CourseEnrollmentStore extends EntityStore<CourseEnrollmentState, CourseEnrollment> {
	constructor() {
		super(initialState);
	}

	updateProgress(id: ID, progress?) {
		this.update(id, enrollment => {
			console.log(enrollment);
			return enrollment;
		})
	}
}

@Injectable({providedIn: 'root'})
export class CourseEnrollmentQuery extends QueryEntity<CourseEnrollmentState, CourseEnrollment> {

	constructor(protected store: CourseEnrollmentStore) {
		super(store);
	}

	sel() {
	}
}
