import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AnswerPostComponent } from '../answer-post/answer-post.component';

@Component({
	templateUrl: './confirm-send-newsletter.component.html',
	styleUrls: ['./confirm-send-newsletter.component.scss']
})
export class ConfirmSendNewsletterComponent {
	constructor(public dialogRef: MatDialogRef<AnswerPostComponent>,
				@Inject(MAT_DIALOG_DATA) public data) {
	}

	ok() {
		this.dialogRef.close(true)
	}

	cancel() {

	}
}