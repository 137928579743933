<mat-sidenav-content class="page">
    <lomas-admin-toolbar title="Personen"></lomas-admin-toolbar>

    <div class="cards">
        <mat-card>
            <mat-card-title class="search-bar">
                <span class="search-bar__title">Personen</span>
                <mat-form-field class="search-bar__input">
                    <input matInput [formControl]="searchText" placeholder="Zoeken"/>
                </mat-form-field>
            </mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content *ngIf="subscribers$ | async as subscribers">
                    <mat-table [dataSource]="subscribers" class="mat-elevation-z8" *ngIf="subscribers.length > 0">

                        <ng-container matColumnDef="firstName">
                            <mat-header-cell *matHeaderCellDef> Voornaam </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.firstName }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="surName">
                            <mat-header-cell *matHeaderCellDef> Achternaam </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.preposition}} {{element.surName }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="enrolled">
                            <mat-header-cell *matHeaderCellDef> Is cursist </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.enrolled? 'Ja': 'Nee'}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> <button mat-button (click)="showAccount(element.id)">Openen</button> </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                    <p></p>
                    <mat-paginator *ngIf="subscribers.length > 0"
                                   [length]="total"
                                   [pageSize]="size"
                                   [pageSizeOptions]="[5, 10, 25, 100]"
                                   (page)="pageChange($event)">
                    </mat-paginator>
            </mat-card-content>
        </mat-card>
    </div>
</mat-sidenav-content>
<a mat-fab color="accent" class="mat-fab-bottom-right fixed" (click)="add()">
    <mat-icon>add</mat-icon>
</a>
