<button mat-raised-button
        matTooltip="Geluidsfragment"
        *ngIf="shortFragment && duration"
        [disabled]="disabled"
        (click)="toggleSound()">
    <mat-icon *ngIf="audio.paused">play_circle_outline</mat-icon>
    <mat-icon *ngIf="!audio.paused">pause_circle_outline</mat-icon>
    {{label}}
</button>
<span *ngIf="duration && !shortFragment">
  {{label}} <br />
  <button mat-raised-button
          matTooltip="Geluidsfragment"
          [disabled]="disabled"
          (click)="toggleSound()">
    <mat-icon *ngIf="audio.paused">play_circle_outline</mat-icon>
    <mat-icon *ngIf="!audio.paused">pause_circle_outline</mat-icon>
  </button>
    <button mat-raised-button
            matTooltip="Geluidsfragment"
            [disabled]="disabled"
            (click)="stop()">
    <mat-icon>stop</mat-icon>
  </button>
  <br />
  {{current}} / {{duration}}
</span>


