/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./audio-player.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/common";
import * as i10 from "./audio-player.component";
var styles_AudioPlayerComponent = [i0.styles];
var RenderType_AudioPlayerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AudioPlayerComponent, data: {} });
export { RenderType_AudioPlayerComponent as RenderType_AudioPlayerComponent };
function View_AudioPlayerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["play_circle_outline"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_AudioPlayerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["pause_circle_outline"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_AudioPlayerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["mat-raised-button", ""], ["matTooltip", "Geluidsfragment"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSound() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AudioPlayerComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AudioPlayerComponent_3)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(6, 0, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.disabled; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.audio.paused; _ck(_v, 3, 0, currVal_3); var currVal_4 = !_co.audio.paused; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _co.label; _ck(_v, 6, 0, currVal_5); }); }
function View_AudioPlayerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["play_circle_outline"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_AudioPlayerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["pause_circle_outline"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_AudioPlayerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "button", [["mat-raised-button", ""], ["matTooltip", "Geluidsfragment"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSound() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AudioPlayerComponent_5)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AudioPlayerComponent_6)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["mat-raised-button", ""], ["matTooltip", "Geluidsfragment"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(12, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["stop"])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, [" ", " / ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.disabled; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.audio.paused; _ck(_v, 6, 0, currVal_4); var currVal_5 = !_co.audio.paused; _ck(_v, 8, 0, currVal_5); var currVal_8 = _co.disabled; _ck(_v, 10, 0, currVal_8); _ck(_v, 12, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 4).disabled || null); var currVal_2 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_6 = (i1.ɵnov(_v, 10).disabled || null); var currVal_7 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 12).inline; _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.current; var currVal_11 = _co.duration; _ck(_v, 15, 0, currVal_10, currVal_11); }); }
export function View_AudioPlayerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AudioPlayerComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AudioPlayerComponent_4)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.shortFragment && _co.duration); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.duration && !_co.shortFragment); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AudioPlayerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lomas-audio-player", [], null, null, null, View_AudioPlayerComponent_0, RenderType_AudioPlayerComponent)), i1.ɵdid(1, 704512, null, 0, i10.AudioPlayerComponent, [], null, null)], null, null); }
var AudioPlayerComponentNgFactory = i1.ɵccf("lomas-audio-player", i10.AudioPlayerComponent, View_AudioPlayerComponent_Host_0, { source: "source", label: "label", disabled: "disabled" }, {}, []);
export { AudioPlayerComponentNgFactory as AudioPlayerComponentNgFactory };
