import { ID } from '@datorama/akita';

export type Newsletter = {
	id: ID;
	title: string;
	content: string;
	createDate: Date;
	sendDateTime: Date;
	summary: UrlCount[];
	recipientCount: number;
	currentRecipientCount: number;
	trackerCount: number;
	uniqueTrackerCount: number;
}

export type UrlCount = {
	url: string;
	count: number;
}

export function createNewsletter({
									 id = null,
									 title = '',
									 content = '',
									 createDate = null,
									 sendDateTime = null,
									 summary = [],
									 recipientCount = 0,
									 currentRecipientCount = 0,
									 trackerCount = 0,
									 uniqueTrackerCount = 0,
								 }: Partial<Newsletter>) {
	return {
		id,
		title,
		content,
		createDate,
		sendDateTime,
		summary,
		recipientCount,
		currentRecipientCount,
		trackerCount,
		uniqueTrackerCount,
	}
}
