import { Component, OnInit } from '@angular/core';
import { CourseService } from '../../../services/course/course.service';
import { StateQuery } from '../../../store/state.store';
import { LicenseCodeQuery } from '../../../store/license-code.store';
import { Paginator } from '@datorama/akita';
import { LicenseCodeDialogComponent } from '../dialogs/license-code/license-code.component';
import { CourseQuery } from '../../../store/course.store';
import { LicenseCodeService } from '../../../services/license-code/license-code.service';
import { LicenseCode } from '../../../model/license-code.model';
import {MatDialog, PageEvent} from '@angular/material';
import {ResellerQuery} from "../../../store/reseller.store";
import {forkJoin, Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {ResellerService} from "../../../services/resellers/resellers.service";

@Component({
	templateUrl: './license-codes.component.html',
	styleUrls: ['./license-codes.component.scss']
})
export class LicenseCodesComponent implements OnInit {
	licenseCode$;
	courses = {};
  pageSize = 25;
  pageIndex = 0;
  total: number;
  total$: Observable<any>;

	constructor(private stateQuery: StateQuery,
				private licenseCodeQuery: LicenseCodeQuery,
				private licenseCodeService: LicenseCodeService,
				private courseQuery: CourseQuery,
				private courseService: CourseService,
				private resellerService: ResellerService,
				private resellerQuery: ResellerQuery,
				private dialog: MatDialog) {}


	ngOnInit() {
	  this.loadData();
		//this.licenseCodeService.list(0, 10).subscribe();
		this.courseService.list().subscribe();
		this.licenseCode$ = this.licenseCodeQuery.selectAll();
		this.resellerService.list();
		this.courseQuery.selectAll().subscribe(courses => {
			this.courses = courses.reduce((result, course) => {
				result[course.id] = course.name;
				return result;
			} , {});

		});

		this.total$ = this.licenseCodeQuery.select()
      //.pipe(state => state['count']);
	}

	loadData() {
      this.licenseCodeService.list( this.pageSize, this.pageIndex * this.pageSize)
        .subscribe((result) => this.total = result as number)
  }

	toggleSideNav = () => this.stateQuery.openSideNav();

	addLicenseCode() {
		this.dialog.open(LicenseCodeDialogComponent, {
			width: '500px',
			height: '500px'
		}).afterClosed().subscribe(res => {
			//this.licenseCodeService.list(0, 10).subscribe();
      this.loadData();
		});
		const paginator = new Paginator<LicenseCode>(this.licenseCodeQuery).withControls().withRange();
		paginator.pageChanges.pipe();
	}

	displayedColumns: string[] = ['name', 'fullName', 'reseller', 'date', 'code', 'duration'];

	pageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.loadData();
  }

  getResellerName(resellerId) {
	  const reseller = this.resellerQuery.getEntity(resellerId);
	  return reseller ? reseller.name : '- onbekend -';
  }
}
