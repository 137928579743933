import { HttpClient } from '@angular/common/http';
import { ResellerStore } from "../../store/reseller.store";
import { tap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../store/reseller.store";
var ResellerService = /** @class */ (function () {
    function ResellerService(http, resellerStore) {
        this.http = http;
        this.resellerStore = resellerStore;
    }
    ResellerService.prototype.list = function () {
        var _this = this;
        this.http.get('/api/reseller')
            .pipe(tap(function (results) { return _this.resellerStore.set(results['value']); }))
            .subscribe();
    };
    ResellerService.prototype.save = function (data) {
        var _this = this;
        if (!data.id) {
            return this.http.post('/api/reseller', data)
                .pipe(tap(function () { _this.list(); }));
        }
        else {
            return this.http.put("/api/reseller/" + data.id, data)
                .pipe(tap(function () { _this.list(); }));
        }
    };
    ResellerService.ngInjectableDef = i0.defineInjectable({ factory: function ResellerService_Factory() { return new ResellerService(i0.inject(i1.HttpClient), i0.inject(i2.ResellerStore)); }, token: ResellerService, providedIn: "root" });
    return ResellerService;
}());
export { ResellerService };
