var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ElementRef, OnInit } from '@angular/core';
import { TdMediaService } from '@covalent/core';
import { StateQuery } from '../../../store/state.store';
import { CourseEnrollmentService } from '../../../services/course-enrollment/course-enrollment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { createCourse } from '../../../model/course.model';
import { ItemService } from '../../../services/item/item.service';
import { BlogService } from '../../../services/blog/blog.service';
import { BlogQuery } from '../../../store/blog.store';
import { AuthService } from '../../../services/auth/auth.service';
import { formatDate } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
var CourseViewerComponent = /** @class */ (function () {
    function CourseViewerComponent(media, stateQuery, itemService, blogService, blogQuery, router, authService, courseEnrollmentService, route, elementRef, sanitizer, window) {
        var _this = this;
        this.media = media;
        this.stateQuery = stateQuery;
        this.itemService = itemService;
        this.blogService = blogService;
        this.blogQuery = blogQuery;
        this.router = router;
        this.authService = authService;
        this.courseEnrollmentService = courseEnrollmentService;
        this.route = route;
        this.elementRef = elementRef;
        this.sanitizer = sanitizer;
        this.window = window;
        this.name = 'Email App';
        this.ltiLaunchUrl = null;
        this.course = createCourse({});
        this.selectedChapter = null;
        this.selectedPart = null;
        this.selectedItem = null;
        this.selectedItemIndex = 0;
        this.tocOpen = false;
        this.blogOpen = false;
        this.splashOpen = true;
        this.blogs$ = this.blogQuery.selectAll();
        this.blogTemp = [];
        this.blogCount = 0;
        this.notAccessible = false;
        this.partNames = {};
        this.displayedColumns = ['date', 'fullName', 'partName'];
        this.size = 5;
        this.pageIndex = 0;
        this.externalView = false;
        this.toggleSideNav = function () { return _this.stateQuery.openSideNav(); };
        this.toggleToc = function () { return _this.tocOpen = !_this.tocOpen; };
        this.toggleBlog = function () {
            if (_this.tocOpen && _this.blogOpen) {
                _this.tocOpen = false;
            }
            else {
                _this.blogOpen = !_this.blogOpen;
                if (_this.blogOpen) {
                    _this.courseEnrollmentService.updateProgress(_this.course.id, _this.selectedPart.id, null, 'B')
                        .subscribe();
                }
            }
        };
    }
    CourseViewerComponent.prototype.click = function (event) {
        if (event.target.tagName.toLowerCase() === 'img') {
            var maxWidth = this.window.getComputedStyle(event.target).getPropertyValue('max-width');
            var naturalWidth = event.target.naturalWidth + "px";
            var newWidth = maxWidth !== naturalWidth ? naturalWidth : '100%';
            event.target.style.setProperty('max-width', newWidth);
        }
    };
    CourseViewerComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.authService.getRoles().includes('lti')) {
            this.externalView = true;
        }
        this.blogService.list().subscribe();
        this.blogs$.subscribe(function (blogs) { return _this.blogTemp = blogs; });
        this.courseEnrollmentService.get(this.route.snapshot.paramMap.get('id')).subscribe(function (res) {
            _this.course = res['value'];
            if (_this.course.ltiLaunch) {
                _this.ltiLaunchUrl = _this.sanitizer.bypassSecurityTrustResourceUrl("/lti/launch/" + _this.course.id);
                _this.courseEnrollmentService.updateLtiProgress(_this.course.id).subscribe();
            }
            _this.partNames = (_this.course.chapters || []).reduce(function (total, chapter) {
                chapter.parts.forEach(function (part) {
                    total[part.id] = chapter.name + " - " + part.name;
                });
                return total;
            }, {});
            if (_this.course.progress) {
                var lastId_1 = _this.course.progress[_this.course.progress.length - 1].p;
                var partAndIndex_1 = _this.course.chapters.reduce(function (result, chapter) {
                    var part = chapter.parts.find(function (part) {
                        return part.id === lastId_1 || (part.children || []).find(function (child) { return child === lastId_1; });
                    });
                    if (part) {
                        result.part = part;
                        result.chapter = chapter;
                        result.itemIndex = (part.children || []).findIndex(function (child) { return child === lastId_1; });
                    }
                    return result;
                }, { chapter: null, part: null, itemIndex: -1 });
                _this.selectedChapter = partAndIndex_1.chapter;
                setTimeout(function () {
                    _this.selectedItemIndex = partAndIndex_1.itemIndex;
                    _this.selectedPart = partAndIndex_1.part;
                });
                setTimeout(function () { _this.resetBlogCount(); }, 1000);
            }
            _this.itemService.list(_this.course.moduleId).subscribe(function () { });
        }, function () {
            _this.notAccessible = true;
        });
    };
    CourseViewerComponent.prototype.getBlogs = function () {
        var _this = this;
        return this.blogTemp
            .map(function (blog) {
            var partName = _this.partNames[blog.partId];
            var date = formatDate(blog.createDateTime, 'medium', 'nl');
            return __assign({ partName: partName, date: date }, blog);
        })
            .filter(function (blog) { return blog.partName; });
    };
    CourseViewerComponent.prototype.getBlogSlice = function () {
        return this.getBlogs()
            .slice(this.pageIndex * this.size, (this.pageIndex + 1) * this.size);
    };
    CourseViewerComponent.prototype.pageChange = function (event) {
        this.size = event.pageSize;
        this.pageIndex = event.pageIndex;
    };
    CourseViewerComponent.prototype.viewPartBlog = function (partId) {
        var _this = this;
        this.blogOpen = true;
        this.splashOpen = false;
        this.course.chapters.forEach(function (chapter) {
            chapter.parts.forEach(function (part) {
                if (part.id === partId) {
                    _this.selectedPart = part;
                    _this.selectedChapter = chapter;
                }
            });
        });
        this.resetBlogCount();
    };
    CourseViewerComponent.prototype.getTitle = function () {
        if (this.selectedChapter === null) {
            return this.course.name;
        }
        else if (this.selectedPart === null) {
            return this.selectedChapter.name;
        }
        else {
            return this.selectedPart.name;
        }
    };
    CourseViewerComponent.prototype.openedPanel = function (event) {
        this.selectedChapter = event;
    };
    CourseViewerComponent.prototype.selectItem = function (index) {
        this.selectedItemIndex = index;
    };
    CourseViewerComponent.prototype.showSplash = function () {
        this.splashOpen = true;
        this.tocOpen = false;
        this.blogOpen = false;
        this.resetBlogCount();
    };
    CourseViewerComponent.prototype.enrollMeeting = function () {
        this.window.open('https://fd8.formdesk.com/examenbureaulssobv/scholingsbijeenkomsten-aanmelding/');
    };
    CourseViewerComponent.prototype.continue = function () {
        this.splashOpen = false;
    };
    CourseViewerComponent.prototype.next = function () {
        var _this = this;
        if (this.blogOpen || this.tocOpen) {
            this.blogOpen = false;
            this.tocOpen = false;
        }
        else if (!this.selectedChapter) {
            this.selectedChapter = this.course.chapters[0];
            setTimeout(function () {
                _this.selectPart(_this.selectedChapter.parts[0]);
                _this.selectedItemIndex = 0;
                if (_this.selectedPart.type === 'items') {
                    _this.updateItemProgress();
                }
            });
        }
        else {
            if (this.selectedPart.type === 'items' && this.selectedPart.children.length - 1 > this.selectedItemIndex) {
                this.selectedItemIndex++;
                this.updateItemProgress();
            }
            else if (this.selectedChapter.parts.indexOf(this.selectedPart) + 1 === this.selectedChapter.parts.length) {
                this.selectedChapter = this.course.chapters[this.course.chapters.indexOf(this.selectedChapter) + 1] || this.course.chapters[0];
                setTimeout(function () {
                    _this.selectPart(_this.selectedChapter.parts[0]);
                    _this.selectedItemIndex = 0;
                    if (_this.selectedPart.type === 'items') {
                        //this.updateItemProgress();
                    }
                });
            }
            else {
                this.selectPart(this.selectedChapter.parts[this.selectedChapter.parts.indexOf(this.selectedPart) + 1]);
                this.selectedItemIndex = 0;
                if (this.selectedPart.type === 'items') {
                    //this.updateItemProgress();
                }
            }
        }
    };
    CourseViewerComponent.prototype.previous = function () {
        var _this = this;
        if (this.blogOpen || this.tocOpen) {
            this.blogOpen = false;
            this.tocOpen = false;
        }
        else if (!this.selectedChapter) {
            this.selectedChapter = this.course.chapters[this.course.chapters.length - 1];
            setTimeout(function () {
                _this.selectedPart = _this.selectedChapter.parts[_this.selectedChapter.parts.length - 1];
                _this.selectedItemIndex = 0;
                if (_this.selectedPart.type === 'items') {
                    //this.updateItemProgress();
                }
            });
        }
        else {
            if (!this.selectedPart) {
                this.selectedChapter = this.course.chapters[this.course.chapters.indexOf(this.selectedChapter) - 1];
                if (!this.selectedChapter) {
                    this.selectedChapter = this.course.chapters[this.course.chapters.length - 1];
                }
                setTimeout(function () {
                    _this.selectPart(_this.selectedChapter.parts[_this.selectedChapter.parts.length - 1]);
                    if (_this.selectedPart.type === 'items') {
                        //this.updateItemProgress();
                    }
                });
            }
            else if (this.selectedPart.type === 'items' && this.selectedItemIndex > 0) {
                this.selectedItemIndex--;
                this.updateItemProgress();
            }
            else if (this.selectedChapter.parts.indexOf(this.selectedPart) === 0) {
                setTimeout(function () {
                    _this.selectedChapter = _this.course.chapters[_this.course.chapters.indexOf(_this.selectedChapter) - 1] || _this.course.chapters[_this.course.chapters.length - 1];
                    _this.selectPart(_this.selectedChapter.parts[_this.selectedChapter.parts.length - 1]);
                    _this.selectedItemIndex = 0;
                    if (_this.selectedPart.type === 'items') {
                        //this.updateItemProgress();
                    }
                });
            }
            else {
                this.selectPart(this.selectedChapter.parts[this.selectedChapter.parts.indexOf(this.selectedPart) - 1]);
                this.selectedItemIndex = 0;
                if (this.selectedPart.type === 'items') {
                    //this.updateItemProgress();
                }
            }
        }
    };
    CourseViewerComponent.prototype.selectPart = function (part) {
        this.tocOpen = false;
        this.blogOpen = false;
        this.splashOpen = false;
        this.selectedPart = part;
        this.selectedItemIndex = 0;
        if (this.selectedPart.type === 'items') {
            this.updateItemProgress();
        }
        else {
            this.courseEnrollmentService.updateProgress(this.course.id, part.id).subscribe();
        }
        this.elementRef.nativeElement.querySelector('#content').scrollTo(0, 0);
        this.resetBlogCount();
    };
    CourseViewerComponent.prototype.updateItemProgress = function (action) {
        this.courseEnrollmentService.updateProgress(this.course.id, this.selectedPart.children[this.selectedItemIndex], null, action).subscribe();
    };
    CourseViewerComponent.prototype.resetBlogCount = function () {
        var _this = this;
        var lastViewTime = this.course.progress ?
            (this.course.progress || []).map(function (x) { return x; }).reverse().find(function (row) {
                return row.p === (_this.selectedPart || {}).id && row.a === 'B';
            }) || { t: 1 } : { t: 0 };
        setTimeout(function () {
            _this.blogCount = _this.blogTemp.filter(function (blog) {
                return blog.courseId === _this.course.id && blog.partId === (_this.selectedPart || {}).id
                    && new Date(blog.createDateTime) > new Date(lastViewTime.t)
                    && blog.accountId !== _this.authService.getPayload().id;
            }).length;
        });
    };
    return CourseViewerComponent;
}());
export { CourseViewerComponent };
