<mat-sidenav-container>
    <mat-sidenav></mat-sidenav>
    <mat-sidenav-content>

        <lomas-public-toolbar></lomas-public-toolbar>

        <section>
            <mat-card>
                <mat-card-title>Bevestiging account</mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <div class="result">
                        <p *ngIf="status === 'new'">
                            Het account wordt geactiveerd ...
                        </p>
                        <p *ngIf="status === 'loggedIn'">
                            Het account is geactiveerd. Ga verder naar de <a href="#" (click)="navigate($event)">Homepage</a>
                        </p>
                        <p *ngIf="status === 'success'">
                            Het account is geactiveerd. Ga verder naar de <a [routerLink]="['/login']">login</a>
                        </p>
                        <p *ngIf="status === 'fail'">
                            Het is niet gelukt om het account te activeren of het account is al geactiveerd.
                        </p>
                    </div>
                </mat-card-content>
            </mat-card>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>

