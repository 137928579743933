<mat-sidenav-content class="page">
    <lomas-admin-toolbar title="Forum"></lomas-admin-toolbar>
    <mat-card class="newsletter">
        <mat-card-header>
            <mat-card-title>Nieuwsbrieven</mat-card-title>
            <span class="flex-title"></span>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content *ngIf="newsletters$ | async as newsletters">
            <mat-table [dataSource]="newsletters" class="mat-elevation-z8" *ngIf="newsletters.length > 0">

                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef> Titel </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.title }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="createDate">
                    <mat-header-cell *matHeaderCellDef> Creatiedatum </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.createDate| date: 'yyyy-MM-dd'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="sendDateTime">
                    <mat-header-cell *matHeaderCellDef> Verstuurdatum </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.sendDateTime| date: 'yyyy-MM-dd'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-raised-button [routerLink]="['/admin/newsletters', element.id]" >Bekijk nieuwsbrief</button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <p></p>
            <mat-paginator *ngIf="newsletters.length > 0"
                           [length]="total"
                           [pageSize]="size"
                           [pageSizeOptions]="[5, 10, 25, 100]"
                           (page)="pageChange($event)">
            </mat-paginator>
            <div class="no-results" *ngIf="newsletters.length === 0">
                Er zijn geen nieuwsbrieven gevonden
            </div>
        </mat-card-content>

    </mat-card>

</mat-sidenav-content>
<a mat-fab color="accent" class="mat-fab-bottom-right fixed" [routerLink]="['/admin/newsletters/new']">
    <mat-icon>add</mat-icon>
</a>