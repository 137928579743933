export function createBlog(_a) {
    var _b = _a.id, id = _b === void 0 ? null : _b, _c = _a.courseId, courseId = _c === void 0 ? '' : _c, _d = _a.accountId, accountId = _d === void 0 ? '' : _d, _e = _a.partId, partId = _e === void 0 ? '' : _e, _f = _a.createDateTime, createDateTime = _f === void 0 ? 0 : _f, _g = _a.message, message = _g === void 0 ? '' : _g, _h = _a.replyId, replyId = _h === void 0 ? null : _h, _j = _a.fullName, fullName = _j === void 0 ? '' : _j, _k = _a.seenDateTime, seenDateTime = _k === void 0 ? 0 : _k, _l = _a.seenById, seenById = _l === void 0 ? null : _l, _m = _a.inactive, inactive = _m === void 0 ? false : _m, _o = _a.adminAnswer, adminAnswer = _o === void 0 ? false : _o;
    return {
        id: id,
        courseId: courseId,
        accountId: accountId,
        partId: partId,
        createDateTime: createDateTime,
        message: message,
        replyId: replyId,
        fullName: fullName,
        seenDateTime: seenDateTime,
        seenById: seenById,
        inactive: inactive,
        adminAnswer: adminAnswer,
    };
}
