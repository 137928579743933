import {Component} from "@angular/core";
import {ActionService} from "../../../services/action/action.service";
import {ActionQuery} from "../../../store/action.store";
import {FormControl} from "@angular/forms";
import {PageEvent} from "@angular/material";
import {ID} from "@datorama/akita";
import {EventTypeQuery} from "../../../store/event-type.store";
import {EventTypeService} from "../../../services/event-type/event-type.service";
import {AccountQuery} from "../../../store/account.store";

@Component({
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent {
  displayedColumns: string[] = ['date', 'eventType', 'account', 'admin', 'note'];
  size$ = this.actionQuery.selectSize();
  pageIndex$ = this.actionQuery.selectPageIndex();
  total$ = this.actionQuery.selectTotal();
  events$ = this.actionQuery.selectPage();
  searchText = new FormControl('');

  constructor(private actionService: ActionService,
              private actionQuery: ActionQuery,
              private eventTypeQuery: EventTypeQuery,
              private eventTypeService: EventTypeService,
              private accountQuery: AccountQuery,) {
  }

  ngOnInit() {
    this.loadData(25, 0);
  }

  loadData(size, pageIndex) {
    this.actionService.overview( size, pageIndex * size)
      .subscribe();
  }

  pageChange(event: PageEvent) {
    this.loadData(event.pageSize, event.pageIndex);
  }

  getEventTypeName(id: string) {
    const eventType = this.eventTypeQuery.getEntity(id);
    return eventType ? eventType.name : '';
  }

  getAccountName(id: ID): string {
    const account = this.accountQuery.getEntity(id);
    return account ? `${account.firstName} ${account.preposition} ${account.surName}` : '';
  }

  isDue(date) {
    return (new Date(date) <= new Date());
  }
}
