import { Observable } from 'rxjs/Rx';
import { EnrollmentOverviewService } from '../../../services/enrollment-overview/enrollment-overview.service';
import { EnrollmentOverviewQuery } from '../../../store/enrollment-overview.store';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/enrollment-overview/enrollment-overview.service";
import * as i2 from "../../../store/enrollment-overview.store";
var CourseEnrollmentsResolver = /** @class */ (function () {
    function CourseEnrollmentsResolver(enrollmentOverviewService, enrollmentOverviewQuery) {
        this.enrollmentOverviewService = enrollmentOverviewService;
        this.enrollmentOverviewQuery = enrollmentOverviewQuery;
    }
    CourseEnrollmentsResolver.prototype.resolve = function () {
        var _this = this;
        return Observable.create(function (observer) {
            var size = _this.enrollmentOverviewQuery.getSize();
            var pageIndex = _this.enrollmentOverviewQuery.getPageIndex();
            _this.enrollmentOverviewService.list(size, pageIndex)
                .subscribe(function () {
                observer.next(true);
                observer.complete();
            }, function () {
                observer.next(false);
                observer.complete();
            });
        });
    };
    CourseEnrollmentsResolver.ngInjectableDef = i0.defineInjectable({ factory: function CourseEnrollmentsResolver_Factory() { return new CourseEnrollmentsResolver(i0.inject(i1.EnrollmentOverviewService), i0.inject(i2.EnrollmentOverviewQuery)); }, token: CourseEnrollmentsResolver, providedIn: "root" });
    return CourseEnrollmentsResolver;
}());
export { CourseEnrollmentsResolver };
