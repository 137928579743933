///<reference path="../../node_modules/@covalent/core/data-table/data-table.module.d.ts"/>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule, pageComponents } from './app.routes';
import { JwtModule } from '@auth0/angular-jwt';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/auth/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { NgReduxModule } from '@angular-redux/store';
import { CounterActions } from './store/actions/counter.actions';

import { EditorModule } from '@tinymce/tinymce-angular';

import { CovalentLayoutModule } from '@covalent/core/layout';
import {
	MatAutocompleteModule, MatBadgeModule,
	MatButtonModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule,
	MatDividerModule,
	MatExpansionModule, MatGridListModule,
	MatIconModule, MatIconRegistry, MatInputModule,
	MatListModule,
	MatMenuModule, MatNativeDateModule, MatPaginator, MatPaginatorModule, MatProgressBar, MatProgressSpinnerModule,
	MatRadioModule,
	MatSelectModule,
	MatSidenavModule, MatSlideToggleModule, MatSnackBarModule, MatTableModule, MatTabsModule, MatToolbarModule,
	MatTooltipModule
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CovalentCommonModule,
  CovalentDataTableModule, CovalentDialogsModule, CovalentExpansionPanelModule, CovalentLoadingModule,
  CovalentMediaModule, CovalentMenuModule,
  CovalentMessageModule,
  CovalentNotificationsModule, CovalentPagingModule, CovalentSearchModule, CovalentStepsModule
} from '@covalent/core';
import { PortalModule } from '@angular/cdk/portal';
import { IconComponent } from './components/icon/icon.component';
import { UserAccountStore } from './store/user-account.store';
import { LicenseCodeService } from './services/license-code/license-code.service';
import { LicenseService } from './services/license/license.service';
import { StateStore } from './store/state.store';
import { LicenseCodeStore } from './store/license-code.store';
import { LicenseCodeDialogComponent } from './pages/admin/dialogs/license-code/license-code.component';
import { CourseStore } from './store/course.store';
import { CourseService } from './services/course/course.service';
import { CourseEnrollmentStore } from './store/course-enrollment.store';
import { CourseEnrollmentService } from './services/course-enrollment/course-enrollment.service';
import { ViewContentComponent } from './pages/learning/course-viewer/view-content/view-content.component';
import { ViewVideoComponent } from './pages/learning/course-viewer/view-video/view-video.component';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { ViewItemsComponent } from './pages/learning/course-viewer/view-items/view-items.component';
import { ItemStore } from './store/item.store';
import { ItemService } from './services/item/item.service';
import { PublicCourseStore } from './store/pubic-course.store';
import { PublicCourseService } from './services/public-course/public-course.service';
import { PublicToolbarComponent } from './components/toolbars/public-toolbar/public-toolbar.component';
import { StudentToolbarComponent } from './components/toolbars/student-toolbar/student-toolbar.component';
import { AdminToolbarComponent } from './components/toolbars/admin-toolbar/admin-toolbar.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { ViewBlogComponent } from './pages/learning/course-viewer/view-blog/view-blog.component';
import { AddBlogpostComponent } from './pages/learning/dialogs/add-blogpost/add-blogpost.component';
import { BlogService } from './services/blog/blog.service';
import { MarkdownModule } from 'ngx-markdown';
import { BlogStore } from './store/blog.store';
import { BlogPostComponent } from './pages/learning/course-viewer/view-blog/blog-post/blog-post.component';
import { AnswerPostComponent } from './pages/admin/dialogs/answer-post/answer-post.component';
import { EditAnswerComponent } from './pages/admin/dialogs/edit-answer/edit-answer.component';
import { NewsletterStore } from './store/newsletter.store';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { WINDOW_PROVIDERS } from './window.provider';
import { ConfirmSendNewsletterComponent } from './pages/admin/dialogs/confirm-send-newsletter/confirm-send-newsletter.component';
import { AccountStore } from './store/account.store';
import { AddSubscriberComponent } from './pages/admin/dialogs/add-subscriber/add-subscriber.component';
import { EventTypeStore } from './store/event-type.store';
import { EditEventTypeComponent } from './pages/admin/dialogs/edit-event-type/edit-event-type.component';
import { EventStore } from './store/event.store';
import { EditEventComponent } from './pages/admin/dialogs/edit-event/edit-event.component';
import { EnrollmentOverviewStore } from './store/enrollment-overview.store';
import { ConfirmUnsubscribeAccountComponent } from './pages/admin/dialogs/confirm-unsubscribe-account/confirm-unsubscribe-account.component';
import {ActionStore} from "./store/action.store";
import {EditActionComponent} from "./pages/admin/dialogs/edit-action/edit-action.component";
import {CompleteActionComponent} from "./pages/admin/dialogs/complete-action/complete-action.component";
import { CloudinaryWidthPipe} from "./pipes/cloudinaryWidth.pipe";

import localeNL from '@angular/common/locales/nl';
import {registerLocaleData} from "@angular/common";
import {ResellersComponent} from "./pages/admin/resellers/resellers.component";
import {EditResellerComponent} from "./pages/admin/dialogs/edit-reseller/edit-reseller.component";
import {CoursesComponent} from "./pages/admin/courses/courses.component";
import {CourseComponent} from 'src/app/pages/admin/course/course.component';

registerLocaleData(localeNL, 'nl');

export function tokenGetter() {
  return sessionStorage.getItem('token');
}

@NgModule({
  declarations: [
    AddBlogpostComponent,
    AddSubscriberComponent,
    AdminToolbarComponent,
    AnswerPostComponent,
    AudioPlayerComponent,
    BlogPostComponent,
    CompleteActionComponent,
    ConfirmSendNewsletterComponent,
    ConfirmUnsubscribeAccountComponent,
    CourseComponent,
    CoursesComponent,
    EditActionComponent,
    EditAnswerComponent,
    EditEventComponent,
    EditEventTypeComponent,
    EditResellerComponent,
    IconComponent,
    pageComponents,
    AppComponent,
    LicenseCodeDialogComponent,
    SafeHtmlPipe,
    PublicToolbarComponent,
    ResellersComponent,
    StudentToolbarComponent,
    ViewBlogComponent,
    ViewContentComponent,
    ViewItemsComponent,
    ViewVideoComponent,
    CloudinaryWidthPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    EditorModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgReduxModule,
    AppRoutingModule,
    JwtModule.forRoot({
      // this token getter isn't used at all. See custom implementation in auth.service.ts
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:4200'],
        blacklistedRoutes: []
      }
    }),
    MarkdownModule.forRoot(),
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,

    // covalent modules
    CovalentDataTableModule, CovalentMediaModule, CovalentLoadingModule,
    CovalentNotificationsModule, CovalentLayoutModule, CovalentMenuModule,
    CovalentPagingModule, CovalentSearchModule, CovalentStepsModule,
    CovalentCommonModule, CovalentDialogsModule, CovalentMessageModule, CovalentExpansionPanelModule,
/*    MatButtonModule, MatCardModule, MatIconModule,
    MatListModule, MatMenuModule, MatTooltipModule,
    MatSlideToggleModule, MatInputModule, MatCheckboxModule,
    MatToolbarModule, MatSnackBarModule, MatSidenavModule,
    MatTabsModule, MatSelectModule, MatChipsModule, MatTableModule,
    MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule, MatExpansionModule, MatDialogModule, MatRadioModule,

    PortalModule,

    CovalentDataTableModule, CovalentMediaModule, CovalentLoadingModule,
    CovalentNotificationsModule, CovalentLayoutModule, CovalentMenuModule,
    CovalentPagingModule, CovalentSearchModule, CovalentStepsModule,
    CovalentCommonModule, CovalentDialogsModule, CovalentMessageModule, CovalentExpansionPanelModule,*/

  ],
  providers: [
    WINDOW_PROVIDERS,
    AccountStore,
    ActionStore,
    AuthService,
    BlogService,
    BlogStore,
    CounterActions,
    CourseEnrollmentService,
    CourseEnrollmentStore,
    CourseService,
    CourseStore,
    EnrollmentOverviewStore,
    EventStore,
    EventTypeStore,
    ItemService,
    ItemStore,
    LicenseCodeService,
    LicenseCodeStore,
    LicenseService,
    MatIconRegistry,
    NewsletterStore,
    PublicCourseService,
    PublicCourseStore,
    UserAccountStore,
    StateStore,
	  {
		  provide: HTTP_INTERCEPTORS,
		  useClass: AuthInterceptor,
		  multi: true
	  }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddBlogpostComponent,
	  AddSubscriberComponent,
	  AnswerPostComponent,
    ConfirmSendNewsletterComponent,
	  ConfirmUnsubscribeAccountComponent,
    CompleteActionComponent,
    EditActionComponent,
	  EditAnswerComponent,
	  EditEventComponent,
	  EditEventTypeComponent,
    EditResellerComponent,
      LicenseCodeDialogComponent
  ]
})
export class AppModule {
 constructor(private matIconRegistry: MatIconRegistry) {
   this.matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
 }
}
