<mat-sidenav-container>
  <mat-sidenav></mat-sidenav>
  <mat-sidenav-content>

    <lomas-public-toolbar></lomas-public-toolbar>

    <section>
      <mat-card>
        <mat-card-title>Inloggen</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div *ngIf="message" class="message">{{message}}</div>
          <form [formGroup]="loginForm" (submit)="submit()">
            <mat-form-field class="full-width">
              <input formControlName="email" (change)="hideMessage()"  matInput placeholder="e-mail" id="email" />
            </mat-form-field>
            <mat-form-field class="full-width">
              <input formControlName="password" type="password" (change)="hideMessage()" matInput placeholder="password" id="password"/>
            </mat-form-field>
            <p>
              <button mat-raised-button color="primary" type="submit" [disabled]="loginForm.status !== 'VALID' || loading === true">Versturen</button>
            </p>

          </form>
          <p class="other-actions">
            <a [routerLink]="['/login/forgot']" [queryParamsHandling]="'merge'">Password vergeten</a> | <a [routerLink]="['/sign-up']" [queryParamsHandling]="'merge'">Maak account aan</a>
          </p>
        </mat-card-content>
      </mat-card>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>


