import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class SubscribeService {
	constructor(private http: HttpClient) {}

	post(subscriber: {email: string, firstName: string}) {
		return this.http.post('/api/subscribe', subscriber)
	}
}