var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Query, Store, StoreConfig } from '@datorama/akita';
import { createState } from '../model/state.model';
import * as i0 from "@angular/core";
var initialState = createState({});
var StateStore = /** @class */ (function (_super) {
    __extends(StateStore, _super);
    function StateStore() {
        return _super.call(this, initialState) || this;
    }
    StateStore = __decorate([
        StoreConfig({ name: 'state' }),
        __metadata("design:paramtypes", [])
    ], StateStore);
    return StateStore;
}(Store));
export { StateStore };
var StateQuery = /** @class */ (function (_super) {
    __extends(StateQuery, _super);
    function StateQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.state$ = _this.select(function (state) { return state; });
        _this.openSideNav = function () { return _this.store.update({ sideNavOpened: true }); };
        _this.closeSideNav = function () { return _this.store.update({ sideNavOpened: false }); };
        return _this;
    }
    StateQuery.ngInjectableDef = i0.defineInjectable({ factory: function StateQuery_Factory() { return new StateQuery(i0.inject(StateStore)); }, token: StateQuery, providedIn: "root" });
    return StateQuery;
}(Query));
export { StateQuery };
