import { ID } from '@datorama/akita';

export type EventType = {
	id: ID;
	name: string;
}

export function createEventType({
									id = null,
									name = null,

								}: Partial<EventType>) {
	return {
		id,
		name,
	}
}