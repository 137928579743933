import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UnsubscribeService = /** @class */ (function () {
    function UnsubscribeService(http) {
        var _this = this;
        this.http = http;
        this.unsubscribe = function (id) {
            return _this.http.put("/api/unsubscribe/" + id, {});
        };
    }
    UnsubscribeService.ngInjectableDef = i0.defineInjectable({ factory: function UnsubscribeService_Factory() { return new UnsubscribeService(i0.inject(i1.HttpClient)); }, token: UnsubscribeService, providedIn: "root" });
    return UnsubscribeService;
}());
export { UnsubscribeService };
