import { Component, OnDestroy, OnInit } from '@angular/core';
import { BlogAdminService } from '../../../services/blog-admin/blog-admin.service';
import { BlogQuery } from '../../../store/blog.store';
import { CourseQuery } from '../../../store/course.store';
import { CourseService } from '../../../services/course/course.service';
import { Course } from '../../../model/course.model';
import { MatDialog, PageEvent } from '@angular/material';
import { AnswerPostComponent } from '../dialogs/answer-post/answer-post.component';
import { Blog } from '../../../model/blog.model';
import { FormControl } from '@angular/forms';

@Component({
	templateUrl: './forum.component.html',
	styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit, OnDestroy {

	blogs$ = this.blogQuery.selectAll();
	courses = {};
	parts = {};
	displayedColumns: string[] = ['icon','courseName', 'partName', 'dateTime', 'fullName', 'actions'];
	total: number;
	size = 25;
	pageIndex = 0;
	showNew = new FormControl(true);
	subscriptions = [];

	constructor(private blogAdminService: BlogAdminService,
				private blogQuery: BlogQuery,
				private courseService: CourseService,
				private courseQuery: CourseQuery,
				private dialog: MatDialog) {}

	ngOnInit() {
		this.loadData();
		this.courseService.list().subscribe();
		this.courseQuery.selectAll().subscribe(courses => {
			this.courses = courses.reduce((result, course) => {
				result[course.id] = course.name;
				return result;
			} , {});
			this.parts = courses.reduce((result, course) => {
				course.chapters
					.forEach(chapter => chapter.parts
						.forEach(part => result[part.id]= part.name));
				return result;
			}, {});
		});
		this.subscriptions.push(this.showNew.valueChanges.subscribe(() => {
			this.loadData();
		}))
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	loadData() {
		this.blogAdminService.list(this.pageIndex * this.size, this.size, this.showNew.value).subscribe(result => this.total = result);
	}

	pageChange(event: PageEvent) {
		this.size = event.pageSize;
		this.pageIndex = event.pageIndex;
		this.loadData();
	}

	showPost(post: Blog) {
		this.dialog.open(AnswerPostComponent, {
			width: '800px',
			height: '700px',
			data: {
				post,
				courseName: this.courses[post.courseId],
				partName: this.parts[post.partId]

			}
		}).afterClosed().subscribe(res => {
			if (res) {
				this.blogAdminService.answer(res).subscribe(() => {
					this.loadData();
				});
			}
		});
	}
}
