/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blog-post.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "./blog-post.component";
import * as i10 from "../../../../../services/auth/auth.service";
import * as i11 from "@angular/common";
import * as i12 from "../../../../../../../node_modules/ngx-markdown/ngx-markdown.ngfactory";
import * as i13 from "ngx-markdown";
var styles_BlogPostComponent = [i0.styles];
var RenderType_BlogPostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlogPostComponent, data: {} });
export { RenderType_BlogPostComponent as RenderType_BlogPostComponent };
function View_BlogPostComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleEditPost(_co.blog) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["edit"])), (_l()(), i1.ɵted(-1, 0, ["Wijzigen "]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_2); }); }
function View_BlogPostComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lomas-blog-post", [], null, [[null, "addPost"], [null, "editPost"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addPost" === en)) {
        var pd_0 = (_co.handleAddPost($event) !== false);
        ad = (pd_0 && ad);
    } if (("editPost" === en)) {
        var pd_1 = (_co.handleEditPost($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_BlogPostComponent_0, RenderType_BlogPostComponent)), i1.ɵdid(1, 49152, null, 0, i9.BlogPostComponent, [i10.AuthService], { depth: [0, "depth"], children: [1, "children"], blog: [2, "blog"], courseId: [3, "courseId"], partId: [4, "partId"], parentId: [5, "parentId"], threadId: [6, "threadId"] }, { addPost: "addPost", editPost: "editPost" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.depth + 1); var currVal_1 = _co.children; var currVal_2 = _v.context.$implicit; var currVal_3 = _co.courseId; var currVal_4 = _co.partId; var currVal_5 = _co.blog.id; var currVal_6 = _co.threadId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_BlogPostComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i11.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "padding-left": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " - ", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "markdown", [], null, null, null, i12.View_MarkdownComponent_0, i12.RenderType_MarkdownComponent)), i1.ɵdid(7, 4243456, null, 0, i13.MarkdownComponent, [i1.ElementRef, i13.MarkdownService], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleAddPost(_co.blog) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(11, 638976, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["reply"])), (_l()(), i1.ɵted(-1, 0, ["Reageren "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogPostComponent_2)), i1.ɵdid(15, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogPostComponent_3)), i1.ɵdid(17, 802816, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.getDepth()); _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.blog.message; _ck(_v, 7, 0, currVal_3); _ck(_v, 11, 0); var currVal_7 = _co.editAllowed(); _ck(_v, 15, 0, currVal_7); var currVal_8 = _co.children; _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.blog.fullName; var currVal_2 = i1.ɵunv(_v, 4, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _co.blog.createDateTime, "dd MMMM yyyy - HH:mm")); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = (i1.ɵnov(_v, 9).disabled || null); var currVal_5 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵnov(_v, 11).inline; _ck(_v, 10, 0, currVal_6); }); }
export function View_BlogPostComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i11.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogPostComponent_1)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showPost(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BlogPostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lomas-blog-post", [], null, null, null, View_BlogPostComponent_0, RenderType_BlogPostComponent)), i1.ɵdid(1, 49152, null, 0, i9.BlogPostComponent, [i10.AuthService], null, null)], null, null); }
var BlogPostComponentNgFactory = i1.ɵccf("lomas-blog-post", i9.BlogPostComponent, View_BlogPostComponent_Host_0, { depth: "depth", children: "children", blog: "blog", courseId: "courseId", partId: "partId", parentId: "parentId", threadId: "threadId" }, { addPost: "addPost", editPost: "editPost" }, []);
export { BlogPostComponentNgFactory as BlogPostComponentNgFactory };
