var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import * as i0 from "@angular/core";
var LicenseCodeStore = /** @class */ (function (_super) {
    __extends(LicenseCodeStore, _super);
    function LicenseCodeStore() {
        return _super.call(this, { count: 7 }) || this;
    }
    LicenseCodeStore.prototype.updateCount = function (count) {
        console.log(count);
        this.update(function (state) { return (__assign({}, state, { count: count })); });
    };
    LicenseCodeStore = __decorate([
        StoreConfig({ name: 'license-code' }),
        __metadata("design:paramtypes", [])
    ], LicenseCodeStore);
    return LicenseCodeStore;
}(EntityStore));
export { LicenseCodeStore };
var LicenseCodeQuery = /** @class */ (function (_super) {
    __extends(LicenseCodeQuery, _super);
    function LicenseCodeQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        return _this;
    }
    LicenseCodeQuery.ngInjectableDef = i0.defineInjectable({ factory: function LicenseCodeQuery_Factory() { return new LicenseCodeQuery(i0.inject(LicenseCodeStore)); }, token: LicenseCodeQuery, providedIn: "root" });
    return LicenseCodeQuery;
}(QueryEntity));
export { LicenseCodeQuery };
