import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'cloudinaryWidth'})
export class CloudinaryWidthPipe implements PipeTransform {
  constructor() {
  }

  transform(value: string, width: number) {
    const matches = /^(.*\/upload\/)(.*)$/.exec(value);

    if (matches) {
      return `${matches[1]}w_${width}/${matches[2]}`;
    } else {
      return value;
    }
  }
}
