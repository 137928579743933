import { map, switchMap, tap } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { EventStore } from '../../store/event.store';
import { AccountService } from '../account/account.service';
import { EventTypeService } from '../event-type/event-type.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../store/event.store";
import * as i3 from "../account/account.service";
import * as i4 from "../event-type/event-type.service";
var EventService = /** @class */ (function () {
    function EventService(http, eventStore, accountService, eventTypeService) {
        this.http = http;
        this.eventStore = eventStore;
        this.accountService = accountService;
        this.eventTypeService = eventTypeService;
    }
    EventService.prototype.list = function (top, skip, accountId, date) {
        var _this = this;
        if (accountId === void 0) { accountId = ''; }
        if (date === void 0) { date = ''; }
        var parent = accountId != '' ? "/account/" + accountId : '';
        var dateQuery = date !== '' ? "&date=" + date : '';
        return this.http.get("/api" + parent + "/event/?$top=" + top + "&$skip=" + skip + dateQuery)
            .pipe(tap(function (results) {
            var data = results['value'];
            _this.eventStore.set(data);
        }), switchMap(function (results) {
            var adminAccountIds = results['value']
                .map(function (event) { return event.adminAccountId; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            return _this.accountService.list(adminAccountIds)
                .pipe(map(function (inner) { return results; }));
        }), map(function (results) { return results['count']; }));
    };
    EventService.prototype.overview = function (top, skip) {
        var _this = this;
        return this.http.get("/api/event/?$top=" + top + "&$skip=" + skip)
            .pipe(tap(function (results) {
            _this.eventStore.upsertPage(results['value'], top, skip, results['count']);
        }), switchMap(function (results) {
            var eventTypeIds = results['value']
                .map(function (event) { return event.eventTypeId; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            return _this.eventTypeService.listByIds(eventTypeIds)
                .pipe(map(function (inner) { return results; }));
        }), switchMap(function (results) {
            var accountIds = results['value']
                .map(function (event) { return event.accountId; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            var adminAccountId = results['value']
                .map(function (event) { return event.adminAccountId; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            return _this.accountService.list(accountIds.concat(adminAccountId))
                .pipe(map(function (inner) { return results; }));
        }));
    };
    EventService.prototype.save = function (event) {
        if (event.id === 'new') {
            return this.http.post('/api/event', event);
        }
        else {
            return this.http.put("/api/event/" + event.id, event);
        }
    };
    EventService.ngInjectableDef = i0.defineInjectable({ factory: function EventService_Factory() { return new EventService(i0.inject(i1.HttpClient), i0.inject(i2.EventStore), i0.inject(i3.AccountService), i0.inject(i4.EventTypeService)); }, token: EventService, providedIn: "root" });
    return EventService;
}());
export { EventService };
