var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { UserAccountStore } from '../../store/user-account.store';
import { createAccount } from '../../model/user-account.model';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../store/user-account.store";
import * as i2 from "@angular/common/http";
var UserAccountService = /** @class */ (function () {
    function UserAccountService(accountStore, http) {
        this.accountStore = accountStore;
        this.http = http;
        accountStore.setState(function () { return createAccount({ id: 'a', firstName: 'JU', preposition: '', surName: '', email: '' }); });
        setTimeout(function () {
            accountStore.setState(function () { return createAccount({ id: 'B', firstName: 'JU', preposition: '', surName: '', email: '' }); });
        }, 3000);
    }
    UserAccountService.prototype.createAccount = function (account, courseId) {
        return this.http.post('/api/useraccount', __assign({}, account, { courseId: courseId }));
    };
    UserAccountService.prototype.confirmAccount = function (code) {
        return this.http.post('/api/useraccount', { code: code });
    };
    UserAccountService.ngInjectableDef = i0.defineInjectable({ factory: function UserAccountService_Factory() { return new UserAccountService(i0.inject(i1.UserAccountStore), i0.inject(i2.HttpClient)); }, token: UserAccountService, providedIn: "root" });
    return UserAccountService;
}());
export { UserAccountService };
