import { Component, OnInit } from '@angular/core';
import { PublicCourseQuery, PublicCourseStore } from '../../store/pubic-course.store';
import { PublicCourseService } from '../../services/public-course/public-course.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lomas-home-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  course$ = this.publicCourseQuery.selectAll();

  constructor(private publicCourseService: PublicCourseService,
              private publicCourseQuery: PublicCourseQuery,
              private router: Router) {}

  ngOnInit(){
    this.publicCourseService.list().subscribe();
  }

  startCourse(course) {
    this.router.navigate(['/sign-up'], {queryParams:  {courseId: course.id}})
  }
}
