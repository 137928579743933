import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SubscribeService = /** @class */ (function () {
    function SubscribeService(http) {
        this.http = http;
    }
    SubscribeService.prototype.post = function (subscriber) {
        return this.http.post('/api/subscribe', subscriber);
    };
    SubscribeService.ngInjectableDef = i0.defineInjectable({ factory: function SubscribeService_Factory() { return new SubscribeService(i0.inject(i1.HttpClient)); }, token: SubscribeService, providedIn: "root" });
    return SubscribeService;
}());
export { SubscribeService };
