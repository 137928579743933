import { Component, OnInit } from '@angular/core';
import { BlogAdminService } from '../../../services/blog-admin/blog-admin.service';
import { ActivatedRoute } from '@angular/router';
import { BlogQuery } from '../../../store/blog.store';
import { map } from 'rxjs/internal/operators';
import { Blog } from '../../../model/blog.model';
import { AnswerPostComponent } from '../dialogs/answer-post/answer-post.component';
import { MatDialog } from '@angular/material';
import { CourseQuery } from '../../../store/course.store';
import { EditAnswerComponent } from '../dialogs/edit-answer/edit-answer.component';

@Component({
	templateUrl: './thread.component.html',
	styleUrls: ['./thread.component.scss']
})
export class ThreadComponent implements OnInit {
	blogs$ = this.blogQuery.selectAll();
	id: string;
	courses = {};
	parts = {};

	constructor(private blogAdminService: BlogAdminService,
				private activatedRoute: ActivatedRoute,
				private blogQuery: BlogQuery,
				private courseQuery: CourseQuery,
				private dialog: MatDialog) {}


	ngOnInit() {
		this.id = this.activatedRoute.snapshot.paramMap.get('threadId');
		this.loadData();
		this.courseQuery.selectAll().subscribe(courses => {
			this.courses = courses.reduce((result, course) => {
				result[course.id] = course.name;
				return result;
			} , {});
			this.parts = courses.reduce((result, course) => {
				course.chapters
					.forEach(chapter => chapter.parts
						.forEach(part => result[part.id]= part.name));
				return result;
			}, {});
		});
	}

	getThreadBlog() {
		return this.blogs$
			.pipe(map(blogs => blogs.find(blog => blog.id === this.id)));
	}

	loadData() {
		this.blogAdminService.thread(this.id).subscribe();
	}

	showPost(post: Blog) {
		this.dialog.open(AnswerPostComponent, {
			width: '800px',
			height: '650px',
			data: {
				post,
				courseName: this.courses[post.courseId],
				partName: this.parts[post.partId]

			}
		}).afterClosed().subscribe(res => {
			if (res) {
				this.blogAdminService.answer(res).subscribe(() => {
					this.loadData();
				});
			}
		});
	}

	editPost(post: Blog) {
		this.dialog.open(EditAnswerComponent, {
			width: '800px',
			height: '700px',
			data: {
				post

			}
		}).afterClosed().subscribe(res => {
			if (res) {
				this.blogAdminService.editAnswer(post.id, res).subscribe(() => {
					this.loadData();
				});
			}
		});
	}

}
