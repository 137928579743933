import { Injectable } from '@angular/core';
import { EntityState, EntityStore, ID, Query, QueryEntity, Store, StoreConfig } from '@datorama/akita';
import { Event } from '../model/event.model';
import { CourseEnrollment } from '../model/course-enrollment.model';
import { Observable } from 'rxjs/Rx';


export interface EventState extends EntityState<Event> {
	size: number;
	pageIndex: number;
	total: number;
	pageIds: ID[];
}

@StoreConfig({ name: 'event' })
export class EventStore extends EntityStore<EventState, Event> {

	upsertPage(events:Event[], size: number, pageIndex: number, total: number) {
		const eventIds = events
			.map(event => {
				this.upsert(event.id, event);
				return event.id;
			});

		this.updateRoot({
			size: size,
			pageIndex: pageIndex,
			total: total,
			pageIds: eventIds
		})
	}

	constructor() {
		super({size: 25, pageIndex: 0, total: 0, pageIds: []});
	}
}

@Injectable({providedIn: 'root'})
export class EventQuery extends QueryEntity<EventState, Event> {

	getPageIndex(): number {
		return this.getSnapshot().pageIndex;
	}

	getSize(): number {
		return this.getSnapshot().size;
	}

	getTotal(): number {
		return this.getSnapshot().total;
	}

	selectPageIndex(): Observable<number> {
		return this.select(state => state.pageIndex);
	}

	selectSize(): Observable<number> {
		return this.select(state => state.size);
	}

	selectTotal(): Observable<number> {
		return this.select(state => state.total)
	}

	selectPage(): Observable<Event[]>{
		return this.select(state => state.pageIds.map(id => this.getEntity(id)));
	}

	constructor(protected store: EventStore) {
		super(store);
	}
}
