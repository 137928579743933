var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { createCourseEnrollment } from '../model/course-enrollment.model';
import * as i0 from "@angular/core";
var initialState = createCourseEnrollment({});
var CourseEnrollmentStore = /** @class */ (function (_super) {
    __extends(CourseEnrollmentStore, _super);
    function CourseEnrollmentStore() {
        return _super.call(this, initialState) || this;
    }
    CourseEnrollmentStore.prototype.updateProgress = function (id, progress) {
        this.update(id, function (enrollment) {
            console.log(enrollment);
            return enrollment;
        });
    };
    CourseEnrollmentStore = __decorate([
        StoreConfig({ name: 'course-enrollment' }),
        __metadata("design:paramtypes", [])
    ], CourseEnrollmentStore);
    return CourseEnrollmentStore;
}(EntityStore));
export { CourseEnrollmentStore };
var CourseEnrollmentQuery = /** @class */ (function (_super) {
    __extends(CourseEnrollmentQuery, _super);
    function CourseEnrollmentQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        return _this;
    }
    CourseEnrollmentQuery.prototype.sel = function () {
    };
    CourseEnrollmentQuery.ngInjectableDef = i0.defineInjectable({ factory: function CourseEnrollmentQuery_Factory() { return new CourseEnrollmentQuery(i0.inject(CourseEnrollmentStore)); }, token: CourseEnrollmentQuery, providedIn: "root" });
    return CourseEnrollmentQuery;
}(QueryEntity));
export { CourseEnrollmentQuery };
