<mat-sidenav-content class="page">
  <lomas-admin-toolbar title="Resellers"></lomas-admin-toolbar>

  <div class="cards">
    <mat-card>
      <mat-card-title class="search-bar">
        <span class="search-bar__title">Resellers</span>

      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content *ngIf="reseller$ | async as resellers">
        <mat-table [dataSource]="resellers" class="mat-elevation-z8" *ngIf="resellers.length > 0">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef>Actief</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.active ? 'Ja' : 'Nee'}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="key">
            <mat-header-cell *matHeaderCellDef>Key</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.ltiKey}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="secret">
            <mat-header-cell *matHeaderCellDef>Secret</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.ltiSecret}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element"><button mat-raised-button (click)="editReseller(element)">Wijzigen</button></mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </mat-card-content>

    </mat-card>
  </div>
</mat-sidenav-content>

<a mat-fab color="accent" class="mat-fab-bottom-right fixed" (click)="addReseller()">
  <mat-icon>add</mat-icon>
</a>
