import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
	templateUrl: './restore-password.component.html',
	styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent {
	code = new FormControl();
	newPassword = new FormControl('', [
		Validators.required,
		Validators.minLength(6)
	]);
	newPassword2 = new FormControl('', [
		Validators.required,
		this.checkEqual.bind(this)]);
	message = '';
	status: 'new' | 'success' | 'fail' = 'new';

	constructor(private route: ActivatedRoute,
				private authService: AuthService) {
		this.code = new FormControl(route.snapshot.queryParamMap.get('code')  || '');
		this.newPassword2.valueChanges.subscribe(() => {
			this.newPassword.updateValueAndValidity();
		});
	}

	submit() {
		this.authService.restorePassword(this.code.value, this.newPassword.value).subscribe((res) => {
			this.status = 'success';
		}, (err) => {
			this.status = 'fail';
		});

	}

	checkEqual(c: FormControl) {
		if (!this.newPassword) {
			return null;
		}
		if (c.value !== this.newPassword.value) {
			return {notEqual: true}
		}
		return null;
	}

}
