import {Injectable} from "@angular/core";
import {QueryEntity} from "@datorama/akita";
import {CourseState, CourseStore} from "./course.store";
import {Course} from "./course.model";

@Injectable({providedIn: 'root'})
export class CourseQuery extends QueryEntity<CourseState, Course> {
  constructor(protected store: CourseStore) {
    super(store);
  }
}
