import { EntityState, EntityStore, Query, QueryEntity, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { createItem, Item } from '../model/items.model';

const initialState: Item = createItem({});

export interface ItemState extends EntityState<Item> {
}

@StoreConfig({name: 'course'})
export class ItemStore extends EntityStore<ItemState, Item> {
	constructor() {
		super(initialState);
	}
}

@Injectable({
	providedIn: 'root'
})
export class ItemQuery extends QueryEntity<ItemState, Item> {
	constructor(protected store: ItemStore) {
		super(store);
	}
}
