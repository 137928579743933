import { Injectable } from '@angular/core';
import {  UserAccountStore } from '../../store/user-account.store';
import { createAccount, UserAccount } from '../../model/user-account.model'
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class UserAccountService {
	constructor(private accountStore: UserAccountStore,
				private http: HttpClient) {
		accountStore.setState(() => createAccount({id: 'a', firstName: 'JU', preposition: '', surName: '', email: ''}) )

		setTimeout(() => {
			accountStore.setState(() => createAccount({id: 'B', firstName: 'JU', preposition: '', surName: '', email: ''}) )
		}, 3000)
	}

	createAccount(account: UserAccount, courseId: string) {
		return this.http.post('/api/useraccount', {...account, ...{courseId: courseId}});
	}

	confirmAccount(code: string) {
		return this.http.post('/api/useraccount', {code: code});
	}
}