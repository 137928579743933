import { Injectable } from '@angular/core';
import { UserAccount, createAccount } from '../model/user-account.model';
import { Query, Store, StoreConfig } from '@datorama/akita';

const initialState: UserAccount = createAccount({});

@StoreConfig({ name: 'user-account' })
export class UserAccountStore extends Store<UserAccount> {
	constructor() {
		super(initialState);
	}
}

@Injectable({providedIn: 'root'})
export class UserAccountQuery extends Query<UserAccount> {
	account$ = this.select(account => account);
	constructor(protected store: UserAccountStore) {
		super(store);
	}
}