<mat-sidenav-content class="page">
    <lomas-admin-toolbar title="Forum"></lomas-admin-toolbar>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Forum berichten</mat-card-title>
            <span class="flex-title"></span>
            <mat-checkbox class="example-margin" [formControl]="showNew">Toon alleen nieuwe berichten</mat-checkbox>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content *ngIf="blogs$ | async as blogs">
            <mat-table [dataSource]="blogs" class="mat-elevation-z8" *ngIf="blogs.length > 0">

                <ng-container matColumnDef="icon">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="element.seenById !== null">drafts</mat-icon>
                        <mat-icon *ngIf="element.seenById === null">mail</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="courseName">
                    <mat-header-cell *matHeaderCellDef> Cursusnaam</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{courses[element.courseId]}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="partName">
                    <mat-header-cell *matHeaderCellDef> Onderdeel</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{parts[element.partId]}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dateTime">
                    <mat-header-cell *matHeaderCellDef> Datum </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.createDateTime | date: 'yyyy-MM-dd: HH:mm'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="fullName">
                    <mat-header-cell *matHeaderCellDef> Naam </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.fullName}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-raised-button (click)="showPost(element )"><lomas-icon name="file-text"></lomas-icon>Bekijk vraag</button>
                        <button mat-raised-button [routerLink]="['/admin/forum', element.threadId]" ><lomas-icon name="folder"></lomas-icon>Bekijk thread</button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <p></p>
            <mat-paginator *ngIf="blogs.length > 0"
                            [length]="total"
                           [pageSize]="size"
                           [pageSizeOptions]="[5, 10, 25, 100]"
                            (page)="pageChange($event)">
            </mat-paginator>
            <div class="no-results" *ngIf="blogs.length === 0">
                Er zijn geen berichten gevonden
            </div>
        </mat-card-content>

    </mat-card>

</mat-sidenav-content>