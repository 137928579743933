var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { MatDialogRef } from "@angular/material";
import { FormControl, FormGroup, Validators } from "@angular/forms";
var EditResellerComponent = /** @class */ (function () {
    function EditResellerComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.id = null;
        this.formGroup = new FormGroup({
            name: new FormControl('', [Validators.required]),
            active: new FormControl(),
            ltiKey: new FormControl('', [Validators.required]),
            ltiSecret: new FormControl(),
        });
        if (data.reseller) {
            this.formGroup.get('name').setValue(data.reseller.name);
            this.formGroup.get('active').setValue(data.reseller.active);
            this.formGroup.get('ltiKey').setValue(data.reseller.ltiKey);
            this.formGroup.get('ltiSecret').setValue(data.reseller.ltiSecret);
            //this.formGroup.get('ltiSecret').disable();
            this.id = data.reseller.id;
        }
        else {
            this.resetLtiSecret();
            this.formGroup.get('active').setValue(true);
        }
    }
    EditResellerComponent.prototype.resetLtiSecret = function () {
        //this.formGroup.get('ltiSecret').disable();
        this.formGroup.get('ltiSecret').setValue(randomString(10));
    };
    EditResellerComponent.prototype.save = function () {
        this.dialogRef.close(__assign({}, this.formGroup.value, { id: this.id }));
    };
    return EditResellerComponent;
}());
export { EditResellerComponent };
function randomString(length) {
    var chars = '0123456789abcdefghijklmnopqrstuvwxyz';
    var result = '';
    for (var i = length; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}
