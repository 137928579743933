import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { ItemStore } from '../../store/item.store';

@Injectable()
export class ItemService {
	constructor(private http: HttpClient,
				private itemStore: ItemStore) {}

	list(moduleId) {
		return this.http
			.get(`/api/module/${moduleId}/item`)
			.pipe(map(results => this.itemStore.set(results['value'])));
	}

}