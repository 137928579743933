<h2 mat-dialog-title>Actie <span *ngIf="id === 'new'">toevoegen</span><span *ngIf="id !== 'new'">wijzigen</span></h2>
<mat-dialog-content  *ngIf="eventTypes$ | async as eventTypes">

  <mat-form-field>
    <input matInput [matDatepicker]="myDatepicker" placeholder="Datum" [formControl]="dueDate">
    <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
    <mat-datepicker #myDatepicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="full-width">
    <textarea matInput placeholder="Notitie" [formControl]="note" class="note"></textarea>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-select placeholder="Type gebeurtenis" [formControl]="eventTypeId">
      <mat-option *ngFor="let eventType of eventTypes" [value]="eventType.id">
        {{eventType.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions >
  <button mat-button mat-dialog-close>Annuleren</button>
  <button mat-raised-button (click)="save()" color="primary" [disabled]="dueDate.invalid || note.invalid">Opslaan</button>
</mat-dialog-actions>
