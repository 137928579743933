import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'lomas-view-video',
	templateUrl: './view-video.component.html',
	styleUrls: ['./view-video.component.scss']
})
export class ViewVideoComponent implements OnChanges {
	@Input() id: string;
	url: SafeResourceUrl;

	constructor(private sanitizer: DomSanitizer,){}

	ngOnChanges() {
		this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${(this.id || '').trim()}`);
	}
}
