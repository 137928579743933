/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../pipes/safeHtml.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./view-content.component";
import * as i5 from "../../../../services/course-enrollment/course-enrollment.service";
var styles_ViewContentComponent = [i0.styles];
var RenderType_ViewContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewContentComponent, data: {} });
export { RenderType_ViewContentComponent as RenderType_ViewContentComponent };
export function View_ViewContentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "md-margin"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v, 0), _co.html)); _ck(_v, 1, 0, currVal_0); }); }
export function View_ViewContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lomas-view-content", [], null, null, null, View_ViewContentComponent_0, RenderType_ViewContentComponent)), i1.ɵdid(1, 573440, null, 0, i4.ViewContentComponent, [i5.CourseEnrollmentService], null, null)], null, null); }
var ViewContentComponentNgFactory = i1.ɵccf("lomas-view-content", i4.ViewContentComponent, View_ViewContentComponent_Host_0, { id: "id", courseId: "courseId" }, {}, []);
export { ViewContentComponentNgFactory as ViewContentComponentNgFactory };
