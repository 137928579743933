import {Component, OnInit} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {CourseService} from "../../../model/course/course.service";
import {CourseQuery} from "../../../model/course/course.query";
import {Subscription} from "rxjs";
import {filter} from "rxjs/operators";
import {MatSnackBar} from "@angular/material";

@Component({
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  courseForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    url: new FormControl('', [Validators.required]),
    key: new FormControl('', [Validators.required]),
    secret: new FormControl('', [Validators.required]),
    thumbnail: new FormControl(''),
    resourceLinkId: new FormControl(''),
    custom: new FormControl(''),
  });
  src;
  id;
  subscriptions = new Subscription();
  showCourse = false;

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private courseService: CourseService,
    private courseQuery: CourseQuery,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id !== 'new') {
      this.courseService.get(this.id);
      this.subscriptions.add(this.courseQuery.selectActive()
        .pipe(filter(course => course && course.id === this.id))
        .subscribe(course => {
          this.courseForm.get('name').setValue(course.name);
          const ltiLaunch = course.ltiLaunch || {url: '', key: '', secret: '', custom: '', resourceLinkId: ''};
          this.courseForm.get('url').setValue(ltiLaunch.url);
          this.courseForm.get('key').setValue(ltiLaunch.key);
          this.courseForm.get('secret').setValue(ltiLaunch.secret);

          this.courseForm.get('custom').setValue(ltiLaunch.custom);
          this.courseForm.get('resourceLinkId').setValue(ltiLaunch.resourceLinkId);
          this.courseForm.get('thumbnail').setValue(course.thumbnail);

          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(`/lti/launch/${course.id}`);
        })
      );

    }

  }

  save() {
    const id = this.id !== 'new' ? this.id : null;
    const data = {
      id,
      name: this.courseForm.get('name').value,
      public: false,
      active: true,
      thumbnail: this.courseForm.get('thumbnail').value,
      ltiLaunch: {
        url: this.courseForm.get('url').value,
        key: this.courseForm.get('key').value,
        secret: this.courseForm.get('secret').value,
        resourceLinkId: this.courseForm.get('resourceLinkId').value,
        custom: this.courseForm.get('custom').value,
      }
    };
    this.courseService.save(data).subscribe(() => {
      this.courseService.list();
      window.history.back();
    }, () => {
      this.snackBar.open('Er is iets misgegaan met het opslaan van de cursus', 'ok', {duration: 4000});
    });
  }

  toggleLaunch() {
    this.showCourse = !this.showCourse;
  }
}
