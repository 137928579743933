import { AuthGuard } from './services/auth/auth.guard';
import { LoginComponent } from './pages/sign-in/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { AdminOutletComponent } from './pages/admin/admin-outlet.component';
import { AdminSettingsComponent } from './pages/admin/settings/settings.component';
import { AdminDashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { LearningOutletComponent } from './pages/learning/learning-outlet.component';
import { LearningDashboardComponent } from './pages/learning/dashboard/learning-dashboard.component';
import { NotFoundComponent } from './pages/notfound/notfound-page.component';
import { NotallowedComponent } from './pages/notallowed/notallowed-page.component';
import { ForgotPasswordComponent } from './pages/sign-in/forgot-password/forgot-password.component';
import { RestorePasswordComponent } from './pages/sign-in/restore-password/restore-password.component';
import { CreateAccountComponent } from './pages/sign-up/create-account/create-account.component';
import { ConfirmAccountComponent } from './pages/sign-up/confirm-account/confirm-account.component';
import { CourseViewerComponent } from './pages/learning/course-viewer/course-viewer.component';
import { LicenseCodesComponent } from './pages/admin/license-codes/license-codes.component';
import { CourseEnrollmentsComponent } from './pages/admin/course-enrollments/course-enrollments.component';
import { ForumComponent } from './pages/admin/forum/forum.component';
import { ThreadComponent } from './pages/admin/thread/thread.component';
import { UnsubscribeComponent } from './pages/sign-up/unsubscribe/unsubscribe.component';
import { NewslettersComponent } from './pages/admin/newsletters/newsletters.component';
import { NewsletterComponent } from './pages/admin/newsletter/newsletter.component';
import { SubscribeComponent } from './pages/sign-up/subscribe/subscribe.component';
import { ConfirmNewsletterComponent } from './pages/sign-up/confirm-newsletter/confirm-newsletter.component';
import { NewsletterWebComponent } from './pages/newsletter-web/newsletter-web.component';
import { EventTypeComponent } from './pages/admin/event-type/event-type.component';
import { AccountsComponent } from './pages/admin/accounts/accounts.component';
import { AccountComponent } from './pages/admin/account/account.component';
import { EventsComponent } from './pages/admin/events/events.component';
import { AccountResolver } from './resolvers/account.resolver';
import { CourseEnrollmentsResolver } from './pages/admin/course-enrollments/course-enrollments.resolver';
import { CatalogComponent } from './pages/learning/catalog/catalog.component';
import { ActionsComponent } from "./pages/admin/actions/actions.component";
import { ResellersComponent } from "./pages/admin/resellers/resellers.component";
import { CoursesComponent } from "./pages/admin/courses/courses.component";
import { CourseComponent } from "./pages/admin/course/course.component";
import { LicensesComponent } from "./pages/admin/licenses/licenses.component";
var ɵ0 = {
    allowedRoles: ['admin']
}, ɵ1 = {
    allowedRoles: ['admin', 'student']
}, ɵ2 = {
    allowedRoles: ['admin', 'student', 'lti']
}, ɵ3 = {
    allowedRoles: ['admin', 'student', 'lti']
};
var routes = [{
        path: '',
        component: HomeComponent
    }, {
        path: 'login',
        children: [{
                path: '',
                component: LoginComponent
            }, {
                path: 'restore',
                component: RestorePasswordComponent
            }, {
                path: 'forgot',
                component: ForgotPasswordComponent
            }],
    }, {
        path: 'sign-up',
        children: [{
                path: '',
                component: CreateAccountComponent
            }, {
                path: 'subscribe',
                component: SubscribeComponent,
            }, {
                path: 'confirm-newsletter',
                component: ConfirmNewsletterComponent,
            }, {
                path: 'confirm',
                component: ConfirmAccountComponent
            }, {
                path: 'unsubscribe',
                component: UnsubscribeComponent
            }]
    }, {
        path: 'logout',
        component: LogoutComponent
    }, {
        path: '401',
        component: NotallowedComponent,
    }, {
        path: '404',
        component: NotFoundComponent,
    }, {
        path: 'admin',
        component: AdminOutletComponent,
        canActivate: [AuthGuard],
        data: ɵ0,
        children: [{
                path: '',
                component: AdminDashboardComponent,
            }, {
                path: 'actions',
                component: ActionsComponent,
            }, {
                path: 'courses',
                children: [{
                        path: '',
                        component: CoursesComponent,
                    }, {
                        path: ':id',
                        component: CourseComponent,
                    }]
            }, {
                path: 'course-enrollments',
                component: CourseEnrollmentsComponent,
                resolve: {
                    overview: CourseEnrollmentsResolver,
                }
            }, {
                path: 'events',
                component: EventsComponent
            }, {
                path: 'event-types',
                component: EventTypeComponent
            }, {
                path: 'license-codes',
                component: LicenseCodesComponent
            }, {
                path: 'licenses',
                component: LicensesComponent,
            }, {
                path: 'newsletters',
                children: [{
                        path: '',
                        component: NewslettersComponent
                    }, {
                        path: ':id',
                        component: NewsletterComponent
                    }]
            }, {
                path: 'accounts',
                children: [
                    {
                        path: '',
                        component: AccountsComponent
                    },
                    {
                        path: ':id',
                        component: AccountComponent,
                        resolve: {
                            check: AccountResolver
                        }
                    }
                ]
            }, {
                path: 'forum',
                children: [{
                        path: '',
                        component: ForumComponent
                    }, {
                        path: ':threadId',
                        component: ThreadComponent
                    }]
            }, {
                path: 'resellers',
                component: ResellersComponent,
            }, {
                path: 'settings',
                component: AdminSettingsComponent,
            }]
    }, {
        path: 'learning',
        component: LearningOutletComponent,
        canActivate: [AuthGuard],
        data: ɵ1,
        children: [{
                path: '',
                component: LearningDashboardComponent,
                canActivate: [AuthGuard],
            }, {
                path: 'catalog',
                component: CatalogComponent,
                canActivate: [AuthGuard],
                data: ɵ2
            }, {
                path: 'course-viewer/:id',
                component: CourseViewerComponent,
                data: ɵ3
            }]
    }, {
        path: 'newsletter-web/:newsletterId',
        component: NewsletterWebComponent
    }, {
        path: '**',
        redirectTo: '/404'
    }];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export var pageComponents = [
    AccountsComponent,
    AccountComponent,
    ActionsComponent,
    AdminOutletComponent,
    AdminDashboardComponent,
    AdminSettingsComponent,
    CatalogComponent,
    ConfirmAccountComponent,
    ConfirmNewsletterComponent,
    CourseViewerComponent,
    CourseEnrollmentsComponent,
    CreateAccountComponent,
    EventsComponent,
    EventTypeComponent,
    ForgotPasswordComponent,
    ForumComponent,
    HomeComponent,
    LearningOutletComponent,
    LearningDashboardComponent,
    LicenseCodesComponent,
    LicensesComponent,
    LoginComponent,
    LogoutComponent,
    NewslettersComponent,
    NewsletterComponent,
    NewsletterWebComponent,
    NotallowedComponent,
    NotFoundComponent,
    RestorePasswordComponent,
    SubscribeComponent,
    ThreadComponent,
    UnsubscribeComponent,
];
export { ɵ0, ɵ1, ɵ2, ɵ3 };
