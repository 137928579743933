import { map, tap } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { EventTypeQuery, EventTypeStore } from '../../store/event-type.store';
import { of } from 'rxjs/index';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../store/event-type.store";
var EventTypeService = /** @class */ (function () {
    function EventTypeService(http, eventTypeStore, eventTypeQuery) {
        this.http = http;
        this.eventTypeStore = eventTypeStore;
        this.eventTypeQuery = eventTypeQuery;
    }
    EventTypeService.prototype.list = function (top, skip, search) {
        var _this = this;
        if (search === void 0) { search = ''; }
        return this.http.get("/api/event-type/?$top=" + top + "&$skip=" + skip + "&search=" + search)
            .pipe(tap(function (results) {
            var data = results['value'];
            _this.eventTypeStore.set(data);
        }), map(function (results) { return results['count']; }));
    };
    EventTypeService.prototype.listByIds = function (ids) {
        var _this = this;
        var uniqueIds = ids
            .filter(function (id, index, self) { return self[index] === id; })
            .filter(function (id) { return !_this.eventTypeQuery.getEntity(id); });
        if (uniqueIds.length === 0) {
            return of([]);
        }
        return this.http.get("/api/event-type?ids=" + ids)
            .pipe(map(function (results) {
            results['value'].forEach(function (event) { return _this.eventTypeStore.upsert(event.id, event); });
            return [];
        }));
    };
    EventTypeService.prototype.save = function (eventType) {
        if (eventType.id === 'new') {
            return this.http.post('/api/event-type', eventType);
        }
        else {
            return this.http.put("/api/event-type/" + eventType.id, eventType);
        }
    };
    EventTypeService.ngInjectableDef = i0.defineInjectable({ factory: function EventTypeService_Factory() { return new EventTypeService(i0.inject(i1.HttpClient), i0.inject(i2.EventTypeStore), i0.inject(i2.EventTypeQuery)); }, token: EventTypeService, providedIn: "root" });
    return EventTypeService;
}());
export { EventTypeService };
