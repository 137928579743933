<mat-sidenav-container>
    <mat-sidenav></mat-sidenav>
    <mat-sidenav-content>

        <lomas-public-toolbar></lomas-public-toolbar>

        <section>
            <mat-card>
                <mat-card-title>Password vergeten</mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <form (submit)="submit()">
                        <mat-form-field class="full-width">
                            <input [formControl]="email" (change)="resetMessage()" matInput placeholder="e-mail">
                        </mat-form-field>

                        <p>
                            <button type="submit" [disabled]="email.errors" mat-raised-button color="primary">
                                Versturen
                            </button>
                        </p>


                        <p *ngIf="message">{{message}}</p>
                        <p class="login">
                            <a [routerLink]="['/login']">Login</a>
                        </p>

                    </form>
                </mat-card-content>
            </mat-card>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>




