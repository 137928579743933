var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter } from '@angular/core';
import { AddBlogpostComponent } from '../../dialogs/add-blogpost/add-blogpost.component';
import { MatDialog } from '@angular/material';
import { BlogService } from '../../../../services/blog/blog.service';
import { BlogQuery } from '../../../../store/blog.store';
import { map } from 'rxjs/internal/operators';
var ViewBlogComponent = /** @class */ (function () {
    function ViewBlogComponent(dialog, blogService, blogQuery) {
        var _this = this;
        this.dialog = dialog;
        this.blogService = blogService;
        this.blogQuery = blogQuery;
        this.close = new EventEmitter();
        this.blogs$ = this.blogQuery.selectAll().pipe(map(function (blogs) { return blogs.filter(function (blog) { return blog.partId === _this.partId; }); }));
    }
    ViewBlogComponent.prototype.addBlogpost = function (replyBlog) {
        var _this = this;
        var replyId = (replyBlog || { id: null }).id;
        this.dialog.open(AddBlogpostComponent, {
            width: '800px',
            height: '560px',
        }).afterClosed().subscribe(function (result) {
            if (result) {
                _this.blogService.save(__assign({}, result, { courseId: _this.courseId, partId: _this.partId, replyId: replyId })).subscribe();
            }
        });
    };
    ViewBlogComponent.prototype.closeBlog = function () {
        this.close.emit();
    };
    return ViewBlogComponent;
}());
export { ViewBlogComponent };
