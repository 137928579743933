import { Component } from '@angular/core';
import { MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { debounce } from 'rxjs/internal/operators';
import { EventType } from '../../../model/event-type.model';
import { FormControl } from '@angular/forms';
import { timer } from 'rxjs/index';
import { EventTypeQuery } from '../../../store/event-type.store';
import { EventTypeService } from '../../../services/event-type/event-type.service';
import { EditEventTypeComponent } from '../dialogs/edit-event-type/edit-event-type.component';
import { EventService } from '../../../services/event/event.service';
import { EventQuery } from '../../../store/event.store';
import { ID } from '@datorama/akita';
import { AccountQuery } from '../../../store/account.store';

@Component({
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.scss']
})

export class EventsComponent {
	displayedColumns: string[] = ['date', 'eventType', 'account', 'admin', 'note'];
	size$ = this.eventQuery.selectSize();
	pageIndex$ = this.eventQuery.selectPageIndex();
	total$ = this.eventQuery.selectTotal();
	events$ = this.eventQuery.selectPage();
	searchText = new FormControl('');

	constructor(private eventTypeQuery: EventTypeQuery,
				private eventTypeService: EventTypeService,
				private eventQuery: EventQuery,
				private eventService: EventService,
				private accountQuery: AccountQuery,)
	{}

	ngOnInit() {
		this.loadData(25, 0);
	}

	loadData(size, pageIndex) {
		this.eventService.overview( size, pageIndex * size)
			.subscribe();
	}

	pageChange(event: PageEvent) {
		this.loadData(event.pageSize, event.pageIndex);
	}

	getEventTypeName(id: string) {
		const eventType = this.eventTypeQuery.getEntity(id);
		return eventType ? eventType.name : '';
	}

	getAccountName(id: ID): string {
		const account = this.accountQuery.getEntity(id);
		return account ? `${account.firstName} ${account.preposition} ${account.surName}` : '';
	}
}
