import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {

    const userRoles: string[] = (this.authService.getPayload() || {roles: []}).roles;
    userRoles.push('*');

    const data = route.firstChild && route.firstChild.data && route.firstChild.data.allowedRoles ? route.firstChild.data : route.data;
    const allowedRoles = (data || {}).allowedRoles || [];

    const isAllowed = userRoles.some(role => allowedRoles.indexOf(role) > -1);

    if (!(this.authService.isAuthenticated() && isAllowed)) {

      this.router.navigate(['/login'], {queryParams: {next: state.url}})
    }

    return this.authService.isAuthenticated() && isAllowed;
  }
}
