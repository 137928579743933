<h2 mat-dialog-title>Licentiecode aanmaken</h2>
<mat-dialog-content>
    <div *ngIf="status === 'new'">
        <mat-form-field class="full-width">
            <mat-select placeholder="Cursus" [formControl]="course">
                <mat-option *ngFor="let course of course$ | async" [value]="course">
                    {{course.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Reseller" [formControl]="reseller">
            <mat-option *ngFor="let reseller of reseller$ | async" [value]="reseller">
              {{reseller.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width">
            <input matInput type="number" placeholder="Geldigheid (in dagen)" [formControl]="duration"/>
        </mat-form-field>
        <mat-form-field class="full-width">
            <input matInput placeholder="Naam/referentie" [formControl]="fullName" />
        </mat-form-field>
    </div>
    <div *ngIf="status === 'loading'">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
    <div *ngIf="status === 'created'">
        <table>
            <tr><td>Cursus</td><td class="value">{{course.value.name}}</td></tr>
            <tr><td>Duur</td><td class="value">{{duration.value}}</td></tr>
            <tr><td>Naam/Referentie</td><td class="value">{{fullName.value}}</td></tr>
            <tr><td class="code">Code</td><td class="value code">{{licenseCode.code}}</td></tr>

        </table>
    </div>
    <div *ngIf="status === 'error'">
        Er is iets misgegaan met het aanmaken van een licentiecode.
    </div>

</mat-dialog-content>
<mat-dialog-actions *ngIf="status === 'new'">
    <button mat-button mat-dialog-close>Annuleren</button>
    <button mat-raised-button (click)="create()" color="primary" [disabled]="course.invalid || duration.invalid">Opslaan</button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="status === 'created'">
    <button mat-button mat-dialog-close>Annuleren</button>
    <button mat-raised-button (click)="another()" color="primary" >Nieuwe code</button>
</mat-dialog-actions>
