/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-items.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../components/audio-player/audio-player.component.ngfactory";
import * as i4 from "../../../../components/audio-player/audio-player.component";
import * as i5 from "../../../../../../node_modules/@angular/material/radio/typings/index.ngfactory";
import * as i6 from "@angular/material/radio";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/collections";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/forms";
import * as i11 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i12 from "@angular/material/icon";
import * as i13 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i14 from "@angular/material/button";
import * as i15 from "@angular/cdk/platform";
import * as i16 from "../../../../pipes/safeHtml.pipe";
import * as i17 from "@angular/platform-browser";
import * as i18 from "./view-items.component";
import * as i19 from "../../../../store/item.store";
var styles_ViewItemsComponent = [i0.styles];
var RenderType_ViewItemsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewItemsComponent, data: {} });
export { RenderType_ViewItemsComponent as RenderType_ViewItemsComponent };
function View_ViewItemsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "btn-round"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonClick(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { selected: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn-round"; var currVal_1 = _ck(_v, 2, 0, (_v.context.index === _co.selectedIndex)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = (_v.context.index + 1); _ck(_v, 4, 0, currVal_2); }); }
function View_ViewItemsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lomas-audio-player", [], null, null, null, i3.View_AudioPlayerComponent_0, i3.RenderType_AudioPlayerComponent)), i1.ɵdid(1, 704512, null, 0, i4.AudioPlayerComponent, [], { source: [0, "source"], label: [1, "label"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.source; var currVal_1 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ViewItemsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "audio"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewItemsComponent_4)), i1.ɵdid(2, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items[_co.selectedIndex].data.audios; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ViewItemsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-radio-button", [["class", "example-radio-button mat-radio-button"]], [[2, "mat-radio-checked", null], [2, "mat-radio-disabled", null], [2, "_mat-animation-noopable", null], [1, "id", 0]], [[null, "focus"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatRadioButton_0, i5.RenderType_MatRadioButton)), i1.ɵdid(1, 4440064, [[1, 4]], 0, i6.MatRadioButton, [[2, i6.MatRadioGroup], i1.ElementRef, i1.ChangeDetectorRef, i7.FocusMonitor, i8.UniqueSelectionDispatcher, [2, i9.ANIMATION_MODULE_TYPE]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_4 = _v.context.$implicit; _ck(_v, 1, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).checked; var currVal_1 = i1.ɵnov(_v, 1).disabled; var currVal_2 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_3 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit; _ck(_v, 2, 0, currVal_5); }); }
function View_ViewItemsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "mat-radio-group", [["class", "example-radio-group mat-radio-group"], ["role", "radiogroup"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedAnswer = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 1064960, null, 1, i6.MatRadioGroup, [i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _radios: 1 }), i1.ɵprd(1024, null, i10.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.MatRadioGroup]), i1.ɵdid(5, 671744, null, 0, i10.NgModel, [[8, null], [8, null], [8, null], [6, i10.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i10.NgControl, null, [i10.NgModel]), i1.ɵdid(7, 16384, null, 0, i10.NgControlStatus, [[4, i10.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewItemsComponent_6)), i1.ɵdid(9, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.selectedAnswer; _ck(_v, 5, 0, currVal_7); var currVal_8 = _co.items[_co.selectedIndex].data.answers; _ck(_v, 9, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ViewItemsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "explanation"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _co.items[_co.selectedIndex].data.explanation)); _ck(_v, 0, 0, currVal_0); }); }
function View_ViewItemsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["done_outline"])), (_l()(), i1.ɵted(-1, null, [" Correct! "]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_ViewItemsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵted(-1, null, [" Onjuist, het correcte antwoord is "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.items[_co.selectedIndex].data.answers[_co.items[_co.selectedIndex].data.correct]; _ck(_v, 6, 0, currVal_1); }); }
function View_ViewItemsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewItemsComponent_9)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewItemsComponent_10)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.answerCorrect(); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.answerCorrect(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ViewItemsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewItemsComponent_2)), i1.ɵdid(2, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "question"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewItemsComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewItemsComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showExplanation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_MatButton_0, i13.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i14.MatButton, [i1.ElementRef, i15.Platform, i7.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Toon antwoord/uitleg"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewItemsComponent_7)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewItemsComponent_8)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.items[_co.selectedIndex].data.audios; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.items[_co.selectedIndex].data.type === "mc"); _ck(_v, 8, 0, currVal_3); var currVal_6 = "primary"; _ck(_v, 10, 0, currVal_6); var currVal_7 = _co.explanationShown; _ck(_v, 13, 0, currVal_7); var currVal_8 = ((_co.items[_co.selectedIndex].data.type === "mc") && _co.explanationShown); _ck(_v, 15, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.items[_co.selectedIndex].data.question)); _ck(_v, 3, 0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 10).disabled || null); var currVal_5 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_4, currVal_5); }); }
export function View_ViewItemsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i16.SafeHtmlPipe, [i17.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewItemsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.items.length > 0) && _co.items[_co.selectedIndex]); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ViewItemsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lomas-view-items", [], null, null, null, View_ViewItemsComponent_0, RenderType_ViewItemsComponent)), i1.ɵdid(1, 573440, null, 0, i18.ViewItemsComponent, [i19.ItemQuery], null, null)], null, null); }
var ViewItemsComponentNgFactory = i1.ɵccf("lomas-view-items", i18.ViewItemsComponent, View_ViewItemsComponent_Host_0, { ids: "ids", selectedIndex: "selectedIndex" }, { selected: "selected" }, []);
export { ViewItemsComponentNgFactory as ViewItemsComponentNgFactory };
