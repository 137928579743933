import { Component } from '@angular/core';
import { PageEvent } from '@angular/material';
import { EnrollmentOverviewService } from '../../../services/enrollment-overview/enrollment-overview.service';
import { EnrollmentOverviewQuery } from '../../../store/enrollment-overview.store';
import { CourseQuery } from '../../../store/course.store';
import { ID } from '@datorama/akita';
import { AccountQuery } from '../../../store/account.store';

@Component({
	templateUrl: './course-enrollments.component.html',
	styleUrls: ['./course-enrollments.component.scss']
})
export class CourseEnrollmentsComponent {
	displayedColumns: string[] = ['createDateTime', 'course', 'account'];
	size$ = this.enrollmentOverviewQuery.selectSize();
	pageIndex$ = this.enrollmentOverviewQuery.selectPageIndex();
	total$ = this.enrollmentOverviewQuery.selectTotal();
	enrollmentOverview$ = this.enrollmentOverviewQuery.selectPage();

	constructor(private enrollmentOverviewService: EnrollmentOverviewService,
				private enrollmentOverviewQuery: EnrollmentOverviewQuery,
				private courseQuery: CourseQuery,
				private accountQuery: AccountQuery,)
	{}

	pageChange(event: PageEvent) {
		this.enrollmentOverviewService.list( event.pageSize, event.pageIndex)
			.subscribe();
	}

	getCourseName(id: ID): string{
		return (this.courseQuery.getEntity(id) || {name: ''}).name;
	}

	getAccountName(id: ID): string {
		const account = this.accountQuery.getEntity(id);
		return account ? `${account.firstName} ${account.preposition} ${account.surName}` : '';
	}
}