import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Blog, createBlog } from '../model/blog.model';

const initialState: Blog = createBlog({});

export interface BlogState extends EntityState<Blog> {}

@StoreConfig({name: 'blog'})
export class BlogStore extends EntityStore<BlogState, Blog> {
	constructor() {
		super(initialState);
	}
}

@Injectable({providedIn: 'root'})
export class BlogQuery extends QueryEntity<BlogState, Blog> {
	constructor(protected store: BlogStore) {
		super(store);
	}
}