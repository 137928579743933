import {ID} from '@datorama/akita';

export type CourseEnrollment = {
  id: ID;
  courseId: string;
  accountId: string;
  name: string;
  progress: any,
  lastUpdate: Date,
  publicAccessible: boolean,
  endDate: Date,
  thumbnail,
}

export function createCourseEnrollment({
                                         id = null,
                                         accountId = null,
                                         courseId = '',
                                         name = '',
                                         progress = null,
                                         lastUpdate = null,
                                         publicAccessible = false,
                                         endDate = null,
                                         thumbnail = ''
                                       }: Partial<CourseEnrollment>) {
  return {
    id,
    accountId,
    courseId,
    name,
    progress,
    lastUpdate: createDate(lastUpdate),
    publicAccessible,
    endDate: createDate(endDate),
    thumbnail,
  }
}

function createDate(dateString: Date) {
  return dateString ? new Date(dateString) : null;
}
