import { Observable } from 'rxjs/Rx';
import { AccountQuery, AccountStore } from '../store/account.store';
import { AccountService } from '../services/account/account.service';
import { MatSnackBar } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "../store/account.store";
import * as i2 from "../services/account/account.service";
import * as i3 from "@angular/material/snack-bar";
var AccountResolver = /** @class */ (function () {
    function AccountResolver(accountQuery, accountStore, accountService, snackBar) {
        this.accountQuery = accountQuery;
        this.accountStore = accountStore;
        this.accountService = accountService;
        this.snackBar = snackBar;
    }
    AccountResolver.prototype.resolve = function (route) {
        var _this = this;
        var accountId = route.paramMap.get('id');
        return Observable.create(function (observer) {
            if (_this.accountQuery.getEntity(accountId)) {
                _this.accountStore.setActive(accountId);
                observer.next(true);
                observer.complete();
            }
            else {
                _this.accountService.get(accountId)
                    .subscribe(function () {
                    _this.accountStore.setActive(accountId);
                    observer.next(true);
                    observer.complete();
                }, function (err) {
                    observer.next(false);
                    observer.complete();
                    _this.snackBar.open('Er is iets misgegaan met het ophalen van de data', 'ok', { duration: 4000 });
                });
            }
        });
    };
    AccountResolver.ngInjectableDef = i0.defineInjectable({ factory: function AccountResolver_Factory() { return new AccountResolver(i0.inject(i1.AccountQuery), i0.inject(i1.AccountStore), i0.inject(i2.AccountService), i0.inject(i3.MatSnackBar)); }, token: AccountResolver, providedIn: "root" });
    return AccountResolver;
}());
export { AccountResolver };
