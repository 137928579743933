<mat-sidenav-container>
    <mat-sidenav></mat-sidenav>
    <mat-sidenav-content>

        <lomas-public-toolbar></lomas-public-toolbar>

        <section>
            <mat-card>
                <mat-card-title>Uitschrijven</mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <div class="result">
                        <p *ngIf="status === 'new'">
                            U wordt uitgeschreven...
                        </p>
                        <p *ngIf="status === 'success'">
                            U bent uitgeschreven.
                        </p>
                        <p *ngIf="status === 'fail'">
                            Er is iets misgegaan met het uitschrijven.
                        </p>
                    </div>
                </mat-card-content>
            </mat-card>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>