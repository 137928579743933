import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';
import { CourseStore } from '../../store/course.store';
import { Course } from '../../model/course.model';
import { PublicCourseStore } from '../../store/pubic-course.store';
import { of } from 'rxjs/index';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class PublicCourseService {
	constructor(private http: HttpClient,
				private publicCourseStore: PublicCourseStore) {}

	list(refresh? : boolean): Observable<any> {
		if (this.publicCourseStore.isPristine || refresh) {
			return this.listCourses();
		} else {
			return of();
		}
	}

	private listCourses() {
		return this.http
			.get('/api/publiccourse')
			.pipe(map(results => this.publicCourseStore.set(results[ 'value' ])));
	}
}