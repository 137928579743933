import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { AccountQuery, AccountStore } from '../store/account.store';
import { AccountService } from '../services/account/account.service';
import { MatSnackBar } from '@angular/material';

@Injectable({
	providedIn: 'root'
})
export class AccountResolver implements Resolve<Observable<boolean>> {
	constructor(private accountQuery: AccountQuery,
				private accountStore: AccountStore,
				private accountService: AccountService,
				private snackBar: MatSnackBar) {}

	resolve(route: ActivatedRouteSnapshot) {
		const accountId = route.paramMap.get('id');

		return Observable.create(observer => {
			if (this.accountQuery.getEntity(accountId)) {
				this.accountStore.setActive(accountId);
				observer.next(true);
				observer.complete();
			} else {
				this.accountService.get(accountId)
					.subscribe(() => {
						this.accountStore.setActive(accountId);
						observer.next(true);
						observer.complete();
					}, (err) => {
						observer.next(false);
						observer.complete();
						this.snackBar.open('Er is iets misgegaan met het ophalen van de data', 'ok', {duration: 4000});
					})
			}

		});
	}
}