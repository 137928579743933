<mat-sidenav-container>
    <mat-sidenav></mat-sidenav>
    <mat-sidenav-content>

        <lomas-public-toolbar></lomas-public-toolbar>

        <section>
            <mat-card>
                <mat-card-title>Maak een account</mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <p *ngIf="course$ | async">
                        Maak eerst een account aan om
                        <strong>{{(course$ | async) .name}}</strong>
                        uit te proberen.
                    </p>
                    <form (submit)="submit()" [formGroup]="createAccountForm" *ngIf="status === 'new'">
                        <p>
                            <mat-form-field class="full-width" color="accent">
                                <input matInput formControlName="firstName" placeholder="Voornaam"/>
                            </mat-form-field>
                            <mat-form-field class="full-width" color="accent">
                                <input matInput formControlName="preposition" placeholder="Tussenvoegsel"/>
                            </mat-form-field>
                            <mat-form-field class="full-width" color="accent">
                                <input matInput formControlName="surName" placeholder="Achternaam"/>
                            </mat-form-field>
                            <mat-form-field  class="full-width" color="accent">
                                <input matInput formControlName="email" placeholder="Email"/>
                                <mat-error *ngIf="email.invalid && email.errors.required">Dit veld is verplicht</mat-error>
                                <mat-error *ngIf="email.invalid && email.errors.email">Vul een correct e-mailadres in</mat-error>
                            </mat-form-field>
                            <mat-form-field class="full-width" color="accent">
                                <input matInput type="password" formControlName="password" placeholder="Password"/>
                                <mat-hint>Minimaal 6 karakters lang</mat-hint>
                                <mat-error *ngIf="password.invalid && password.errors.required">Dit veld is verplicht</mat-error>
                                <mat-error *ngIf="password.invalid && password.errors.minlength">Het password moet minimaal 6 karakters lang zijn</mat-error>
                            </mat-form-field>
                        </p>
                        <button mat-raised-button color="primary" type="submit" [disabled]="createAccountForm.invalid" >
                            Versturen
                        </button>
                        <p class="login">
                            Heb je al een account: <a [routerLink]="['/login']" [queryParamsHandling]="'merge'">Inloggen</a>
                        </p>

                    </form>
                    <div *ngIf="status !== 'new'" class="result">
                        <p *ngIf="status === 'success'">
                            Uw account is aangemaakt. Er is een email gestuurd waarmee u het account kunt activeren.
                        </p>
                        <p *ngIf="status === 'exists'">
                            Er bestaat al een account met dit e-mailadres. <a [routerLink]="['/login']" [queryParamsHandling]="'merge'">Login</a>
                        </p>
                        <p *ngIf="status === 'fail'">
                            Er is iets misgegaan met het aanmaken van uw account.
                        </p>
                    </div>
                </mat-card-content>
            </mat-card>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>

