import { ID } from '@datorama/akita';

export type Item = {
	id: ID;
	moduleId: string;
	name: string;
	data: any;
}

export function createItem({
								 id = null,
								 moduleId = '',
								 name = '',
								 data = null,}: Partial<Item>){
	return {
		id,
		moduleId,
		name,
		data,
	}
}