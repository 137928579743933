import { map, tap } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { AccountQuery, AccountStore } from '../../store/account.store';
import { of } from 'rxjs/index';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../store/account.store";
var AccountService = /** @class */ (function () {
    function AccountService(http, accountStore, accountQuery) {
        this.http = http;
        this.accountStore = accountStore;
        this.accountQuery = accountQuery;
        this.pageSize = 25;
        this.pageIndex = 0;
        this.searchText = '';
    }
    AccountService.prototype.get = function (id) {
        var _this = this;
        return this.http.get("/api/account/" + id)
            .pipe(tap(function (result) { return _this.accountStore.upsert(id, result['value']); }));
    };
    AccountService.prototype.list = function (ids) {
        var _this = this;
        var uniqueIds = ids
            .filter(function (id, index, self) { return self.indexOf(id) === index; })
            .filter(function (x) { return x; })
            .filter(function (id) { return !_this.accountQuery.getEntity(id); });
        if (uniqueIds.length === 0) {
            return of([]);
        }
        return this.http.get("/api/account?ids=" + uniqueIds)
            .pipe(map(function (results) {
            results['value'].forEach(function (account) { return _this.accountStore.upsert(account.id, account); });
            return [];
        }));
    };
    AccountService.prototype.page = function (top, skip, search, ids) {
        var _this = this;
        if (search === void 0) { search = ''; }
        if (ids === void 0) { ids = []; }
        var idsProp = ids.length > 0 ? "&ids=" + ids.join() : '';
        if (ids.length > 0) {
            top = ids.length;
            skip = 0;
        }
        return this.http.get("/api/account/?$top=" + top + "&$skip=" + skip + "&search=" + search + idsProp)
            .pipe(tap(function (results) {
            var data = results['value']
                .map(function (row) {
                row.enrolled = row.roles !== null;
                return row;
            });
            _this.accountStore.set(data);
        }), map(function (results) { return results['count']; }));
    };
    AccountService.prototype.save = function (subscriber) {
        if (subscriber.id === 'new') {
            return this.http.post('/api/account', subscriber);
        }
        else {
            return this.http.put("/api/account/" + subscriber.id, subscriber);
        }
    };
    AccountService.ngInjectableDef = i0.defineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.inject(i1.HttpClient), i0.inject(i2.AccountStore), i0.inject(i2.AccountQuery)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };
