<div class="viewer">
    <p class="no-blogs" *ngIf="(blogs$ | async).length === 0">
        Er zijn nog geen vragen bij deze paragraaf.
    </p>
    <button mat-raised-button color="primary" (click)="addBlogpost()">Stel een vraag</button>
    <button mat-button (click)="closeBlog()">Terug naar de cursus</button>

    <lomas-blog-post
            *ngFor="let blog of blogs$ | async"
            [depth]="0"
            [children]="blogs$ | async"
            [blog]="blog"
            [courseId]="courseId"
            [partId]="partId"
            [threadId]="blog.id"
            (addPost)="addBlogpost($event)">

    </lomas-blog-post>
</div>