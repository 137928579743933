import { Component, Input } from '@angular/core';
import { StateQuery } from '../../../store/state.store';

@Component({
	selector: 'lomas-admin-toolbar',
	templateUrl: './admin-toolbar.component.html',
	styleUrls: ['./admin-toolbar.component.scss']
})
export class AdminToolbarComponent {
	@Input() title: string;

	constructor(private stateQuery: StateQuery) {}

	toggleSideNav = () => this.stateQuery.openSideNav();
}