<mat-sidenav-content class="page">
    <lomas-admin-toolbar title="Gebeurtenissen"></lomas-admin-toolbar>

    <div class="cards">
        <mat-card>
            <mat-card-title class="search-bar">
                <span class="search-bar__title">Gebeurtenissen</span>
<!--                <mat-form-field class="search-bar__input">
                    <input matInput [formControl]="searchText" placeholder="Zoeken"/>
                </mat-form-field>-->
            </mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content *ngIf="events$ | async as events">
                <mat-table [dataSource]="events" class="mat-elevation-z8" *ngIf="events.length > 0">

                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef> Datum</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.createDateTime | date:'yyyy-MM-dd (HH:mm)'}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="eventType">
                        <mat-header-cell *matHeaderCellDef> Event type </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{getEventTypeName(element.eventTypeId) }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="account">
                        <mat-header-cell *matHeaderCellDef> Account </mat-header-cell>
                        <mat-cell *matCellDef="let element"> <a [routerLink]="['/admin/accounts', element.accountId]">{{getAccountName(element.accountId) }}</a> </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="admin">
                        <mat-header-cell *matHeaderCellDef> Door </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{getAccountName(element.adminAccountId) }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="note">
                        <mat-header-cell *matHeaderCellDef> Notitie </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.note }} </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <p></p>
                <mat-paginator *ngIf="events.length > 0"
                               [length]="total$ | async"
                               [pageSize]="size$ | async"
                               [pageSizeOptions]="[5, 10, 25, 100]"
                               (page)="pageChange($event)">
                </mat-paginator>
            </mat-card-content>
        </mat-card>
    </div>
</mat-sidenav-content>