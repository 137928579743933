import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/internal/operators';
import { CourseService } from '../course/course.service';
import { EnrollmentOverviewStore } from '../../store/enrollment-overview.store';
import { AccountService } from '../account/account.service';

@Injectable({
	providedIn: 'root'
})
export class EnrollmentOverviewService {
	constructor(private http: HttpClient,
				private courseService: CourseService,
				private accountService: AccountService,
				private enrollmentOverviewStore: EnrollmentOverviewStore)
	{}

	list(top: number, skip: number) {
		return this.http.get(`/api/enrollment-overview?&$top=${top}&$skip=${skip}&publicAccessible=true`)
			.pipe(
				switchMap(results => this.courseService.list().map(inner => results)),
				switchMap(results => {
					const courseIds = results['value'].map(enrollment => enrollment.accountId);
					return this.accountService.list(courseIds)
						.pipe(map(inner => results));
				}),
				tap(results => this.enrollmentOverviewStore.upsertPage(results['value'], top, skip, results['count']))
			);
	}

	accountList(accountId: string) {
	  return this.http.get(`/api/account/${accountId}/enrollment-overview`)
      .pipe(map(results => results['value']));
  }
}
