import {Component, Inject} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Action} from "../../../../model/action.model";
import {EventTypeQuery} from "../../../../store/event-type.store";
import {EventTypeService} from "../../../../services/event-type/event-type.service";

@Component({
  templateUrl: './edit-action.component.html',
  styleUrls: ['./edit-action.component.scss']
})
export class EditActionComponent {
  eventTypes$ = this.eventTypeQuery.selectAll();
  id = 'new';

  eventTypeId = new FormControl('', [Validators.required]);
  dueDate = new FormControl(new Date(), [Validators.required]);
  note = new FormControl('', [Validators.required]);

  constructor(
    private dialogRef: MatDialogRef<EditActionComponent>,
    private eventTypeQuery: EventTypeQuery,
    private eventTypeService: EventTypeService,
    @Inject(MAT_DIALOG_DATA) private data){
      if (data.action) {
        this.dueDate.setValue(data.action.dueDate);
        this.eventTypeId.setValue(data.action.eventTypeId);
        this.note.setValue(data.action.note)
        this.id = data.action.id;
      }
      this.eventTypeService.list(0, 1000).subscribe();
  }

  save() {
    const date = new Date(Date.parse(this.dueDate.value));
    this.dialogRef.close(<Action> {
      id: this.id,
      note: this.note.value,
      dueDate: date,
      eventTypeId: this.eventTypeId.value
    });
  }
}
