<div class="container" *ngIf="items.length > 0 && items[selectedIndex]">
    <div
            class="btn-round"
            [ngClass]="{selected: i === selectedIndex}"
            *ngFor="let item of items; let i= index"
            (click)="buttonClick(i)">
        <span>{{i+1}}</span>
    </div>
    <div [innerHTML]="items[selectedIndex].data.question | safeHtml" class="question"></div>
    <div *ngIf="items[selectedIndex].data.audios" class="audio">
        <lomas-audio-player [source]="audio.source" [label]="audio.label" *ngFor="let audio of items[selectedIndex].data.audios"></lomas-audio-player>
    </div>
    <div *ngIf="items[selectedIndex].data.type === 'mc'">
        <mat-radio-group class="example-radio-group" [(ngModel)]="selectedAnswer">
            <mat-radio-button class="example-radio-button" *ngFor="let answer of items[selectedIndex].data.answers" [value]="answer">
                {{answer}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <button mat-raised-button (click)="showExplanation()" color="primary">Toon antwoord/uitleg</button>
    <div *ngIf="explanationShown" [innerHTML]="items[selectedIndex].data.explanation | safeHtml" class="explanation"></div>
    <div *ngIf="items[selectedIndex].data.type === 'mc' && explanationShown">
        <div *ngIf="answerCorrect()">
            <mat-icon>done_outline</mat-icon>
            Correct!
        </div>
        <div *ngIf="!answerCorrect()">
            <mat-icon>close</mat-icon>
            Onjuist, het correcte antwoord is
            <strong>{{items[selectedIndex].data.answers[items[selectedIndex].data.correct]}}</strong>
        </div>
    </div>
</div>