import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/internal/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import { AccountService } from '../account/account.service';
import { ActionStore } from "../../store/action.store";
import { Action } from "../../model/action.model";
import {EventTypeService} from "../event-type/event-type.service";
import {ID} from "@datorama/akita";

@Injectable({
  providedIn: 'root'
}	)
export class ActionService {
  constructor(private http: HttpClient,
              private actionStore: ActionStore,
              private accountService: AccountService,
              private eventTypeService: EventTypeService,) {}

  list(top: number, skip: number, accountId = '', date = '') {
    const parent = accountId != '' ? `/account/${accountId}` : '';
    const dateQuery = date !== '' ? `&date=${date}` : '';

    return this.http.get(`/api${parent}/action/?$top=${top}&$skip=${skip}${dateQuery}`)
      .pipe(
        tap(results => {
          const data = results['value'];
          this.actionStore.set(data);
        }),
        switchMap(results => {
          const createAccountIds = results['value']
            .map(action => action.createAccountId)
            .filter((value, index, self) =>  self.indexOf(value) === index);

          const completeAccountIds = results['value']
            .map(action => action.createAccountId)
            .filter((value, index, self) =>  self.indexOf(value) === index);
          return this.accountService.list(createAccountIds.concat(completeAccountIds))
            .pipe(map(inner => results));
        }),
        map(results => results['count']));
  }

  open() {
    let params = new HttpParams();
    params = params.set('dueOnly', String(true));

    return this.http.get('/api/action', {params: params})
      .pipe(map(results => results['count']));
  }

  overview(top: number, skip: number) {
    return this.http.get(`/api/action/?$top=${top}&$skip=${skip}`)
      .pipe(
        tap(results => {
          this.actionStore.upsertPage(results['value'], top, skip, results['count']);
        }),
        switchMap(results => {
          const eventTypeIds = results['value']
            .map(event => event.eventTypeId)
            .filter((value, index, self) => self.indexOf(value) === index);
          return this.eventTypeService.listByIds(eventTypeIds)
            .pipe(map(inner => results))
        }),
        switchMap(results => {
          const accountIds = results['value']
            .map(action => action.accountId)
            .filter((value, index, self) => self.indexOf(value) === index);

          const createAccountIds = results['value']
            .map(action => action.createAccountId)
            .filter((value, index, self) => self.indexOf(value) === index);

          const completeAccountIds = results['value']
            .map(action => action.completeAccountId)
            .filter((value, index, self) => self.indexOf(value) === index);

          return this.accountService.list(accountIds.concat(createAccountIds, completeAccountIds))
            .pipe(map(inner => results))

        })
      );
  }

  save(action: Action) {
    if (action.id === 'new') {
      return this.http.post('/api/action', action);
    } else {
      return this.http.put(`/api/action/${action.id}`, action);
    }
  }

  complete(id: ID) {
    return this.http.put(`/api/action/${id}`, {completed: true});
  }
}
