import { Component } from '@angular/core';
import { StateQuery } from '../../store/state.store';

@Component({
  templateUrl: './admin-outlet.component.html',
  styleUrls: ['./admin-outlet.component.scss']
})
export class AdminOutletComponent {
  constructor( private stateQuery: StateQuery) {}

  state$ = this.stateQuery.state$;

  sideNavClosed = () => this.stateQuery.closeSideNav();

}

