import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { EventTypeQuery } from '../../../../store/event-type.store';
import { FormControl, Validators } from '@angular/forms';
import { EventTypeService } from '../../../../services/event-type/event-type.service';
import { Event } from'../../../../model/event.model';

@Component({
	templateUrl: './edit-event.component.html',
	styleUrls: ['./edit-event.component.scss']
})
export class EditEventComponent {
	eventTypes$ = this.eventTypeQuery.selectAll();

	eventTypeId = new FormControl('', [Validators.required]);
	note = new FormControl('', [Validators.required]);

	constructor(
		private dialogRef: MatDialogRef<EditEventComponent>,
		@Inject(MAT_DIALOG_DATA) private data,
		private eventTypeQuery: EventTypeQuery,
		private eventTypeService: EventTypeService){
			this.eventTypeService.list(0, 1000).subscribe();
	}

	save() {
		this.dialogRef.close(<Event> {id: 'new', eventTypeId: this.eventTypeId.value, note: this.note.value});
	}
}