import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';
import { CourseStore } from '../../store/course.store';
import { Course } from '../../model/course.model';

@Injectable()
export class CourseService {
	constructor(private http: HttpClient,
				private courseStore: CourseStore) {}

	list() {
		return this.http
			.get('/api/course')
			.pipe(map(results => this.courseStore.set(results['value'])));
	}

	create() {
		return this.http.post('/api/licensecode', {duration: 60, courseId: 'course', fullName: 'ikke'})
	}
}