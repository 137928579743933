import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/internal/operators';
import { CourseEnrollmentQuery, CourseEnrollmentStore } from '../../store/course-enrollment.store';
import { PublicCourseService } from '../public-course/public-course.service';
import {CourseEnrollment, createCourseEnrollment} from "../../model/course-enrollment.model";
import {Observable} from "rxjs";

@Injectable()
export class CourseEnrollmentService {
	constructor(private http: HttpClient,
				private courseEnrollmentStore: CourseEnrollmentStore,
				private courseEnrollmentQuery: CourseEnrollmentQuery) {}

	list() {
		return this.http
			.get('/api/courseenrollment')
			.pipe(map(res => this.courseEnrollmentStore.set(res['value'].map(row => createCourseEnrollment(row)))));
	}

	get(id) {
		return this.http
			.get(`/api/courseenrollment/${id}`)
	}

	content(courseId, id) {
		return this.http.get(`/api/courseenrollment/${courseId}/content/${id}`)
	}

	updateProgress(courseId, partId, itemId?, action?, value?) {
		return this.http
			.put(`/api/courseenrollment/${courseId}/progress`, {partId, itemId, action, value})
			.pipe(map(results =>  {
				this.courseEnrollmentQuery.sel();
				return results['value']
				//this.courseEnrollmentStore.updateProgress(courseId, results['value'])
			}));
	}

	updateLtiProgress(courseId) {
		return this.http
			.put(`/api/courseenrollment/${courseId}/progress`, {action: 'L'})
			.pipe(map(results =>  {
				this.courseEnrollmentQuery.sel();
				return results['value']
			}));
	}

	addCourse(courseId: string) {
		return this.http.post(`/api/publiccourse`, {courseId: courseId})
			.pipe(map(() => this.list().subscribe()))
	}

	addCurriculum(curriculumId: string) {
		return this.http.post(`/api/publiccourse`, {curriculumId: curriculumId})
			.pipe(map(() => this.list().subscribe()))
	}
}
