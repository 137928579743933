<h2 mat-dialog-title>Bevestigen uitschrijven</h2>
<mat-dialog-content>
    <p>
Je staat op het punt {{data.account?.firstName}} {{data.account?.preposition}} {{data.account?.surName}}
        uit te schrijven voor de nieuwsbrief
    </p>

</mat-dialog-content>
<mat-dialog-actions >
    <button mat-button mat-dialog-close>Annuleren</button>
    <button mat-raised-button (click)="ok()" color="primary" >Ok</button>
</mat-dialog-actions>