
<mat-sidenav-content class="page">
  <lomas-admin-toolbar title="Cursus wijzigen"></lomas-admin-toolbar>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Cursus wijzigen</mat-card-title>
      <span class="flex-title"></span>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <form [formGroup]="courseForm" (submit)="save()">
        <mat-form-field>
          <input matInput formControlName="name" placeholder="Naam" />
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="url" placeholder="URL" />
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="key" placeholder="Key" />
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="secret" placeholder="Secret" />
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="custom" placeholder="Custom" />
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="resourceLinkId" placeholder="Resource Link ID" />
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="thumbnail" placeholder="URL afbeelding" />
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit" [disabled]="courseForm.status === 'INVALID'">Opslaan</button>
      </form>
    </mat-card-content>
    <button mat-raised-button *ngIf="id !== 'new'" (click)="toggleLaunch()">Lanceer cursus</button>
    <div *ngIf="showCourse" style="height: 600px;">
      <iframe  [src]="src" style="width: 100%; height: 100%; border: 0"></iframe>
    </div>
  </mat-card>
</mat-sidenav-content>

