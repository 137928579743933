<h2 mat-dialog-title>Toevoegen abonnee</h2>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field class="full-width">
            <input matInput placeholder="Voornaam" formControlName="firstName"/>
        </mat-form-field>
        <mat-form-field class="full-width">
            <input matInput placeholder="Tussenvoegsel" formControlName="preposition"/>
        </mat-form-field>
        <mat-form-field class="full-width">
            <input matInput placeholder="Achternaam" formControlName="surName"/>
        </mat-form-field>
        <mat-form-field class="full-width">
            <input matInput placeholder="E-mailadres" formControlName="email"/>
        </mat-form-field>
    </form>


</mat-dialog-content>
<mat-dialog-actions >
    <button mat-button mat-dialog-close>Annuleren</button>
    <button mat-raised-button (click)="save()" color="primary" [disabled]="form.invalid">Opslaan</button>
</mat-dialog-actions>