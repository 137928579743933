import { Component } from '@angular/core';
import { Account } from '../../../model/account.model';
import { debounce } from 'rxjs/internal/operators';
import { MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { timer } from 'rxjs/index';
import { AddSubscriberComponent } from '../dialogs/add-subscriber/add-subscriber.component';
import { FormControl } from '@angular/forms';
import { EventTypeQuery } from '../../../store/event-type.store';
import { EventTypeService } from '../../../services/event-type/event-type.service';
import { EventType } from '../../../model/event-type.model';
import { EditEventTypeComponent } from '../dialogs/edit-event-type/edit-event-type.component';

@Component({
	templateUrl: './event-type.component.html',
	styleUrls: ['./event-type.component.scss']
})
export class EventTypeComponent {
	displayedColumns: string[] = ['name'];
	size = 25;
	pageIndex = 0;
	total: number;
	eventTypes$ = this.eventTypeQuery.selectAll();
	searchText = new FormControl('');

	constructor(private eventTypeQuery: EventTypeQuery,
				private eventTypeService: EventTypeService,
				private dialog: MatDialog,
				private snackBar: MatSnackBar)
	{}

	ngOnInit() {
		this.loadData();
		this.searchText.valueChanges
			.pipe(debounce(() => timer(300)))
			.subscribe(() => this.loadData())

	}

	loadData() {
		this.eventTypeService.list(this.pageIndex * this.size, this.size, this.searchText.value)
			.subscribe();
	}

	pageChange(event: PageEvent) {
		this.size = event.pageSize;
		this.pageIndex = event.pageIndex;
		this.loadData();
	}

	add() {
		this.dialog.open(EditEventTypeComponent, {
			width: '500px',
			height: '300px',
			data: {id: 'new'}
		}).afterClosed().subscribe(result => {
			if (result) {
				this.eventTypeService.save(<EventType> result).subscribe(() => {
					this.snackBar.open('Type gebeurtenis opgeslagen', 'ok', {duration: 4000});
					this.loadData();
				},() => {
					this.snackBar.open('Type gebeurtenis bestaat al', 'ok', {duration: 4000});
				});
			}
		});
	}
}