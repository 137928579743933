import {Component, OnInit} from "@angular/core";
import {CourseService} from "../../../model/course/course.service";
import {CourseQuery} from "../../../model/course/course.query";
import {Course} from "../../../model/course/course.model";
import {Observable, Subscription} from "rxjs";
import {filter, map, tap} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  courses$: Observable<Course[]>;
  displayedColumns: string[] = ['name', 'actions'];

  constructor(
    private courseService: CourseService,
    private courseQuery: CourseQuery,
    private router: Router,
    private route: ActivatedRoute,
  ){}

  ngOnInit() {
    this.courseService.list();
    this.courses$ = this.courseQuery.selectAll()
      .pipe(map(courses => courses.filter(course => course.ltiLaunch)));
  }

  editCourse(id: string){
    this.router.navigate([id], {relativeTo: this.route});
  }

  add() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }
}
