import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { EventTypeQuery, EventTypeStore } from '../../store/event-type.store';
import { EventType } from '../../model/event-type.model';
import { of } from 'rxjs/index';

@Injectable({
	providedIn: 'root'
}	)
export class EventTypeService {
	constructor(private http: HttpClient,
				private eventTypeStore: EventTypeStore,
				private eventTypeQuery: EventTypeQuery) {}

	list(top: number, skip: number, search = '') {
		return this.http.get(`/api/event-type/?$top=${top}&$skip=${skip}&search=${search}`)
			.pipe(
				tap(results => {
					const data = results['value'];

					this.eventTypeStore.set(data)
				}),
				map(results => results['count']));
	}

	listByIds(ids: string[]) {
		const uniqueIds = ids
			.filter((id, index, self) => self[index] === id)
			.filter(id => !this.eventTypeQuery.getEntity(id));

		if (uniqueIds.length === 0) {
			return of([]);
		}

		return this.http.get(`/api/event-type?ids=${ids}`)
			.pipe(map(results => {
				results['value'].forEach(event => this.eventTypeStore.upsert(event.id, event));
				return [];
			}))
	}

	save(eventType: EventType) {
		if (eventType.id === 'new') {
			return this.http.post('/api/event-type', eventType);
		} else {
			return this.http.put(`/api/event-type/${eventType.id}`, eventType);
		}

	}


}