import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'lomas-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    loading = false;
    errorMessages = {
		NO_USER_FOUND : 'De combinatie van e-mailadres en password is onjuist.',
		USER_INACTIVE: 'Dit account is op inactief gezet.'
    };

    message = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {}

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required] )
  });

  submit() {
    const nextUrl = this.activatedRoute.snapshot.queryParamMap.get('next');
    this.authService.logout();
    this.loading = true;
    this.authService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(
      (res) => {
          this.loading = false;
        const payload = this.authService.getPayload();

        if (nextUrl) {
            this.router.navigate([nextUrl]);
        } else if (payload.roles.indexOf('admin') > -1) {
          this.router.navigate(['admin']);
        } else {
          this.router.navigate(['learning'], {queryParamsHandling: "merge"});
        }

      },
      (err) => {
          this.loading = false;
          this.message = this.errorMessages[(err.error || {}).code] || 'Een onbekende fout is opgetreden.';
      }
    );
  }

  hideMessage() {
      this.message = '';
  }

  check() {
    console.log(this.authService.isAuthenticated());
  }
}
