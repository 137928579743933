/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-unsubscribe-account.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./confirm-unsubscribe-account.component";
var styles_ConfirmUnsubscribeAccountComponent = [i0.styles];
var RenderType_ConfirmUnsubscribeAccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmUnsubscribeAccountComponent, data: {} });
export { RenderType_ConfirmUnsubscribeAccountComponent as RenderType_ConfirmUnsubscribeAccountComponent };
export function View_ConfirmUnsubscribeAccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Bevestigen uitschrijven"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["\nJe staat op het punt ", " ", " ", " uit te schrijven voor de nieuwsbrief "])), (_l()(), i1.ɵeld(7, 0, null, null, 8, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["mat-button", ""], ["mat-dialog-close", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(11, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Annuleren"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ok"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_7 = ""; _ck(_v, 11, 0, currVal_7); var currVal_10 = "primary"; _ck(_v, 14, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.data.account == null) ? null : _co.data.account.firstName); var currVal_2 = ((_co.data.account == null) ? null : _co.data.account.preposition); var currVal_3 = ((_co.data.account == null) ? null : _co.data.account.surName); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 10).disabled || null); var currVal_5 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_6 = i1.ɵnov(_v, 11).ariaLabel; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 14).disabled || null); var currVal_9 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_8, currVal_9); }); }
export function View_ConfirmUnsubscribeAccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ConfirmUnsubscribeAccountComponent_0, RenderType_ConfirmUnsubscribeAccountComponent)), i1.ɵdid(1, 49152, null, 0, i8.ConfirmUnsubscribeAccountComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var ConfirmUnsubscribeAccountComponentNgFactory = i1.ɵccf("ng-component", i8.ConfirmUnsubscribeAccountComponent, View_ConfirmUnsubscribeAccountComponent_Host_0, {}, {}, []);
export { ConfirmUnsubscribeAccountComponentNgFactory as ConfirmUnsubscribeAccountComponentNgFactory };
