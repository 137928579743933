import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { JWTTokenPayload } from '../../model/jwttokenpayload';
import { LicenseCodeService } from '../../services/license-code/license-code.service';
import { LicenseService } from '../../services/license/license.service';
import { StateQuery } from '../../store/state.store';
import { Router } from '@angular/router';
import { CourseService } from '../../services/course/course.service';
import { CourseEnrollmentService } from '../../services/course-enrollment/course-enrollment.service';

@Component({
  selector: 'lomas-learning-outlet',
  templateUrl: './learning-outlet.component.html',
  styleUrls: ['./learning-outlet.component.scss']
})
export class LearningOutletComponent implements OnInit {
  payload: JWTTokenPayload;

  constructor(private authService: AuthService,
              private licenseService: LicenseService,
              private courseEnrollmentService: CourseEnrollmentService,
              private stateQuery: StateQuery,
              private router: Router) {

  }

  state$ = this.stateQuery.state$;

  ngOnInit() {
    this.payload = this.authService.getPayload();
  }

  sideNavClosed(){
    this.stateQuery.closeSideNav();
  }

}
