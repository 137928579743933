import { Component, OnInit } from '@angular/core';
import { NewsletterService } from '../../../services/newsletter/newsletter.service';
import { NewsletterQuery } from '../../../store/newsletter.store';
import { PageEvent } from '@angular/material';

@Component({
	templateUrl: './newsletters.component.html',
	styleUrls: ['./newsletters.component.scss']
})
export class NewslettersComponent implements OnInit {
	displayedColumns: string[] = ['title', 'createDate', 'sendDateTime', 'actions'];
	size = 25;
	pageIndex = 0;
	total: number;
	newsletters$ = this.newsletterQuery.selectAll();

	constructor(private newsletterService: NewsletterService,
				private newsletterQuery: NewsletterQuery)
	{}

	ngOnInit() {
		this.loadData();
	}

	load(event) {
		console.log(event)
	}

	loadData() {
		this.newsletterService.list(this.pageIndex * this.size, this.size)
			.subscribe(result => this.total = result);
	}

	pageChange(event: PageEvent) {
		this.size = event.pageSize;
		this.pageIndex = event.pageIndex;
		this.loadData();
	}
}