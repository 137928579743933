<h2 mat-dialog-title>Wijzigen forum post</h2>
<mat-dialog-content>

    <mat-form-field class="answer" *ngIf="!showResult">
        <textarea matInput placeholder="Antwoord" [formControl]="answerText" ></textarea>
    </mat-form-field>

  <markdown *ngIf="showResult" [data]="answerText.value"></markdown>

</mat-dialog-content>
<mat-dialog-actions >
    <button mat-button mat-dialog-close>Annuleren</button>
    <button mat-raised-button (click)="answer()" color="primary" [disabled]="answerText.invalid">Beantwoorden</button>
  <mat-slide-toggle
    class="example-margin"
    (change)="toggleShowResult($event)"
    [checked]="showResult">
    Toon resultaat
  </mat-slide-toggle>
  <button mat-icon-button aria-label="Example icon-button with a heart icon" (click)="openDocumentation()">
    <mat-icon>help</mat-icon>
  </button>
</mat-dialog-actions>
