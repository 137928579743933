import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { Event } from '../../model/event.model';
import { EventStore } from '../../store/event.store';
import { AccountService } from '../account/account.service';
import { EventTypeService } from '../event-type/event-type.service';

@Injectable({
	providedIn: 'root'
}	)
export class EventService {
	constructor(private http: HttpClient,
				private eventStore: EventStore,
				private accountService: AccountService,
				private eventTypeService: EventTypeService) {}

	list(top: number, skip: number, accountId = '', date = '') {
		const parent = accountId != '' ? `/account/${accountId}` : '';
		const dateQuery = date !== '' ? `&date=${date}` : '';

		return this.http.get(`/api${parent}/event/?$top=${top}&$skip=${skip}${dateQuery}`)
			.pipe(
				tap(results => {
					const data = results['value'];
					this.eventStore.set(data);
				}),
				switchMap(results => {
					const adminAccountIds = results['value']
						.map(event => event.adminAccountId)
						.filter((value, index, self) =>  self.indexOf(value) === index);
					return this.accountService.list(adminAccountIds)
						.pipe(map(inner => results));
				}),
				map(results => results['count']));
	}

	overview(top: number, skip: number) {
		return this.http.get(`/api/event/?$top=${top}&$skip=${skip}`)
			.pipe(
				tap(results => {
					this.eventStore.upsertPage(results['value'], top, skip, results['count']);
				}),
				switchMap(results => {
					const eventTypeIds = results['value']
						.map(event => event.eventTypeId)
						.filter((value, index, self) => self.indexOf(value) === index);
					return this.eventTypeService.listByIds(eventTypeIds)
						.pipe(map(inner => results))
				}),
				switchMap(results => {
					const accountIds = results['value']
						.map(event => event.accountId)
						.filter((value, index, self) => self.indexOf(value) === index);

					const adminAccountId = results['value']
						.map(event => event.adminAccountId)
						.filter((value, index, self) => self.indexOf(value) === index);

					return this.accountService.list(accountIds.concat(adminAccountId))
						.pipe(map(inner => results))

				})
			);
	}

	save(event: Event) {
		if (event.id === 'new') {
			return this.http.post('/api/event', event);
		} else {
			return this.http.put(`/api/event/${event.id}`, event);
		}

	}
}
