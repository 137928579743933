export function createAccount(_a) {
    var _b = _a.id, id = _b === void 0 ? null : _b, _c = _a.firstName, firstName = _c === void 0 ? '' : _c, _d = _a.preposition, preposition = _d === void 0 ? '' : _d, _e = _a.surName, surName = _e === void 0 ? '' : _e, _f = _a.email, email = _f === void 0 ? '' : _f, _g = _a.password, password = _g === void 0 ? '' : _g;
    return {
        id: id,
        firstName: firstName,
        preposition: preposition,
        surName: surName,
        email: email,
        password: password
    };
}
