export function createCourseEnrollment(_a) {
    var _b = _a.id, id = _b === void 0 ? null : _b, _c = _a.accountId, accountId = _c === void 0 ? null : _c, _d = _a.courseId, courseId = _d === void 0 ? '' : _d, _e = _a.name, name = _e === void 0 ? '' : _e, _f = _a.progress, progress = _f === void 0 ? null : _f, _g = _a.lastUpdate, lastUpdate = _g === void 0 ? null : _g, _h = _a.publicAccessible, publicAccessible = _h === void 0 ? false : _h, _j = _a.endDate, endDate = _j === void 0 ? null : _j, _k = _a.thumbnail, thumbnail = _k === void 0 ? '' : _k;
    return {
        id: id,
        accountId: accountId,
        courseId: courseId,
        name: name,
        progress: progress,
        lastUpdate: createDate(lastUpdate),
        publicAccessible: publicAccessible,
        endDate: createDate(endDate),
        thumbnail: thumbnail,
    };
}
function createDate(dateString) {
    return dateString ? new Date(dateString) : null;
}
