import { Component, OnInit } from '@angular/core';
import { UnsubscribeService } from '../../../services/unsubscribe/unsubscribe.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	templateUrl: './unsubscribe.component.html',
	styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
	status: 'new' | 'fail' | 'success'  = 'new';

	constructor(private unsubscribeService: UnsubscribeService,
				private activatedRoute: ActivatedRoute)
	{}

	ngOnInit() {
		const id = this.activatedRoute.snapshot.queryParamMap.get('id');

		if (!id) {
			this.status = 'fail';
		} else {
			this.unsubscribeService.unsubscribe(id).subscribe(() => {
				this.status = 'success';
			}, () => {
				this.status = 'fail';
			})
		}
	}
}