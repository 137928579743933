import { map, switchMap, tap } from 'rxjs/internal/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AccountService } from '../account/account.service';
import { ActionStore } from "../../store/action.store";
import { EventTypeService } from "../event-type/event-type.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../store/action.store";
import * as i3 from "../account/account.service";
import * as i4 from "../event-type/event-type.service";
var ActionService = /** @class */ (function () {
    function ActionService(http, actionStore, accountService, eventTypeService) {
        this.http = http;
        this.actionStore = actionStore;
        this.accountService = accountService;
        this.eventTypeService = eventTypeService;
    }
    ActionService.prototype.list = function (top, skip, accountId, date) {
        var _this = this;
        if (accountId === void 0) { accountId = ''; }
        if (date === void 0) { date = ''; }
        var parent = accountId != '' ? "/account/" + accountId : '';
        var dateQuery = date !== '' ? "&date=" + date : '';
        return this.http.get("/api" + parent + "/action/?$top=" + top + "&$skip=" + skip + dateQuery)
            .pipe(tap(function (results) {
            var data = results['value'];
            _this.actionStore.set(data);
        }), switchMap(function (results) {
            var createAccountIds = results['value']
                .map(function (action) { return action.createAccountId; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            var completeAccountIds = results['value']
                .map(function (action) { return action.createAccountId; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            return _this.accountService.list(createAccountIds.concat(completeAccountIds))
                .pipe(map(function (inner) { return results; }));
        }), map(function (results) { return results['count']; }));
    };
    ActionService.prototype.open = function () {
        var params = new HttpParams();
        params = params.set('dueOnly', String(true));
        return this.http.get('/api/action', { params: params })
            .pipe(map(function (results) { return results['count']; }));
    };
    ActionService.prototype.overview = function (top, skip) {
        var _this = this;
        return this.http.get("/api/action/?$top=" + top + "&$skip=" + skip)
            .pipe(tap(function (results) {
            _this.actionStore.upsertPage(results['value'], top, skip, results['count']);
        }), switchMap(function (results) {
            var eventTypeIds = results['value']
                .map(function (event) { return event.eventTypeId; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            return _this.eventTypeService.listByIds(eventTypeIds)
                .pipe(map(function (inner) { return results; }));
        }), switchMap(function (results) {
            var accountIds = results['value']
                .map(function (action) { return action.accountId; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            var createAccountIds = results['value']
                .map(function (action) { return action.createAccountId; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            var completeAccountIds = results['value']
                .map(function (action) { return action.completeAccountId; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            return _this.accountService.list(accountIds.concat(createAccountIds, completeAccountIds))
                .pipe(map(function (inner) { return results; }));
        }));
    };
    ActionService.prototype.save = function (action) {
        if (action.id === 'new') {
            return this.http.post('/api/action', action);
        }
        else {
            return this.http.put("/api/action/" + action.id, action);
        }
    };
    ActionService.prototype.complete = function (id) {
        return this.http.put("/api/action/" + id, { completed: true });
    };
    ActionService.ngInjectableDef = i0.defineInjectable({ factory: function ActionService_Factory() { return new ActionService(i0.inject(i1.HttpClient), i0.inject(i2.ActionStore), i0.inject(i3.AccountService), i0.inject(i4.EventTypeService)); }, token: ActionService, providedIn: "root" });
    return ActionService;
}());
export { ActionService };
