<mat-sidenav-content class="page">
    <lomas-student-toolbar title="Calogus proeflessen"></lomas-student-toolbar>
    <div>
        <mat-card *ngIf="(enrollableCourses$  | async).length === 0">
            <mat-card-title></mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content>
                <p>
                    Er zijn geen proeflessen meer die je kunt volgen.
                </p>
                <p>
                    <a [routerLink]="['/learning']">Terug naar home</a>
                </p>
            </mat-card-content>
        </mat-card>
        <mat-card *ngFor="let course of enrollableCourses$  | async">
            <mat-card-title>{{course.name}}</mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content>
                <a (click)="startCourse(course)">
                    <img [src]="course.thumbnail" width="300"/>
                    <button mat-raised-button color="primary">Start proefles</button>
                </a>
            </mat-card-content>
        </mat-card>
    </div>
</mat-sidenav-content>
