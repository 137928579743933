/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./settings.component";
var styles_AdminSettingsComponent = [];
var RenderType_AdminSettingsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminSettingsComponent, data: {} });
export { RenderType_AdminSettingsComponent as RenderType_AdminSettingsComponent };
export function View_AdminSettingsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Settings"]))], null, null); }
export function View_AdminSettingsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lomas-admin-settings-page", [], null, null, null, View_AdminSettingsComponent_0, RenderType_AdminSettingsComponent)), i0.ɵdid(1, 49152, null, 0, i1.AdminSettingsComponent, [], null, null)], null, null); }
var AdminSettingsComponentNgFactory = i0.ɵccf("lomas-admin-settings-page", i1.AdminSettingsComponent, View_AdminSettingsComponent_Host_0, {}, {}, []);
export { AdminSettingsComponentNgFactory as AdminSettingsComponentNgFactory };
