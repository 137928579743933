import { HttpClient } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/internal/operators';
import { CourseService } from '../course/course.service';
import { EnrollmentOverviewStore } from '../../store/enrollment-overview.store';
import { AccountService } from '../account/account.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../course/course.service";
import * as i3 from "../account/account.service";
import * as i4 from "../../store/enrollment-overview.store";
var EnrollmentOverviewService = /** @class */ (function () {
    function EnrollmentOverviewService(http, courseService, accountService, enrollmentOverviewStore) {
        this.http = http;
        this.courseService = courseService;
        this.accountService = accountService;
        this.enrollmentOverviewStore = enrollmentOverviewStore;
    }
    EnrollmentOverviewService.prototype.list = function (top, skip) {
        var _this = this;
        return this.http.get("/api/enrollment-overview?&$top=" + top + "&$skip=" + skip + "&publicAccessible=true")
            .pipe(switchMap(function (results) { return _this.courseService.list().map(function (inner) { return results; }); }), switchMap(function (results) {
            var courseIds = results['value'].map(function (enrollment) { return enrollment.accountId; });
            return _this.accountService.list(courseIds)
                .pipe(map(function (inner) { return results; }));
        }), tap(function (results) { return _this.enrollmentOverviewStore.upsertPage(results['value'], top, skip, results['count']); }));
    };
    EnrollmentOverviewService.prototype.accountList = function (accountId) {
        return this.http.get("/api/account/" + accountId + "/enrollment-overview")
            .pipe(map(function (results) { return results['value']; }));
    };
    EnrollmentOverviewService.ngInjectableDef = i0.defineInjectable({ factory: function EnrollmentOverviewService_Factory() { return new EnrollmentOverviewService(i0.inject(i1.HttpClient), i0.inject(i2.CourseService), i0.inject(i3.AccountService), i0.inject(i4.EnrollmentOverviewStore)); }, token: EnrollmentOverviewService, providedIn: "root" });
    return EnrollmentOverviewService;
}());
export { EnrollmentOverviewService };
