import { Injectable } from '@angular/core';
import { IAppState } from '../store';
import { NgRedux } from '@angular-redux/store';

@Injectable()
export class CounterActions {
  constructor(private ngRedux: NgRedux<IAppState>) {}

  add(number: number) {
    this.ngRedux.dispatch({type:'toggleFetching'});
    setTimeout(() => {this.ngRedux.dispatch({type: 'add', payload: number})}, 2000)

  }
}
