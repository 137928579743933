import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map} from "rxjs/operators";

@Injectable()
export class LicenseService {
	constructor(private http: HttpClient) {}

	list(month: number, year: number) {
	  const startDate = this.getPaddedDay(new Date(year, month, 1));
	  const endDate = this.getPaddedDay(new Date(year, month + 1, 0));

	  return this.http.get('/api/license', {params: {startDate, endDate}})
      .pipe(map((data) => data['value']));
  }

	activate(code) {
		return this.http.post('/api/license', {code: code});
	}

  private getPaddedDay(date: Date) {
	  const day = `0${date.getDate()}`.substr(-2);
    const month = `0${date.getMonth()+1}`.substr(-2);

    return `${date.getFullYear()}-${month}-${day}`
  }
}
