<div *ngIf="showPost()" [ngStyle]="{'padding-left': getDepth()}">
    <h3>{{blog.fullName}} - {{blog.createDateTime | date: 'dd MMMM yyyy - HH:mm'}}</h3>
    <markdown [data]="blog.message"></markdown>
    <button mat-button (click)="handleAddPost(blog)">
        <mat-icon>reply</mat-icon>Reageren
    </button>
    <button mat-button (click)="handleEditPost(blog)" *ngIf="editAllowed()">
        <mat-icon>edit</mat-icon>Wijzigen
    </button>

    <lomas-blog-post
            *ngFor="let child of children"
            [depth]="depth + 1"
            [children]="children"
            [blog]="child"
            [courseId]="courseId"
            [partId]="partId"
            [parentId]="blog.id"
            [threadId]="threadId"
            (addPost)="handleAddPost($event)"
            (editPost)="handleEditPost($event)"
    ></lomas-blog-post>
</div>