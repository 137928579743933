/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-blog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./blog-post/blog-post.component.ngfactory";
import * as i3 from "./blog-post/blog-post.component";
import * as i4 from "../../../../services/auth/auth.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./view-blog.component";
import * as i12 from "@angular/material/dialog";
import * as i13 from "../../../../services/blog/blog.service";
import * as i14 from "../../../../store/blog.store";
var styles_ViewBlogComponent = [i0.styles];
var RenderType_ViewBlogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewBlogComponent, data: {} });
export { RenderType_ViewBlogComponent as RenderType_ViewBlogComponent };
function View_ViewBlogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "no-blogs"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Er zijn nog geen vragen bij deze paragraaf. "]))], null, null); }
function View_ViewBlogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "lomas-blog-post", [], null, [[null, "addPost"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addPost" === en)) {
        var pd_0 = (_co.addBlogpost($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BlogPostComponent_0, i2.RenderType_BlogPostComponent)), i1.ɵdid(1, 49152, null, 0, i3.BlogPostComponent, [i4.AuthService], { depth: [0, "depth"], children: [1, "children"], blog: [2, "blog"], courseId: [3, "courseId"], partId: [4, "partId"], threadId: [5, "threadId"] }, { addPost: "addPost" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = 0; var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_co.blogs$)); var currVal_2 = _v.context.$implicit; var currVal_3 = _co.courseId; var currVal_4 = _co.partId; var currVal_5 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_ViewBlogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "viewer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ViewBlogComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addBlogpost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Stel een vraag"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeBlog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Terug naar de cursus"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ViewBlogComponent_2)), i1.ɵdid(11, 802816, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.blogs$)).length === 0); _ck(_v, 2, 0, currVal_0); var currVal_3 = "primary"; _ck(_v, 5, 0, currVal_3); var currVal_6 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.blogs$)); _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); var currVal_2 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = (i1.ɵnov(_v, 8).disabled || null); var currVal_5 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_4, currVal_5); }); }
export function View_ViewBlogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lomas-view-blog", [], null, null, null, View_ViewBlogComponent_0, RenderType_ViewBlogComponent)), i1.ɵdid(1, 49152, null, 0, i11.ViewBlogComponent, [i12.MatDialog, i13.BlogService, i14.BlogQuery], null, null)], null, null); }
var ViewBlogComponentNgFactory = i1.ɵccf("lomas-view-blog", i11.ViewBlogComponent, View_ViewBlogComponent_Host_0, { courseId: "courseId", partId: "partId" }, { close: "close" }, []);
export { ViewBlogComponentNgFactory as ViewBlogComponentNgFactory };
