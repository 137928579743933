import { Component, OnInit } from '@angular/core';
import { NewsletterWebService } from '../../services/newsletter-web/newsletter-web.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	templateUrl: './newsletter-web.component.html',
	styleUrls: ['./newsletter-web.component.scss']
})
export class NewsletterWebComponent implements OnInit {

	content = 'loading....';
	constructor(private newsletterWebService: NewsletterWebService,
				private route: ActivatedRoute){

	}

	ngOnInit() {
		const newsletterId = this.route.snapshot.paramMap.get('newsletterId');
		const accountId = this.route.snapshot.queryParamMap.get('accountId');
		this.newsletterWebService.get(newsletterId, accountId).subscribe(
			result => this.content = result.content,
			() => this.content = 'Er is iets misgegaan met het tonen van de nieuwsbrief.');
	}
}