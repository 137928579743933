import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { EnrollmentOverviewService } from '../../../services/enrollment-overview/enrollment-overview.service';
import { EnrollmentOverviewQuery } from '../../../store/enrollment-overview.store';

@Injectable({
	providedIn: 'root'
})
export class CourseEnrollmentsResolver implements Resolve<Observable<boolean>> {
	constructor(
		private enrollmentOverviewService: EnrollmentOverviewService,
		private enrollmentOverviewQuery: EnrollmentOverviewQuery)
	{}

	resolve() {
		return Observable.create(observer => {
			const size = this.enrollmentOverviewQuery.getSize();
			const pageIndex = this.enrollmentOverviewQuery.getPageIndex();

			this.enrollmentOverviewService.list( size, pageIndex)
				.subscribe(() => {
					observer.next(true);
					observer.complete();
				}, () => {
					observer.next(false);
					observer.complete();
				})
		});
	}
}