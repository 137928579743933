import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {createReseller, Reseller} from "../model/reseller.model";

const initialState: Reseller = createReseller({});

export interface ResellerState extends EntityState<Reseller> {}

@StoreConfig({name: 'reseller'})
@Injectable({providedIn: 'root'})
export class ResellerStore extends EntityStore<ResellerState, Reseller> {
  constructor() {
    super(initialState);
  }
}

@Injectable({providedIn: 'root'})
export class ResellerQuery extends QueryEntity<ResellerState, Reseller> {
  constructor(protected store: ResellerStore) {
    super(store);
  }
}
