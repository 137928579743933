import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddBlogpostComponent } from '../../dialogs/add-blogpost/add-blogpost.component';
import { MatDialog } from '@angular/material';
import { BlogService } from '../../../../services/blog/blog.service';
import { BlogQuery } from '../../../../store/blog.store';
import { map } from 'rxjs/internal/operators';
import { Blog } from '../../../../model/blog.model';

@Component({
	selector: 'lomas-view-blog',
	templateUrl: './view-blog.component.html',
	styleUrls: ['./view-blog.component.scss']
})
export class ViewBlogComponent {
	@Input() courseId: string;
	@Input() partId: string;
	@Output() close = new EventEmitter();

	blogs$ = this.blogQuery.selectAll().pipe(map(blogs => blogs.filter(blog => blog.partId === this.partId)));

	constructor(private dialog: MatDialog,
				private blogService: BlogService,
				private blogQuery: BlogQuery) {}

	addBlogpost(replyBlog?: Blog) {
		const replyId = (replyBlog || {id: null}).id;
		this.dialog.open(AddBlogpostComponent, {
			width: '800px',
			height: '560px',

		}).afterClosed().subscribe(result => {
				if (result) {
					this.blogService.save({...result, ...{courseId: this.courseId, partId: this.partId, replyId: replyId}}).subscribe();
				}

		});
	}

	closeBlog() {
		this.close.emit();
	}
}