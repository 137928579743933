<mat-sidenav-content class="page">
    <lomas-student-toolbar title="Home"></lomas-student-toolbar>
    <div>
    <mat-card>
        <mat-card-title>Toegangscode</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            Heb je een toegangscode? Voer die hieronder in:
            <mat-form-field class="full-width">
                <input matInput [formControl]="licenseCode" placeholder="Toegangscode"/>
            </mat-form-field>
            <button mat-raised-button color="primary"
                    [disabled]="codeLoading || licenseCode.value === ''"
                    (click)="activate()">Versturen</button>
            <span class="spinner" *ngIf="codeLoading">
                <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
            </span>
            <p>
                <a [routerLink]="['/learning/catalog']">Of bekijk de gratis proeflessen</a>
            </p>
        </mat-card-content>
    </mat-card>
    <mat-card *ngFor="let enrollment of courseEnrollment$ | async">
        <mat-card-title>{{enrollment.name}}</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content class="course">
            <div class="thumbnail">
                <img [src]="enrollment.thumbnail" width="100">
            </div>
            <div>
              <div *ngIf="isAccessible(enrollment)">
                <div class="description" *ngIf="enrollment.progress === null">
                  <div >Nieuw</div>

                </div>
                <div class="description" *ngIf="enrollment.progress !== null">
                  De laatste keer bekeken: <div ><strong>{{enrollment.lastUpdate | date: 'dd-MM-yyyy'}}</strong></div>

                </div>
                <div class="description" *ngIf="enrollment.endDate">
                  Toegang verloopt na: <div><strong>{{enrollment.endDate | date: 'dd-MM-yyyy'}}</strong></div>
                </div>
              </div>
              <div>
                <button mat-raised-button *ngIf="enrollment.progress === null" color="primary"[routerLink]="['/learning/course-viewer', enrollment.courseId]">Start cursus</button>
                <button mat-raised-button *ngIf="enrollment.progress !== null && isAccessible(enrollment)" color="primary"  [routerLink]="['/learning/course-viewer', enrollment.courseId]">Vervolg cursus</button>
              </div>
            </div>
            <div *ngIf="!isAccessible(enrollment)" class="description no-access">
              Deze cursus is niet meer toegankelijk
            </div>
        </mat-card-content>
    </mat-card>
    </div>
</mat-sidenav-content>
