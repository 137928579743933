import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { createState, State } from '../model/state.model';

const initialState: State = createState({});

@StoreConfig({name: 'state'})
export class StateStore extends Store<State> {
	constructor() {
		super(initialState);
	}
}

@Injectable({
	providedIn: 'root'
})
export class StateQuery extends Query<State> {
	state$ = this.select(state => state);

	constructor(protected store: StateStore) {
		super(store);
	}

	openSideNav = () => this.store.update({sideNavOpened: true});
	closeSideNav = () => this.store.update({sideNavOpened: false});
}
