<h2 mat-dialog-title>Reseller</h2>
<mat-dialog-content [formGroup]="formGroup">
  <mat-form-field class="full-width">
    <input matInput placeholder="Naam" formControlName="name"/>
  </mat-form-field>
  <mat-form-field class="full-width">
    <input matInput placeholder="Key" formControlName="ltiKey"/>
  </mat-form-field>
  <mat-form-field class="full-width">
    <input matInput placeholder="Secret" formControlName="ltiSecret" />
  </mat-form-field>
  <mat-checkbox formControlName="active">actief</mat-checkbox>
  <div>
    <button mat-raised-button (click)="resetLtiSecret()">Wijzig secret</button>
  </div>
  <br />
</mat-dialog-content>
<mat-dialog-actions >
  <button mat-button mat-dialog-close>Annuleren</button>
  <button mat-raised-button (click)="save()" color="primary" [disabled]="formGroup.invalid">Opslaan</button>
</mat-dialog-actions>
