import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';

@Injectable({
	providedIn: 'root'
})
export class NewsletterWebService {
	constructor(private http: HttpClient){}

	get(id, accountId?) {
		return this.http
			.get(`/api/newsletter-web/${id}?accountId=${accountId}`)
			.pipe(map(result => result['value']));
	}
}