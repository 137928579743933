import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/internal/operators';
import { NewsletterStore } from '../../store/newsletter.store';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../store/newsletter.store";
var NewsletterService = /** @class */ (function () {
    function NewsletterService(http, newsletterStore) {
        this.http = http;
        this.newsletterStore = newsletterStore;
    }
    NewsletterService.prototype.list = function (top, skip) {
        var _this = this;
        return this.http.get("/api/newsletter/?&$top=" + top + "&$skip=" + skip)
            .pipe(tap(function (results) { return _this.newsletterStore.set(results['value']); }), map(function (results) { return results['count']; }));
    };
    NewsletterService.prototype.get = function (id) {
        return this.http.get("/api/newsletter/" + id)
            .pipe(map(function (result) { return result['value']; }));
    };
    NewsletterService.prototype.getBatch = function (id, batchIndex) {
        return this.http.get("/api/newsletter/" + id + "/" + batchIndex)
            .pipe(map(function (result) { return result['value']; }));
    };
    NewsletterService.prototype.resend = function (id, batchIndex) {
        return this.http.put("/api/newsletter/resend/" + id, { batchIndex: batchIndex });
    };
    NewsletterService.prototype.save = function (newsletter) {
        return newsletter.id === 'new' ?
            this.http.post('/api/newsletter', newsletter, { observe: 'response' })
                .pipe(map(function (resp) {
                var location = resp.headers.get('location');
                return location.substr(location.lastIndexOf('/') + 1);
            })) :
            this.http.put("/api/newsletter/" + newsletter.id, newsletter)
                .pipe(map(function () { return newsletter.id; }));
    };
    NewsletterService.prototype.preview = function (id, emails) {
        return this.http.put("/api/newsletter/preview/" + id, { emails: emails });
    };
    NewsletterService.prototype.send = function (id) {
        return this.http.put("/api/newsletter/send/" + id, {});
    };
    NewsletterService.ngInjectableDef = i0.defineInjectable({ factory: function NewsletterService_Factory() { return new NewsletterService(i0.inject(i1.HttpClient), i0.inject(i2.NewsletterStore)); }, token: NewsletterService, providedIn: "root" });
    return NewsletterService;
}());
export { NewsletterService };
