import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AnswerPostComponent } from '../answer-post/answer-post.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
	templateUrl: './edit-answer.component.html',
	styleUrls: ['./edit-answer.component.scss']
})
export class EditAnswerComponent {
	answerText = new FormControl('', [Validators.required]);
  showResult = false;

	constructor(public dialogRef: MatDialogRef<AnswerPostComponent>,
				@Inject(MAT_DIALOG_DATA) public data) {
		this.answerText.setValue(data.post.message)
	}

	answer() {
		this.dialogRef.close( this.answerText.value);
	}

  toggleShowResult(event) {
    this.showResult = event.checked;
  }

  openDocumentation() {
    window.open('https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet');
  }
}
