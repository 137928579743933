<mat-sidenav-container class="container">
    <mat-sidenav [opened]="(state$ | async).sideNavOpened" (closedStart)="sideNavClosed()">
        <mat-toolbar class="page-header mat-elevation-z4">
            Menu
        </mat-toolbar>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/learning']" (click)="sideNavClosed()"><mat-icon>home</mat-icon>Home</a>
            <a mat-list-item [routerLink]="['/logout']" (click)="sideNavClosed()"><mat-icon>exit_to_app</mat-icon>Uitloggen</a>
        </mat-nav-list>
    </mat-sidenav>
    <router-outlet></router-outlet>
</mat-sidenav-container>
