/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "@angular/router";
import * as i13 from "./admin-toolbar.component";
import * as i14 from "../../../store/state.store";
var styles_AdminToolbarComponent = [i0.styles];
var RenderType_AdminToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminToolbarComponent, data: {} });
export { RenderType_AdminToolbarComponent as RenderType_AdminToolbarComponent };
function View_AdminToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\u00A0- ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_AdminToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "mat-toolbar", [["class", "page-header mat-elevation-z4 mat-toolbar"], ["color", "primary"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "button", [["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSideNav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i7.MatButton, [i1.ElementRef, i4.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(6, 638976, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["menu"])), (_l()(), i1.ɵeld(8, 0, null, 0, 0, "img", [["src", "assets/kantooropleider.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "a", [["class", "right-links"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i12.RouterLinkWithHref, [i12.Router, i12.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 1), (_l()(), i1.ɵted(-1, null, ["Admin"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AdminToolbarComponent_1)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, 0, 0, "span", [["class", "spacer"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); _ck(_v, 6, 0); var currVal_8 = _ck(_v, 11, 0, "/admin"); _ck(_v, 10, 0, currVal_8); var currVal_9 = _co.title; _ck(_v, 14, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 4).disabled || null); var currVal_4 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 10).target; var currVal_7 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_6, currVal_7); }); }
export function View_AdminToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lomas-admin-toolbar", [], null, null, null, View_AdminToolbarComponent_0, RenderType_AdminToolbarComponent)), i1.ɵdid(1, 49152, null, 0, i13.AdminToolbarComponent, [i14.StateQuery], null, null)], null, null); }
var AdminToolbarComponentNgFactory = i1.ɵccf("lomas-admin-toolbar", i13.AdminToolbarComponent, View_AdminToolbarComponent_Host_0, { title: "title" }, {}, []);
export { AdminToolbarComponentNgFactory as AdminToolbarComponentNgFactory };
