export function createReseller(_a) {
    var _b = _a.id, id = _b === void 0 ? null : _b, _c = _a.active, active = _c === void 0 ? true : _c, _d = _a.name, name = _d === void 0 ? '' : _d, _e = _a.ltiKey, ltiKey = _e === void 0 ? '' : _e, _f = _a.ltiSecret, ltiSecret = _f === void 0 ? '' : _f;
    return {
        id: id,
        active: active,
        name: name,
        ltiKey: ltiKey,
        ltiSecret: ltiSecret
    };
}
