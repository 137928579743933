<mat-toolbar color="primary" class="page-header mat-elevation-z4">
    <img src="assets/kantooropleider.png" />
    <a [routerLink]="['/']" class="right-links">De Kantooropleider</a>
    <span class="spacer"></span>
    <span class="nav-links">
        <a [routerLink]="['/login']" class="right-links">inloggen</a>
        <a [routerLink]="['/sign-up']" class="right-links">aanmelden</a>
        <a [routerLink]="['/sign-up/subscribe']" class="right-links">nieuwsbrief</a>
    </span>
    <button mat-icon-button [matMenuTriggerFor]="menu" class="nav-links-menu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/login']">
            <span>inloggen</span>
        </button>
        <button mat-menu-item [routerLink]="['/sign-up']">
            <span>aanmelden</span>
        </button>
        <button mat-menu-item [routerLink]="['/sign-up/subscribe']">
            <span>nieuwsbrief</span>
        </button>
    </mat-menu>
</mat-toolbar>