import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAccount } from '../../../model/user-account.model';
import { UserAccountService } from '../../../services/useraccount/useraccount.service';
import { PublicCourseService } from '../../../services/public-course/public-course.service';
import { PublicCourseQuery } from '../../../store/pubic-course.store';
import { ActivatedRoute } from '@angular/router';
import { SubscribeService } from '../../../services/subscribe/subscribe.service';

@Component({
	templateUrl: './subscribe.component.html',
	styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent {
	createAccountForm = new FormGroup({
		firstName: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required, Validators.email]),
	});


	status: 'new' | 'success' | 'exists' | 'fail' = 'new';

	constructor(private subscribeService: SubscribeService,
				private publicCourseService: PublicCourseService,
				private publicCourseQuery: PublicCourseQuery,
				private route: ActivatedRoute) {}

	get firstName(): any { return this.createAccountForm.get('firstName'); }
	get email(): any { return this.createAccountForm.get('email'); }


	submit() {
		const account = <UserAccount> this.createAccountForm.value;
		this.subscribeService.post(account).subscribe((res) => {
			this.status = 'success';
		}, (err) => {
			if (err.error.code === 'ALREADY_EXISTS') {
				this.status = 'exists';
			} else {
				this.status = 'fail';
			}
		})
	}
}