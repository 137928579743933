import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JWTTokenPayload } from '../../model/jwttokenpayload';

const TOKEN = 'token';

@Injectable()
export class AuthService {

  constructor(
    private http: HttpClient,
    public jwtHelper: JwtHelperService) {}

  isAuthenticated(): boolean {
    // get the token
    return !this.jwtHelper.isTokenExpired(this.getRawToken());
  }

  getPayload(): JWTTokenPayload {
    const token = this.getRawToken();
    if (!token) {
      return null;
    }
    return this.jwtHelper.decodeToken(this.getRawToken()) as JWTTokenPayload;
  }

  login(email: string, password: string): Observable<void> {
    return Observable.create(observable => {
      this.http.post('/auth/login', {email: email, password: password})
        .subscribe(res => {
          this.setToken(res[TOKEN]);
          observable.next();
          observable.complete();
        }, (err) => {
          observable.error(err);
        });
    });
  }

  refreshToken() {
    return Observable.create(observable => {
      this.http.get('/auth/login')
          .subscribe(res => {
            this.setToken(res[TOKEN]);
            observable.next();
            observable.complete();
          }, (err) => {
            this.setToken(null);
            observable.error(err);
          });
    });
  }

  getRoles() {
    return this.getPayload().roles;
  }

  logout(): void {
    this.deleteToken();
  }

  getRawToken(): string {
    return sessionStorage.getItem(TOKEN);
  }

  forgotPassword(email: string) {
    return Observable.create(observable => {
      this.http.post('/auth/forgot', {email: email})
          .subscribe(res => {
            observable.next(res);
            observable.complete();
          }, err => {
            observable.error(err);
          })
    });
  }

  restorePassword(code: string, newPassword: string) {
	  return Observable.create(observable => {
		  this.http.post('/auth/restore', {newPassword: newPassword, code: code})
			  .subscribe(res => {
				  observable.next(res);
				  observable.complete();
			  }, err => {
				  observable.error(err);
			  })
	  });
  }

  private setToken(token): void {
    sessionStorage.setItem(TOKEN, token);
  }

  private deleteToken(): void {
    sessionStorage.removeItem(TOKEN);
  }
}
