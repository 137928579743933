import { Component, OnInit } from '@angular/core';
import { AccountQuery } from '../../../store/account.store';
import { EventQuery } from '../../../store/event.store';
import { EventService } from '../../../services/event/event.service';
import { MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { EditEventComponent } from '../dialogs/edit-event/edit-event.component';
import { EventTypeService } from '../../../services/event-type/event-type.service';
import { ID } from '@datorama/akita';
import { EventTypeQuery } from '../../../store/event-type.store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../../services/account/account.service';
import { ConfirmUnsubscribeAccountComponent } from '../dialogs/confirm-unsubscribe-account/confirm-unsubscribe-account.component';
import { UnsubscribeService } from '../../../services/unsubscribe/unsubscribe.service';
import {EditActionComponent} from "../dialogs/edit-action/edit-action.component";
import {ActionService} from "../../../services/action/action.service";
import { EnrollmentOverviewService } from '../../../services/enrollment-overview/enrollment-overview.service';
import {ActionQuery} from "../../../store/action.store";
import {Action} from "../../../model/action.model";
import {CompleteActionComponent} from "../dialogs/complete-action/complete-action.component";
import {formatDate} from "@angular/common";

@Component({
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
	eventDisplayedColumns: string[] = ['dateTime', 'eventType', 'adminName', 'note'];
	eventPageSize = 25;
	eventPageIndex = 0;
	eventTotal: number;
  events$ = this.eventQuery.selectAll();

	eventTypes = {};

  actionDisplayedColumns: string[] = ['dueDate', 'eventType', 'adminName', 'note', 'actions'];
  actionPageSize = 25;
  actionPageIndex = 0;
  actionTotal: number;
  actions$ = this.actionQuery.selectAll();

  courseEnrollments = [];

	account;
	accountDetailsForm = new FormGroup({
		firstName: new FormControl('', [Validators.required]),
		preposition: new FormControl('', [Validators.required]),
		surName: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required]),
		note: new FormControl('')
	});

	constructor(private accountQuery: AccountQuery,
				private eventQuery: EventQuery,
				private eventService: EventService,
				private dialog: MatDialog,
				private snackBar: MatSnackBar,
				private eventTypeService: EventTypeService,
				private eventTypeQuery: EventTypeQuery,
				private subscriberService: AccountService,
				private unsubscribeService: UnsubscribeService,
				private accountService: AccountService,
        private actionService: ActionService,
        private actionQuery: ActionQuery,
        private enrollmentOverviewService: EnrollmentOverviewService
	) {}

	ngOnInit() {
		this.account = this.accountQuery.getActive();
		this.loadData();
		this.eventTypeService.list(0, 1000).subscribe();
		this.eventTypeQuery.selectAll().subscribe(eventTypes => {
			this.eventTypes = eventTypes.reduce((types, eventType) => {
				types[ eventType.id ] = eventType;
				return types;
			}, {});
		})
	}

	loadData() {
		if (this.account) {
			this.accountDetailsForm.setValue({
				firstName: this.account.firstName,
				preposition: this.account.preposition,
				surName: this.account.surName,
				email: this.account.email,
				note: this.account.note
			});

			this.eventService.list( this.eventPageSize, this.eventPageIndex * this.eventPageSize, this.account.id)
				.subscribe(result => this.eventTotal = result);

			this.actionService.list(this.actionPageSize, this.actionPageIndex * this.actionPageSize, this.account.id)
        .subscribe(result => this.actionTotal = result);

			this.enrollmentOverviewService.accountList(this.account.id).subscribe(results => {
			  this.courseEnrollments = results.map(result => {
			    const parts = (result.chapters || []).reduce((total, chapter) => {
			      chapter.parts.forEach(part => {
			        total[part.id] = `${chapter.name} - ${part.name}`
            });
			      return total;
          }, {});
			    if (result.progress) {
			      result.progress.map(entry => {
			        entry.date = formatDate(entry.t, 'medium', 'nl');
			        entry.partName = entry.a ==='L' ? 'LTI Launch' : ( parts[entry.p] || 'Introscherm');
			        return entry;
            })
          }
			    return result;
        })
      });
		}
	}

	actionPageChange(event: PageEvent) {
		this.actionPageSize = event.pageSize;
		this.actionPageIndex = event.pageIndex;
		this.loadData();
	}

	eventPageChange(event: PageEvent) {
		this.eventPageSize = event.pageSize;
		this.eventPageIndex = event.pageIndex;
		this.loadData();
	}

	save() {
		this.subscriberService.save({...this.accountDetailsForm.value, id: this.account.id}).subscribe(() => {
			this.snackBar.open('Gegegevens zijn opgeslagen', 'ok', {duration: 4000});
		}, () => {
			this.snackBar.open('Er is iets misgegaan met het opslaan van de gegevens', 'ok', {duration: 4000});
		});
	}

	addEvent() {
		this.dialog.open(EditEventComponent, {
			width: '500px',
			height: '500px'
		}).afterClosed().subscribe(result => {
			if (result) {
				result.accountId = this.account.id;
				this.eventService.save(result).subscribe(() => {
					this.loadData();
				}, () => {
					this.snackBar.open('er is iets misgegaan met het opslaan van de data', 'ok', {duration: 4000})
				})
			}
		})
	}

	addAction() {
    this.editAction();
	}

	unsubscribeAccount() {
		this.dialog.open(ConfirmUnsubscribeAccountComponent, {
			width: '500px',
			height: '500px',
			data: {
				account: this.account
			}
		}).afterClosed().subscribe(result => {
			if (result === true) {
				this.unsubscribeService.unsubscribe(this.account.id)
					.subscribe(() => this.accountService.get(this.account.id)
						.subscribe(() => {
							this.snackBar.open('Uitschrijven is gelukt', 'ok', {duration: 4000});
							this.account = this.accountQuery.getActive();
						}),
						() => {
							this.snackBar.open('Er is iets misgegaan met het uitschrijven', 'ok', {duration: 4000})
						}
					)
			}
		})
	}

	getAccountName(id: ID): string {
		const account = this.accountQuery.getEntity(id);
		return account ? `${account.firstName} ${account.preposition} ${account.surName}` : '';
	}

	isDue(date) {
	  return (new Date(date) <= new Date());
  }

  editAction(action?: Action) {
    this.dialog.open(EditActionComponent, {
      width: '500px',
      height: '500px',
      data: {
        action: action
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        result.accountId = this.account.id;
        this.actionService.save(result).subscribe(() => {
          this.loadData();
        }, () => {
          this.snackBar.open('er is iets misgegaan met het opslaan van de data', 'ok', {duration: 4000})
        })
      }
    })
  }

  completeAction(action: Action) {
	  this.dialog.open(CompleteActionComponent, {
      width: '500px',
      height: '500px',
      data: {
        action: action
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        result.accountId = this.account.id;

        this.eventService.save(result).subscribe(() => {
          this.actionService.complete(result.actionId).subscribe(() => {
            console.log('done')
            this.loadData();
          } , () => {
            this.snackBar.open('er is iets misgegaan met het opslaan van de data', 'ok', {duration: 4000});
          });
        }, () => {
          this.snackBar.open('er is iets misgegaan met het opslaan van de data', 'ok', {duration: 4000});
        });
        console.log(result)
      }
    });
  }

  getLatestProgressDate(progress) {
	  console.log(progress)
	  return progress[progress.length -1].t;
  }
}
