<h2 mat-dialog-title>Gebeurtenistype</h2>
<mat-dialog-content>
    <mat-form-field class="full-width">
        <input matInput placeholder="Naam" [formControl]="name"/>
    </mat-form-field>


</mat-dialog-content>
<mat-dialog-actions >
    <button mat-button mat-dialog-close>Annuleren</button>
    <button mat-raised-button (click)="save()" color="primary" [disabled]="name.invalid">Opslaan</button>
</mat-dialog-actions>