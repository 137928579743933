import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { createAccount, Account } from '../model/account.model';
import { Observable } from 'rxjs/Rx';

export interface AccountState extends EntityState<Account> {
	search: string;
}

@StoreConfig({name: 'store'})
export class AccountStore extends EntityStore<AccountState, Account> {

	constructor() {
		super({search: ''});
	}
}

@Injectable({providedIn: 'root'})
export class AccountQuery extends QueryEntity<AccountState, Account> {
	search$ = Observable.of(this.getSnapshot().search);

	selectSearch() {
		return this.select(state => state.search);
	}

	constructor(protected store: AccountStore) {
		super(store);
	}
}