import {Component, ElementRef, HostListener, Inject, OnInit} from '@angular/core';
import { TdMediaService } from '@covalent/core';
import { StateQuery } from '../../../store/state.store';
import { CourseEnrollmentService } from '../../../services/course-enrollment/course-enrollment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Course, createCourse } from '../../../model/course.model';
import { ItemService } from '../../../services/item/item.service';
import { BlogService } from '../../../services/blog/blog.service';
import { BlogQuery } from '../../../store/blog.store';
import { AuthService } from '../../../services/auth/auth.service';
import { WINDOW } from '../../../window.provider';
import {formatDate} from "@angular/common";
import {PageEvent} from "@angular/material";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
	templateUrl: './course-viewer.component.html',
	styleUrls: ['./course-viewer.component.scss']
})
export class CourseViewerComponent implements OnInit {
	name = 'Email App'

  ltiLaunchUrl = null;

	course: Course = createCourse({});
	selectedChapter = null;
	selectedPart = null;
	selectedItem = null;
	selectedItemIndex = 0;
	tocOpen = false;
	blogOpen = false;
	splashOpen = true;
	blogs$ = this.blogQuery.selectAll();
	blogTemp = [];
	blogCount = 0;
	initialChapterIndex;
	notAccessible = false;
	partNames = {};
  displayedColumns = ['date','fullName', 'partName'];

  size = 5;
  pageIndex = 0;

  externalView = false;


	@HostListener('click', ['$event'])
	click(event) {
		if (event.target.tagName.toLowerCase() === 'img') {
			const maxWidth = this.window.getComputedStyle(event.target).getPropertyValue('max-width');
			const naturalWidth = `${event.target.naturalWidth}px`;
			const newWidth = maxWidth !==  naturalWidth ? naturalWidth : '100%';

			event.target.style.setProperty('max-width', newWidth);
		}
	}

	constructor(public media: TdMediaService,
				private stateQuery: StateQuery,
				private itemService: ItemService,
				private blogService: BlogService,
				private blogQuery: BlogQuery,
				private router: Router,
				private authService: AuthService,
				private courseEnrollmentService: CourseEnrollmentService,
				private route: ActivatedRoute,
				private elementRef: ElementRef,
				private sanitizer: DomSanitizer,
				@Inject(WINDOW) private window: Window,) {
	}

	ngOnInit() {
	  if (this.authService.getRoles().includes('lti')) {
	    this.externalView = true;
    }
		this.blogService.list().subscribe();
		this.blogs$.subscribe(blogs => this.blogTemp = blogs);
		this.courseEnrollmentService.get(this.route.snapshot.paramMap.get('id')).subscribe(res => {
			this.course = res[ 'value' ];

			if (this.course.ltiLaunch) {
        this.ltiLaunchUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/lti/launch/${this.course.id}`);
        this.courseEnrollmentService.updateLtiProgress(this.course.id).subscribe();
      }

      this.partNames = (this.course.chapters || []).reduce((total, chapter) => {
        chapter.parts.forEach(part => {
          total[part.id] = `${chapter.name} - ${part.name}`
        });
        return total;
      }, {});

			if (this.course.progress) {
				const lastId = this.course.progress[ this.course.progress.length - 1 ].p;
				const partAndIndex = this.course.chapters.reduce((result, chapter) => {
					const part = chapter.parts.find(part => {
						return part.id === lastId || (part.children || []).find(child => child === lastId);
					});

					if (part) {
						result.part = part;
						result.chapter = chapter;
						result.itemIndex = (part.children || []).findIndex(child => child === lastId)
					}
					return result;
				}, {chapter: null, part: null, itemIndex: -1});
				this.selectedChapter = partAndIndex.chapter;
				setTimeout(() => {
					this.selectedItemIndex = partAndIndex.itemIndex;
					this.selectedPart = partAndIndex.part;
				});
				setTimeout(() => {this.resetBlogCount();}, 1000)

			}
			this.itemService.list(this.course.moduleId).subscribe(() => {});
		}, () => {
		  this.notAccessible = true;
		});
	}

	getBlogs() {
	  return this.blogTemp
      .map(blog => {
        const partName = this.partNames[blog.partId];
        const date = formatDate(blog.createDateTime, 'medium', 'nl');
	      return {partName, date, ...blog}
      })
      .filter(blog => blog.partName);

  }

  getBlogSlice() {
	  return this.getBlogs()
      .slice(this.pageIndex * this.size, (this.pageIndex + 1 ) * this.size);
  }

  pageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  viewPartBlog(partId?: string) {
	  this.blogOpen = true;
	  this.splashOpen = false;
	  this.course.chapters.forEach(chapter => {
	    chapter.parts.forEach(part => {
	      if(part.id === partId) {
	        this.selectedPart = part;
	        this.selectedChapter = chapter;
        }
      });
    });
	  this.resetBlogCount();
  }

	toggleSideNav = () => this.stateQuery.openSideNav();

	toggleToc = () => this.tocOpen = !this.tocOpen;

	toggleBlog = () => {
		if (this.tocOpen && this.blogOpen) {
			this.tocOpen = false;
		} else {
			this.blogOpen = !this.blogOpen;
			if (this.blogOpen) {
				this.courseEnrollmentService.updateProgress(this.course.id, this.selectedPart.id, null, 'B')
					.subscribe();
			}
		}
	};

	getTitle() {
		if (this.selectedChapter === null) {
			return this.course.name;
		} else if (this.selectedPart === null) {
			return this.selectedChapter.name;
		} else {
			return this.selectedPart.name;
		}
	}

	openedPanel(event) {
		this.selectedChapter = event;
	}

	selectItem(index: number) {
		this.selectedItemIndex = index;
	}

	showSplash() {
		this.splashOpen = true;
		this.tocOpen = false;
		this.blogOpen = false;
		this.resetBlogCount();
	}

	enrollMeeting() {
		this.window.open('https://fd8.formdesk.com/examenbureaulssobv/scholingsbijeenkomsten-aanmelding/');
	}

	continue() {
		this.splashOpen = false;
	}

	next() {
		if (this.blogOpen || this.tocOpen) {
			this.blogOpen = false;
			this.tocOpen = false;
		} else if (!this.selectedChapter) {
			this.selectedChapter = this.course.chapters[ 0 ];
			setTimeout(() => {
				this.selectPart(this.selectedChapter.parts[ 0 ]);
				this.selectedItemIndex = 0;
				if (this.selectedPart.type === 'items') {
					this.updateItemProgress();
				}
			});
		} else {
			if (this.selectedPart.type === 'items' && this.selectedPart.children.length - 1 > this.selectedItemIndex) {
				this.selectedItemIndex++;
				this.updateItemProgress();
			} else if (this.selectedChapter.parts.indexOf(this.selectedPart) + 1 === this.selectedChapter.parts.length) {
				this.selectedChapter = this.course.chapters[ this.course.chapters.indexOf(this.selectedChapter) + 1 ] || this.course.chapters[ 0 ];
				setTimeout(() => {
					this.selectPart(this.selectedChapter.parts[ 0 ]);
					this.selectedItemIndex = 0;

					if (this.selectedPart.type === 'items') {
						//this.updateItemProgress();
					}
				});
			} else {
				this.selectPart(this.selectedChapter.parts[ this.selectedChapter.parts.indexOf(this.selectedPart) + 1 ]);
				this.selectedItemIndex = 0;
				if (this.selectedPart.type === 'items') {
					//this.updateItemProgress();
				}
			}
		}
	}

	previous() {
		if (this.blogOpen || this.tocOpen) {
			this.blogOpen = false;
			this.tocOpen = false;
		} else if (!this.selectedChapter) {
			this.selectedChapter = this.course.chapters[ this.course.chapters.length - 1 ];
			setTimeout(() => {
				this.selectedPart = this.selectedChapter.parts[ this.selectedChapter.parts.length - 1 ];
				this.selectedItemIndex = 0;
				if (this.selectedPart.type === 'items') {
					//this.updateItemProgress();
				}
			});
		} else {
			if (!this.selectedPart) {
				this.selectedChapter = this.course.chapters[ this.course.chapters.indexOf(this.selectedChapter) - 1 ];
				if (!this.selectedChapter) {
					this.selectedChapter = this.course.chapters[ this.course.chapters.length - 1 ];
				}
				setTimeout(() => {
					this.selectPart(this.selectedChapter.parts[ this.selectedChapter.parts.length - 1 ]);
					if (this.selectedPart.type === 'items') {
						//this.updateItemProgress();
					}
				});
			} else if (this.selectedPart.type === 'items' && this.selectedItemIndex > 0) {
				this.selectedItemIndex--;
				this.updateItemProgress();
			} else if (this.selectedChapter.parts.indexOf(this.selectedPart) === 0) {
				setTimeout(() => {
          this.selectedChapter = this.course.chapters[ this.course.chapters.indexOf(this.selectedChapter) - 1 ] || this.course.chapters[ this.course.chapters.length -1 ];
					this.selectPart(this.selectedChapter.parts[ this.selectedChapter.parts.length - 1 ]);
					this.selectedItemIndex = 0;

					if (this.selectedPart.type === 'items') {
						//this.updateItemProgress();
					}
				});
			} else {
				this.selectPart(this.selectedChapter.parts[ this.selectedChapter.parts.indexOf(this.selectedPart) - 1 ]);
				this.selectedItemIndex = 0;
				if (this.selectedPart.type === 'items') {
					//this.updateItemProgress();
				}
			}
		}
	}


	selectPart(part) {
		this.tocOpen = false;
		this.blogOpen = false;
		this.splashOpen = false;
		this.selectedPart = part;
		this.selectedItemIndex = 0;
		if (this.selectedPart.type === 'items') {
			this.updateItemProgress();
		} else {
			this.courseEnrollmentService.updateProgress(this.course.id, part.id).subscribe();
		}
		this.elementRef.nativeElement.querySelector('#content').scrollTo(0, 0);
		this.resetBlogCount();
	}

	updateItemProgress(action?: string) {
		this.courseEnrollmentService.updateProgress(this.course.id, this.selectedPart.children[ this.selectedItemIndex ], null, action).subscribe();
	}

	resetBlogCount() {
		const lastViewTime = this.course.progress ?
			(this.course.progress || []).map(x => x).reverse().find(row => {

				return row.p === (this.selectedPart || {}).id && row.a === 'B'
			}) || {t:1} : {t:0} ;

		setTimeout(() => {
			this.blogCount = this.blogTemp.filter(blog => {
					return blog.courseId === this.course.id && blog.partId === (this.selectedPart || {}).id
						&& new Date(blog.createDateTime) > new Date(lastViewTime.t)
						&& blog.accountId !== this.authService.getPayload().id
				}
			).length;
		})
	}
}
