var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { createReseller } from "../model/reseller.model";
import * as i0 from "@angular/core";
var initialState = createReseller({});
var ResellerStore = /** @class */ (function (_super) {
    __extends(ResellerStore, _super);
    function ResellerStore() {
        return _super.call(this, initialState) || this;
    }
    ResellerStore.ngInjectableDef = i0.defineInjectable({ factory: function ResellerStore_Factory() { return new ResellerStore(); }, token: ResellerStore, providedIn: "root" });
    ResellerStore = __decorate([
        StoreConfig({ name: 'reseller' }),
        __metadata("design:paramtypes", [])
    ], ResellerStore);
    return ResellerStore;
}(EntityStore));
export { ResellerStore };
var ResellerQuery = /** @class */ (function (_super) {
    __extends(ResellerQuery, _super);
    function ResellerQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        return _this;
    }
    ResellerQuery.ngInjectableDef = i0.defineInjectable({ factory: function ResellerQuery_Factory() { return new ResellerQuery(i0.inject(ResellerStore)); }, token: ResellerQuery, providedIn: "root" });
    return ResellerQuery;
}(QueryEntity));
export { ResellerQuery };
