/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./catalog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "../../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i5 from "@angular/material/divider";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "../../../../../node_modules/@angular/material/sidenav/typings/index.ngfactory";
import * as i14 from "@angular/material/sidenav";
import * as i15 from "../../../components/toolbars/student-toolbar/student-toolbar.component.ngfactory";
import * as i16 from "../../../components/toolbars/student-toolbar/student-toolbar.component";
import * as i17 from "../../../store/state.store";
import * as i18 from "./catalog.component";
import * as i19 from "../../../services/public-course/public-course.service";
import * as i20 from "../../../services/course-enrollment/course-enrollment.service";
import * as i21 from "../../../store/pubic-course.store";
import * as i22 from "../../../store/course-enrollment.store";
var styles_CatalogComponent = [i0.styles];
var RenderType_CatalogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CatalogComponent, data: {} });
export { RenderType_CatalogComponent as RenderType_CatalogComponent };
function View_CatalogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i4.View_MatDivider_0, i4.RenderType_MatDivider)), i1.ɵdid(5, 49152, null, 0, i5.MatDivider, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 8, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Er zijn geen proeflessen meer die je kunt volgen. "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 1), (_l()(), i1.ɵted(-1, null, ["Terug naar home"]))], function (_ck, _v) { var currVal_6 = _ck(_v, 13, 0, "/learning"); _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 5).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 5).vertical; var currVal_2 = !i1.ɵnov(_v, 5).vertical; var currVal_3 = i1.ɵnov(_v, 5).inset; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 12).target; var currVal_5 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_4, currVal_5); }); }
function View_CatalogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i4.View_MatDivider_0, i4.RenderType_MatDivider)), i1.ɵdid(6, 49152, null, 0, i5.MatDivider, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 6, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startCourse(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["width", "300"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Start proefles"]))], function (_ck, _v) { var currVal_8 = "primary"; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 6).vertical ? "vertical" : "horizontal"); var currVal_2 = i1.ɵnov(_v, 6).vertical; var currVal_3 = !i1.ɵnov(_v, 6).vertical; var currVal_4 = i1.ɵnov(_v, 6).inset; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.thumbnail; _ck(_v, 10, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 12).disabled || null); var currVal_7 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_6, currVal_7); }); }
export function View_CatalogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "mat-sidenav-content", [["class", "page mat-drawer-content mat-sidenav-content"]], [[4, "margin-left", "px"], [4, "margin-right", "px"]], null, null, i13.View_MatSidenavContent_0, i13.RenderType_MatSidenavContent)), i1.ɵdid(1, 1097728, null, 0, i14.MatSidenavContent, [i1.ChangeDetectorRef, i14.MatSidenavContainer], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "lomas-student-toolbar", [["title", "Calogus proeflessen"]], null, null, null, i15.View_StudentToolbarComponent_0, i15.RenderType_StudentToolbarComponent)), i1.ɵdid(3, 49152, null, 0, i16.StudentToolbarComponent, [i17.StateQuery], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CatalogComponent_1)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CatalogComponent_2)), i1.ɵdid(9, 802816, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = "Calogus proeflessen"; _ck(_v, 3, 0, currVal_2); var currVal_3 = (i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.enrollableCourses$)).length === 0); _ck(_v, 6, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.enrollableCourses$)); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._container._contentMargins.left; var currVal_1 = i1.ɵnov(_v, 1)._container._contentMargins.right; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CatalogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CatalogComponent_0, RenderType_CatalogComponent)), i1.ɵdid(1, 114688, null, 0, i18.CatalogComponent, [i19.PublicCourseService, i20.CourseEnrollmentService, i21.PublicCourseQuery, i22.CourseEnrollmentQuery, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CatalogComponentNgFactory = i1.ɵccf("ng-component", i18.CatalogComponent, View_CatalogComponent_Host_0, {}, {}, []);
export { CatalogComponentNgFactory as CatalogComponentNgFactory };
