<mat-sidenav-content class="page">
    <lomas-admin-toolbar title="Thread"></lomas-admin-toolbar>
    <mat-card>
        <mat-card-title>
            <button mat-icon-button [routerLink]="['/admin/forum']">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
            Thread
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content *ngIf="getThreadBlog() | async as blog">
            <lomas-blog-post
                    [blog]="blog"
                    [children]="blogs$ | async"
                    [depth]="0"
                    [threadId]="blog.id"
                    (addPost)="showPost($event)"
                    (editPost)="editPost($event)"
            ></lomas-blog-post>
        </mat-card-content>
    </mat-card>
</mat-sidenav-content>