import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Blog } from '../../../../../model/blog.model';
import { AddBlogpostComponent } from '../../../dialogs/add-blogpost/add-blogpost.component';
import { MatDialog } from '@angular/material';
import { BlogService } from '../../../../../services/blog/blog.service';
import { AuthService } from '../../../../../services/auth/auth.service';

@Component({
	selector: 'lomas-blog-post',
	templateUrl: 'blog-post.component.html',
	styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent {
	@Input() depth: number;
	@Input() children: Blog[];
	@Input() blog: Blog;
	@Input() courseId;
	@Input() partId;
	@Input() parentId;
	@Input() threadId;
	@Output() addPost = new EventEmitter();
	@Output() editPost = new EventEmitter();

	constructor(private authService: AuthService) {}

	handleAddPost(replyPost: Blog) {
		this.addPost.emit(replyPost);
	}

	handleEditPost(post: Blog) {
		this.editPost.emit(post);
	}

	showPost() {
		return (this.depth === 0 && this.blog.replyId === null) ||
			(this.depth > 0 && this.parentId === this.blog.replyId);
	}

	getDepth() {
		const maxDepth = Math.min(80, this.depth * 20);
		return `${maxDepth}px`;
	}

	editAllowed() {
		return this.authService.getPayload().roles.includes('admin')
			&& this.blog.adminAnswer;
	}
}