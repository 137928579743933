import { ID } from '@datorama/akita';

export type Reseller = {
  id: ID;
  active: boolean;
  name: string;
  ltiKey: string;
  ltiSecret: string;
}

export function createReseller({
                             id = null,
                            active = true,
                             name = '',
                             ltiKey = '',
                              ltiSecret = ''}: Partial<Reseller>){
  return {
    id,
    active,
    name,
    ltiKey,
    ltiSecret
  }
}
