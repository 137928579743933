/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./newsletter-web.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/safeHtml.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./newsletter-web.component";
import * as i5 from "../../services/newsletter-web/newsletter-web.service";
import * as i6 from "@angular/router";
var styles_NewsletterWebComponent = [i0.styles];
var RenderType_NewsletterWebComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewsletterWebComponent, data: {} });
export { RenderType_NewsletterWebComponent as RenderType_NewsletterWebComponent };
export function View_NewsletterWebComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "section", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v, 0), _co.content)); _ck(_v, 1, 0, currVal_0); }); }
export function View_NewsletterWebComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NewsletterWebComponent_0, RenderType_NewsletterWebComponent)), i1.ɵdid(1, 114688, null, 0, i4.NewsletterWebComponent, [i5.NewsletterWebService, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsletterWebComponentNgFactory = i1.ɵccf("ng-component", i4.NewsletterWebComponent, View_NewsletterWebComponent_Host_0, {}, {}, []);
export { NewsletterWebComponentNgFactory as NewsletterWebComponentNgFactory };
