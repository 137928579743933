<mat-sidenav-content class="page">
    <lomas-admin-toolbar title=""></lomas-admin-toolbar>
    <mat-card>
        <mat-card-title>Licentiecodes</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <table><tbody>
            <tr *ngFor="let licenseCode of licenseCode$ | async">
                <td>{{courses[licenseCode.courseId]}}</td>
                <td><strong>{{licenseCode.code}}</strong></td>
            </tr>
            </tbody></table>
            <button mat-raised-button color="primary" [routerLink]="['/admin/license-codes']">Overzicht licentiecodes</button>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-title>Proeflessen</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <button mat-raised-button color="primary" [routerLink]="['/admin/course-enrollments']">Overzicht inschrijvingen</button>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-title>Forum</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <p>
                <span [matBadge]="unseenForumCount" matBadgeOverlap="false">Ongelezen berichten</span>
            </p>
            <button mat-raised-button color="primary" [routerLink]="['/admin/forum']">Overzicht forum posts</button>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-title>Acties</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <p>
                <span [matBadge]="openActionCount" matBadgeOverlap="false">Openstaande acties</span>
            </p>
            <button mat-raised-button color="primary" [routerLink]="['/admin/actions']">Overzicht acties</button>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-title>Nieuwsbrieven</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <button mat-raised-button color="primary" [routerLink]="['/admin/newsletters']">Overzicht nieuwsbrieven</button>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-title>Personen</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <div *ngFor="let subscriber of subscribers$ | async" class="subscribers">
                <span class="subscribers__first-name">{{subscriber.firstName}}</span>
                <span class="subscribers__confirmed-date">{{subscriber.confirmedDate}}</span>
            </div>
            <button mat-raised-button color="primary" [routerLink]="['/admin/accounts']">Overzicht personen</button>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-title>Resellers</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>

            <button mat-raised-button color="primary" [routerLink]="['/admin/resellers']">Overzicht resellers</button>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-title>Gebeurtenissen</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <button mat-raised-button color="primary" [routerLink]="['/admin/events']">Overzicht Gebeurtenissen</button>
            <br /><br />
            <button mat-raised-button color="primary" [routerLink]="['/admin/event-types']">Overzicht Gebeurtenistypes</button>
        </mat-card-content>
    </mat-card>

  <mat-card>
    <mat-card-title>Cursussen</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>

      <button mat-raised-button color="primary" [routerLink]="['/admin/courses']">Overzicht cursussen</button>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Licenties</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <button mat-raised-button color="primary" [routerLink]="['/admin/licenses']">Overzicht licenties</button>
    </mat-card-content>
  </mat-card>
</mat-sidenav-content>
