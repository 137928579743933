import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CourseStore} from "./course.store";
import {Subscription} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class CourseService {

  constructor(
    private http: HttpClient,
    private courseStore: CourseStore,
  ) {}

  get(id) {
    if (this.courseStore.entities[id]) {
      this.courseStore.setActive(id);
    } else {
      this.courseStore.setLoading(true);

      this.http.get('/api/course')
        .subscribe(response => {
          this.courseStore.setLoading(false);
          this.courseStore.set(response['value']);
          this.courseStore.setActive(id);
        }, err => {
          this.courseStore.setLoading(false);
          this.courseStore.setError(err);
        });
    }

  }

  list(){
    this.courseStore.setLoading(true);

    this.http.get('/api/course')
      .subscribe(response => {
        this.courseStore.setLoading(false);
        this.courseStore.set(response['value']);
      }, err => {
        this.courseStore.setLoading(false);
        this.courseStore.setError(err);
      });
  }

  save(data) {
    if (!data.id) {
      return this.http.post(`/api/course`, data, {observe: 'response'})
        .pipe(map(result => {
          const keys = result.headers.keys();
          if (keys.indexOf('location') > -1) {
            const location = result.headers.get('location').split('/');
            return location[location.length -1];
          } else {
            return '';
          }
        }))
    } else {
      return this.http.put(`/api/course/${data.id}`, data)
    }
  }
}
